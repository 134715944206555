/* eslint-disable max-lines */
import axios from 'axios';
import { createAction } from 'redux-actions';
import { deleteRequest, getRequest, postRequest } from 'actions/utils';
import { initialState } from 'reducers/fund_admin_reducers/pcapReducer';

import {
  PCAPS_SET_RESULTS,
  PREVIEW_SET_RESULTS,
  PREVIEW_TOTALS_SET_RESULTS,
  PREVIEW_UPDATE_RESULT,
  TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT,
  TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT,
  SET_DOC_CENTER_SYNC_TO_FALSE,
} from 'actions/actionsConstants';

const cancelFetchPreviews = {
  instance: undefined,
};

const cancelFetchPcaps = {
  instance: undefined,
};

const setPreviewTotals = createAction(PREVIEW_TOTALS_SET_RESULTS);
const plusUpdateTotalDocsPublished = createAction(TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT);
const minusUpdateTotalDocsPublished = createAction(TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT);
const updateDocCenterSync = createAction(SET_DOC_CENTER_SYNC_TO_FALSE);

const setPcaps = createAction(PCAPS_SET_RESULTS, (data) =>
  data.periods ? data : { periods: data, total_periods: data.length }
);

function fetchPcaps(pafId, currentPage, resultsPerPage) {
  return getRequest({
    showSpinner: false,
    cancelInstance: cancelFetchPcaps,
    url: '/investment_report_document_sets/valid_reporting_periods',
    params: {
      private_access_fund_id: pafId,
      page: currentPage,
      per_page: resultsPerPage,
    },
    onSuccess: setPcaps,
  });
}

function setPreviews(data, reportId) {
  return (dispatch) => {
    dispatch({ type: PREVIEW_SET_RESULTS, report_id: reportId, payload: data });
  };
}

function updatePreview(investmentId, values) {
  return (dispatch) => {
    dispatch({
      type: PREVIEW_UPDATE_RESULT,
      investment_id: investmentId,
      payload: values,
    });
  };
}

function fetchPreviews(pafId, reportId, currentPage = 1, resultsPerPage = 25, filterText = '') {
  return getRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/preview`,
    params: {
      private_access_fund_id: pafId,
      page: currentPage,
      per_page: resultsPerPage,
      search: filterText,
    },
    cancelInstance: cancelFetchPreviews,
    onSuccess: (data) => setPreviews(data, reportId),
    onFailure: () => setPreviews(initialState.preview_list, reportId),
  });
}

function fetchPreviewTotals(pafId, reportId) {
  return getRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/preview_totals`,
    params: { private_access_fund_id: pafId },
    onSuccess: setPreviewTotals,
  });
}

function invDocCreateExport(pafId, reportingIds) {
  return postRequest({
    showSpinner: false,
    url: '/investment_report_document_sets/create_export',
    data: {
      private_access_fund_id: pafId,
      reporting_ids: reportingIds,
    },
  });
}

function sendEmailNotifications(pafId, reportId, investmentIds) {
  return postRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/email_notifications`,
    data: {
      private_access_fund_id: pafId,
      investment_ids: investmentIds,
    },
  });
}

function deleteReportDocument(pafId, investmentId, reportingPeriodId) {
  return (dispatch) =>
    // CONSIDER: Follow a more normal approach, just sending the InvestmentReportDocument#id to be deleted.
    dispatch(
      deleteRequest({
        showSpinner: false,
        url: '/investment_report_documents/destroy',
        params: {
          private_access_fund_id: pafId,
          investment_id: investmentId,
          reporting_period_id: reportingPeriodId,
        },
        onSuccess: () => {
          dispatch(updatePreview(investmentId, { document_id: null, document_url: null }));

          return minusUpdateTotalDocsPublished();
        },
      })
    );
}

function deleteReportDocumentSet(pafId, reportId, investmentIds) {
  return postRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/bulk_delete`,
    data: {
      private_access_fund_id: pafId,
      id: reportId, // Reporting period string (e.g. '2020-Q1').
      investment_ids: investmentIds,
    },
  });
}

function createReportDocument(pafId, investmentId, reportId) {
  // It was not migrated to postRequest, because it requires to check 'status'
  // and onSuccess only has access to data
  return (dispatch) =>
    axios
      .post(
        '/investment_report_documents',
        {
          investment_id: investmentId,
          private_access_fund_id: pafId,
          reporting_period_id: reportId,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // sometimes it returns 204
        if (response.status === 200) {
          const { data } = response;

          dispatch(
            updatePreview(investmentId, {
              document_id: data.id,
              document_url: data.url,
            })
          );

          if (data.document_center_sync === false) {
            dispatch(updateDocCenterSync());
          }

          dispatch(plusUpdateTotalDocsPublished());
        }

        return response;
      });
}

function publishPcapDocuments(pafId, reportId, selectedInvestmentIds) {
  return postRequest({
    showSpinner: false,
    url: '/investment_report_document_sets',
    data: {
      private_access_fund_id: pafId,
      id: reportId,
      investment_ids: selectedInvestmentIds,
    },
  });
}

export default {
  fetchPcaps,
  fetchPreviews,
  fetchPreviewTotals,
  invDocCreateExport,
  sendEmailNotifications,
  deleteReportDocument,
  deleteReportDocumentSet,
  createReportDocument,
  publishPcapDocuments,
};
