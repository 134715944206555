/* eslint-disable max-lines */
import axios from 'axios';
import spinnerActions from 'actions/spinner_actions/spinnerActions';
import { createAction } from 'redux-actions';
import { postRequest } from 'actions/utils';
import notificationService from 'services/notifications';
import {
  DM_SELECT_FUND,
  DM_SELECT_FUNDS,
  DM_SELECT_WLP,
  DM_SELECT_WLPS,
  DM_SELECT_FIRM,
  DM_SELECT_FIRMS,
  DM_SELECT_USER,
  DM_TOGGLE_ALL_DOCS,
  DM_SET_FUNDS,
  DM_SET_WLPS,
  DM_SET_FIRMS,
  DM_SET_USERS,
  DM_SET_DOCUMENTS,
  DM_SET_EXPIRING_DOCUMENTS,
  DM_SET_EXPIRED_DOCUMENTS,
  DM_SET_PENDING_APPROVAL,
  DM_SET_PENDING_NOTIFICATIONS,
  DM_SET_LIBRARY_DOCUMENTS,
  DM_SET_DOCUMENT_TYPES,
  DM_UPLOAD_DOCUMENT,
  DM_UPLOAD_LIBRARY_DOCUMENT,
  DM_UPLOAD_TEMPLATE_DOCUMENT,
  DM_UPDATE_DOCUMENT,
  DM_UPDATE_LIBRARY_DOCUMENT,
  DM_DELETE_DOCUMENT,
  DM_DELETE_LIBRARY_DOCUMENT,
  DM_LOADING_FUNDS,
  DM_LOADING_WLPS,
  DM_LOADING_FIRMS,
  DM_LOADING_USERS,
  DM_SET_DOCUMENTS_STATUS,
  DM_SET_BRANCH_CODES,
  DM_SET_TEMPLATE_DOCUMENTS,
  DM_SET_FUND_GROUPS,
  DM_UPDATE_TEMPLATE_DOCUMENT,
} from 'actions/actionsConstants';

function dmLoadedUsers() {
  return {
    type: DM_LOADING_USERS,
    payload: false,
  };
}

function dmLoadingUsers() {
  return {
    type: DM_LOADING_USERS,
    payload: true,
  };
}

function dmLoadedFirms() {
  return {
    type: DM_LOADING_FIRMS,
    payload: false,
  };
}

function dmLoadingFirms() {
  return {
    type: DM_LOADING_FIRMS,
    payload: true,
  };
}

function dmLoadedFunds() {
  return {
    type: DM_LOADING_FUNDS,
    payload: false,
  };
}

function dmLoadingFunds() {
  return {
    type: DM_LOADING_FUNDS,
    payload: true,
  };
}

function dmLoadedWlps() {
  return {
    type: DM_LOADING_WLPS,
    payload: false,
  };
}

function dmLoadingWlps() {
  return {
    type: DM_LOADING_WLPS,
    payload: true,
  };
}

function dmSetFunds(payload) {
  return {
    type: DM_SET_FUNDS,
    payload,
  };
}

function dmSetWlps(payload) {
  return {
    type: DM_SET_WLPS,
    payload,
  };
}

function dmSetFirms(payload) {
  return {
    type: DM_SET_FIRMS,
    payload,
  };
}

function dmSetUsers(users, currentUser) {
  return {
    type: DM_SET_USERS,
    payload: {
      users,
      currentUser,
    },
  };
}

function dmSetDocuments(payload) {
  return {
    type: DM_SET_DOCUMENTS,
    payload,
  };
}

function dmSetPendingApproval(payload) {
  return {
    type: DM_SET_PENDING_APPROVAL,
    payload,
  };
}

function dmSetPendingNotifications(payload) {
  return {
    type: DM_SET_PENDING_NOTIFICATIONS,
    payload,
  };
}

function dmSetExpiringDocuments(payload) {
  return {
    type: DM_SET_EXPIRING_DOCUMENTS,
    payload,
  };
}

function dmSetExpiredDocuments(payload) {
  return {
    type: DM_SET_EXPIRED_DOCUMENTS,
    payload,
  };
}

function dmSetLibraryDocuments(payload) {
  return {
    type: DM_SET_LIBRARY_DOCUMENTS,
    payload,
  };
}

function dmSetTemplateDocuments(payload) {
  return {
    type: DM_SET_TEMPLATE_DOCUMENTS,
    payload,
  };
}

function dmSetBranchCodes(payload) {
  return {
    type: DM_SET_BRANCH_CODES,
    payload,
  };
}

function dmSetFundGroups(payload) {
  return {
    type: DM_SET_FUND_GROUPS,
    payload,
  };
}

const dmSetDocumentsStatus = createAction(DM_SET_DOCUMENTS_STATUS);

function dmSetDocumentTypes(payload) {
  return {
    type: DM_SET_DOCUMENT_TYPES,
    payload,
  };
}

function dmSelectFund(payload) {
  return {
    type: DM_SELECT_FUND,
    payload,
  };
}

function dmSelectFunds(payload) {
  return {
    type: DM_SELECT_FUNDS,
    payload,
  };
}

function dmSelectWlp(payload) {
  return {
    type: DM_SELECT_WLP,
    payload,
  };
}

function dmSelectWlps(payload) {
  return {
    type: DM_SELECT_WLPS,
    payload,
  };
}

function dmSelectFirm(payload) {
  return {
    type: DM_SELECT_FIRM,
    payload,
  };
}

function dmSelectFirms(payload) {
  return {
    type: DM_SELECT_FIRMS,
    payload,
  };
}

function dmSelectUser(payload) {
  return {
    type: DM_SELECT_USER,
    payload,
  };
}

function dmToggleAllDocs() {
  return {
    type: DM_TOGGLE_ALL_DOCS,
  };
}

function dmUploadDocument(payload) {
  return {
    type: DM_UPLOAD_DOCUMENT,
    payload,
  };
}

function dmUploadLibraryDocument(payload) {
  return {
    type: DM_UPLOAD_LIBRARY_DOCUMENT,
    payload,
  };
}

function dmUpdateDocument(payload) {
  return {
    type: DM_UPDATE_DOCUMENT,
    payload,
  };
}

function dmUpdateLibraryDocument(payload) {
  return {
    type: DM_UPDATE_LIBRARY_DOCUMENT,
    payload,
  };
}

function dmUpdateFundDocumentTemplate(payload) {
  return {
    type: DM_UPDATE_TEMPLATE_DOCUMENT,
    payload,
  };
}

function dmDeleteDocumentFile(payload) {
  return {
    type: DM_DELETE_DOCUMENT,
    payload,
  };
}

function dmDeleteLibraryDocumentFile(payload) {
  return {
    type: DM_DELETE_LIBRARY_DOCUMENT,
    payload,
  };
}

function dmUploadTemplateDocument(payload) {
  return {
    type: DM_UPLOAD_TEMPLATE_DOCUMENT,
    payload,
  };
}

function dmFetchFundsAutocomplete(query) {
  const url = 'document_manager/funds/autocomplete';
  return (dispatch) => {
    dispatch(dmLoadingFunds());
    return axios.get(url, {
      withCredentials: true,
      params: {
        query,
      },
    }).then((response) => {
      dispatch(dmSetFunds(response.data));
      dispatch(dmLoadedFunds());
    }).catch(() => {
      dispatch(dmLoadedFunds());
    });
  };
}

function dmFetchFunds() {
  return dmFetchFundsAutocomplete('');
}

function dmFetchWlps() {
  const url = 'white_label_partners';
  return (dispatch) => {
    dispatch(dmLoadingWlps());
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, { withCredentials: true }).then((response) => {
      dispatch(dmSetWlps(response.data));
      dispatch(dmLoadedWlps());
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(dmLoadedWlps());
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchFirmsAutocomplete(query) {
  const url = 'firms/autocomplete';
  return (dispatch) => {
    dispatch(dmLoadingFirms());
    return axios.get(url, {
      params: {
        query,
      },
      withCredentials: true,
    }).then((response) => {
      dispatch(dmSetFirms(response.data));
      dispatch(dmLoadedFirms());
    }).catch(() => {
      dispatch(dmLoadedFirms());
    });
  };
}

function dmFetchFirms() {
  return dmFetchFirmsAutocomplete('');
}

function dmFetchUsersAutocomplete(query, user) {
  const url = 'document_manager/users/autocomplete';
  return (dispatch) => {
    dispatch(dmLoadingUsers());
    return axios.get(url, {
      params: {
        query,
      },
      withCredentials: true,
    }).then((response) => {
      dispatch(dmSetUsers(response.data, user));
      dispatch(dmLoadedUsers());
    }).catch(() => {
      dispatch(dmLoadedUsers());
    });
  };
}

const dmFetchDocumentsStatus = (fundId, fundDocumentIds) => (
  postRequest({
    url: `funds/${fundId}/fund_documents/documents_status`,
    data: {
      fund_document_ids: fundDocumentIds,
    },
    showSpinner: false,
    onSuccess: dmSetDocumentsStatus,
  })
);

const dmFetchMultipleDocumentsStatus = (fundIds, fundDocumentIds) => (dispatch) => {
  fundIds.forEach((fundId) => {
    postRequest({
      url: `funds/${fundId}/fund_documents/documents_status`,
      data: {
        fund_document_ids: fundDocumentIds,
      },
      showSpinner: false,
      onSuccess: (response) => dispatch(dmSetDocumentsStatus(response)),
    });
  });
};


function dmFetchDocuments({
  fundId, wlpId, firmId, allDocuments,
}) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_id: fundId,
        wlp_id: wlpId,
        firm_id: firmId,
        all_docs: allDocuments,
      },
    }).then((response) => {
      dispatch(dmSetDocuments(response.data));
      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchDocumentsStatus(fundId, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchMultipleDocuments({ fundIds, wlpIds, firmIds, uploaderUserId }) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        firm_ids: firmIds,
        uploader_user_id: uploaderUserId,
      },
    }).then((response) => {
      dispatch(dmSetDocuments(response.data));

      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchMultipleDocumentsStatus(fundIds, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchPendingApproval({ fundIds, wlpIds, firmIds, uploaderUserId }) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        firm_ids: firmIds,
        uploader_user_id: uploaderUserId,
        pending_approval: true,
      },
    }).then((response) => {
      dispatch(dmSetPendingApproval(response.data));

      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchMultipleDocumentsStatus(fundIds, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchPendingNotifications({ fundIds, wlpIds, firmIds, uploaderUserId }) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        firm_ids: firmIds,
        uploader_user_id: uploaderUserId,
        pending_notifications: true,
      },
    }).then((response) => {
      dispatch(dmSetPendingNotifications(response.data));

      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchMultipleDocumentsStatus(fundIds, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchExpiringDocuments({ fundIds, wlpIds, firmIds, uploaderUserId }) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        firm_ids: firmIds,
        uploader_user_id: uploaderUserId,
        expiring_docs: true,
      },
    }).then((response) => {
      dispatch(dmSetExpiringDocuments(response.data));

      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchMultipleDocumentsStatus(fundIds, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchExpiredDocuments({ fundIds, wlpIds, firmIds, uploaderUserId }) {
  const url = 'document_manager/fund_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        firm_ids: firmIds,
        uploader_user_id: uploaderUserId,
        expired_docs: true,
      },
    }).then((response) => {
      dispatch(dmSetExpiredDocuments(response.data));

      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchMultipleDocumentsStatus(fundIds, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchLibraryDocuments({
  fundId, wlpId,
}) {
  const url = 'document_manager/fund_library_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_id: fundId,
        wlp_id: wlpId,
      },
    }).then((response) => {
      dispatch(dmSetLibraryDocuments(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchMultipleLibraryDocuments({ fundIds, wlpIds, uploaderUserId }) {
  const url = 'document_manager/fund_library_documents';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        uploader_user_id: uploaderUserId,
      },
    }).then((response) => {
      dispatch(dmSetLibraryDocuments(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchFundDocumentTemplates({
  fundIds, wlpIds, onlyApproved = false, excludedWlps = [],
}) {
  const url = 'document_manager/fund_document_templates';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        fund_ids: fundIds,
        wlp_ids: wlpIds,
        only_approved: onlyApproved,
        excluded_wlps: excludedWlps,
      },
    }).then((response) => {
      dispatch(dmSetTemplateDocuments(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchDocumentTypes(filterForLibrary) {
  const url = 'document_manager/fund_documents/document_types';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        show_library_doc_types: filterForLibrary,
      },
    }).then((response) => {
      dispatch(dmSetDocumentTypes(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchTemplateDocumentTypes() {
  const url = 'document_manager/fund_documents/template_document_types';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
    }).then((response) => {
      dispatch(dmSetDocumentTypes(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchBranchCodes(wlpId) {
  const url = 'document_manager/fund_documents/branch_codes'
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        wlp_id: wlpId,
      },
    }).then((response) => {
      dispatch(dmSetBranchCodes(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchFundGroups(organizationId) {
  const url = 'document_manager/fund_groups';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: {
        organization_id: organizationId,
      },
    }).then((response) => {
      const fundGroups = response.data?.fund_groups || [];
      dispatch(dmSetFundGroups(fundGroups));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function formData(file, companionFile) {
  const form = new FormData();

  if (file) {
    form.append('file', file);
  }

  if (companionFile) {
    form.append('companion_file', companionFile)
  }

  return form;
}

function dmUploadFundDocument(documentDetails) {
  const url = 'document_manager/fund_documents/';

  const file = formData(documentDetails.file, documentDetails.companionFile);
  const params = {
    fund_ids: documentDetails.selectedFunds.map((e) => e.value),
    wlp_id: documentDetails.selectedWlp.value,
    firm_id: documentDetails.selectedFirm.value,
    excluded_wlps: documentDetails.selectedExcWlps.map((e) => e.value),
    excluded_firms: documentDetails.selectedExcFirms.map((e) => e.value),
    selected_branch_codes: documentDetails.selectedBranchCodes?.map((e) => e.value),
    doc_type: documentDetails.selectedDocType.label,
    date: documentDetails.selectedDate,
    notify_publication: documentDetails.notifyPublication,
    offering_material: documentDetails.offeringMaterial,
    display_name: documentDetails.displayName,
    preview_emails: documentDetails.previewEmails,
    preview_status: documentDetails.previewStatus,
    preview_event_uuid: documentDetails.previewEventUuid,
    restrict_downloads: documentDetails.restrictDownloads,
    hide_from_doc_center: documentDetails.hideFromDocCenter,
    internal: documentDetails.internal,
    previewer_only: documentDetails.previewerOnly,
    expiration_date: documentDetails.expirationDate,
    form_responses: documentDetails.formResponses,
    library_doc_id: documentDetails.libraryDocId,
    template_id: documentDetails.selectedTemplate?.value,
    wrap_document: documentDetails.wrapDocument,
  };

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, file, {
      withCredentials: true,
      params,
    }).then((response) => {
      dispatch(dmUploadDocument(response.data));
      response.data.forEach(element => {
        dispatch(dmFetchDocumentsStatus(element.fund.id, [element.id]));
      });
      dispatch(spinnerActions.endApiRequest());
    }).catch((error) => {
      const isInputError = error.status >= 400 && error.status < 500;
      const errorMessage = error.data?.error || error.statusText
      const message = isInputError ? errorMessage : 'There was an error uploading the document.';
      notificationService.notifyError('Error', message);
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUploadToIdm(documentDetails) {
  const url = 'document_manager/fund_documents/upload_to_idm';

  const file = formData(documentDetails.file);
  const params = {
    fund_id: documentDetails.selectedFunds.map((e) => e.value),
    wlp_id: documentDetails.selectedWlp.value,
    external_document_type_id: documentDetails.selectedDocType.label,
    effective_date: documentDetails.selectedDate,
    template_id: documentDetails.selectedTemplate?.value,
    wrap_document: documentDetails.wrapDocument,
  };

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, file, {
      withCredentials: true,
      params,
    }).then(() => {
      notificationService.notifySuccess('Document has been rerouted', '', 10000, '/publisher_tool', 'Publisher Tool');
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('Uploading to IDM/Document Center has failed, please try again');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUploadFundLibraryDocument(documentDetails) {
  const url = 'document_manager/fund_library_documents/';

  const file = formData(documentDetails.file);
  const params = {
    fund_ids: documentDetails.selectedFunds.map(e => e.value),
    wlp_id: documentDetails.selectedWlp.value,
    doc_type: documentDetails.selectedDocType.label,
    date: documentDetails.selectedDate,
    display_name: documentDetails.displayName,
    expiration_date: documentDetails.expirationDate,
    form_responses: documentDetails.formResponses,
  };

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, file, {
      withCredentials: true,
      params,
    }).then((response) => {
      dispatch(dmUploadLibraryDocument(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch((error) => {
      const isInputError = error.status >= 400 && error.status < 500;
      const errorMessage = error.data?.error || error.statusText
      const message = isInputError ? errorMessage : 'There was an error uploading the document.';
      notificationService.notifyError('Error', message);
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUploadFundDocumentTemplate(documentDetails) {
  const url = 'document_manager/fund_document_templates/';

  const file = formData(documentDetails.file);
  const params = {
    fund_id: documentDetails.selectedFund.value,
    wlp_ids: documentDetails.selectedWlps.map((wlp) => wlp.value),
    doc_type: documentDetails.selectedDocType.label,
    date: documentDetails.selectedDate,
    display_name: documentDetails.displayName,
    expiration_date: documentDetails.expirationDate,
    orientation: documentDetails.orientation,
    has_date_watermark: documentDetails.hasDateWatermark,
    date_watermark_position_x: documentDetails.dateWatermarkPositionX,
    date_watermark_position_y: documentDetails.dateWatermarkPositionY,
  };

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, file, {
      withCredentials: true,
      params,
    }).then((response) => {
      dispatch(dmUploadTemplateDocument(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch((error) => {
      const isInputError = error.status >= 400 && error.status < 500;
      const errorMessage = error.data?.error || error.statusText
      const message = isInputError ? errorMessage : 'There was an error uploading the document.';
      notificationService.notifyError('Error', message);
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmApproveDocument(document) {
  const id = document.id;

  const url = `document_manager/fund_documents/${id}/approve`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, {
      withCredentials: true,
    }).then(() => {
      notificationService.notifySuccess('Notifications will be sent for this document', 'Approved Fund Document');
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('A different user must approve this document', 'Unable to approve Fund Document');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmApproveTemplate(document) {
  const id = document.id;

  const url = `document_manager/fund_document_templates/${id}/approve`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, {
      withCredentials: true,
    }).then(() => {
      // notificationService.notifySuccess('Notifications will be sent for this document', 'Approved Fund Document');
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('A different user must approve this document', 'Unable to approve Fund Document Template');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmRejectTemplate(document) {
  const id = document.id;

  const url = `document_manager/fund_document_templates/${id}/reject`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, {
      withCredentials: true,
    }).then(() => {
      // notificationService.notifySuccess('Notifications will be sent for this document', 'Approved Fund Document');
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('Unable to reject Fund Document Template');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmApproveUpload(document) {
  const id = document.id;

  const url = `document_manager/fund_documents/${id}/approve_upload`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, {
      withCredentials: true,
    }).then(() => {
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('A different user must approve this upload', 'Unable to approve the upload');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmRejectUpload(document) {
  const id = document.id;

  const url = `document_manager/fund_documents/${id}/reject_upload`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, {
      withCredentials: true,
    }).then(() => {
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('Unable to reject the upload');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmApproveFundLibraryDocument(document) {
  const url = `document_manager/fund_library_documents/${document.id}/approve`

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, {
      withCredentials: true,
    }).then(() => {
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      notificationService.notifyError('A different user must approve this document', 'Unable to approve Fund Library Document');
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUpdateDocumentDetails(documentDetails) {
  const url = `document_manager/fund_documents/${documentDetails.id}`;

  const file = formData(null, documentDetails.companionFile);
  const params = {
    id: documentDetails.id,
    fund_id: documentDetails.selectedFunds[0].value,
    wlp_id: documentDetails.selectedWlp.value,
    firm_id: documentDetails.selectedFirm.value,
    excluded_wlps: documentDetails.selectedExcWlps.map((e) => e.value),
    excluded_firms: documentDetails.selectedExcFirms.map((e) => e.value),
    selected_branch_codes: documentDetails.selectedBranchCodes?.map((e) => e.value),
    doc_type: documentDetails.selectedDocType.label,
    date: documentDetails.selectedDate,
    notify_publication: documentDetails.notifyPublication,
    offering_material: documentDetails.offeringMaterial,
    display_name: documentDetails.displayName,
    preview_emails: documentDetails.previewEmails,
    preview_status: documentDetails.previewStatus,
    preview_event_uuid: documentDetails.previewEventUuid,
    restrict_downloads: documentDetails.restrictDownloads,
    hide_from_doc_center: documentDetails.hideFromDocCenter,
    internal: documentDetails.internal,
    previewer_only: documentDetails.previewerOnly,
    expiration_date: documentDetails.expirationDate,
    form_responses: documentDetails.formResponses,
  };

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, file, {
      withCredentials: true,
      params: { ...params },
    }).then((response) => {
      dispatch(dmUpdateDocument(response.data));
      dispatch(dmFetchDocumentsStatus(response.data.fund.id, [response.data.id]));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUpdateLibraryDocumentDetails(documentDetails) {
  const url = `document_manager/fund_library_documents/${documentDetails.id}`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, {
      withCredentials: true,
      id: documentDetails.id,
      fund_id: documentDetails.selectedFunds[0].value,
      wlp_id: documentDetails.selectedWlp.value,
      doc_type: documentDetails.selectedDocType.label,
      date: documentDetails.selectedDate,
      display_name: documentDetails.displayName,
      expiration_date: documentDetails.expirationDate,
      form_responses: documentDetails.formResponses,
    }).then((response) => {
      dispatch(dmUpdateLibraryDocument(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmUpdateFundDocumentTemplateDetails(documentDetails) {
  const url = `document_manager/fund_document_templates/${documentDetails.id}`;
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, {
      withCredentials: true,
      fund_id: documentDetails.selectedFund.value,
      wlp_ids: documentDetails.selectedWlps.map((wlp) => wlp.value),
      doc_type: documentDetails.selectedDocType.label,
      date: documentDetails.selectedDate,
      display_name: documentDetails.displayName,
      expiration_date: documentDetails.expirationDate,
      orientation: documentDetails.orientation,
      has_date_watermark: documentDetails.hasDateWatermark,
      date_watermark_position_x: documentDetails.dateWatermarkPositionX,
      date_watermark_position_y: documentDetails.dateWatermarkPositionY,
    }).then((response) => {
      dispatch(dmUpdateFundDocumentTemplate(response.data));
      dispatch(spinnerActions.endApiRequest());
    }).catch((error) => {
      const isInputError = error.status >= 400 && error.status < 500;
      const errorMessage = error.data?.error || error.statusText
      const message = isInputError ? errorMessage : 'There was an error updating the document.';
      notificationService.notifyError('Error', message);
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmSetDocumentOrder({
  fundId, id, allDocuments, oldPosition, newPosition,
}) {
  const url = `document_manager/fund_documents/${id}/position`;
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.patch(url, null, {
      withCredentials: true,
      params: {
        fund_id: fundId,
        old_position: oldPosition,
        new_position: newPosition,
        all_docs: allDocuments,
      },
    }).then((response) => {
      dispatch(dmSetDocuments(response.data));
      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchDocumentsStatus(fundId, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmDeleteDocument(document) {
  const id = document.id;
  const fundId = document.fund.id;

  const url = `document_manager/fund_documents/${id}`;
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.delete(url, {
      withCredentials: true,
      params: {
        fund_id: fundId,
      },
    }).then((response) => {
      dispatch(dmDeleteDocumentFile({ fund_id: fundId, doc_id: id, documents: response.data }));
      const fundDocumentIds = response.data.map((fundDocument) => fundDocument.id);
      dispatch(dmFetchDocumentsStatus(fundId, fundDocumentIds));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmDeleteLibraryDocument(document) {
  const id = document.id;

  const url = `document_manager/fund_library_documents/${id}`;
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.delete(url, {
      withCredentials: true,
    }).then((response) => {
      dispatch(dmDeleteLibraryDocumentFile({ doc_id: id }));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

const dmBackgroundWatermarking = (fundId, fundDocumentId) => (
  postRequest({
    url: `funds/${fundId}/fund_documents/${fundDocumentId}/generate_watermark_document`,
    onSuccess: dmSetDocumentsStatus,
  })
);

const dmCheckIfFileExists = (fileType, fileInfo, onSuccess) => {
  const className = fileType === 'FUND_DOC' ? 'fund_documents' : 'fund_library_documents';
  const url = `document_manager/${className}/document_exists`;
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.post(url, {
      doc_type: fileInfo.selectedDocType.label,
      fund_ids: fileInfo.selectedFunds.map((e) => e.value),
      wlp_id: fileInfo.selectedWlp.value,
      firm_id: fileInfo.selectedFirm?.value,
      display_name: fileInfo.displayName,
    }).then((response) => {
      onSuccess(response);
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
};

export default {
  dmLoadedFirms,
  dmLoadingFirms,
  dmLoadedFunds,
  dmLoadingFunds,
  dmLoadedWlps,
  dmLoadingWlps,
  dmSetFunds,
  dmSetWlps,
  dmSetFirms,
  dmSetUsers,
  dmSetBranchCodes,
  dmSetFundGroups,
  dmToggleAllDocs,
  dmSetDocuments,
  dmSetLibraryDocuments,
  dmSetTemplateDocuments,
  dmSetDocumentTypes,
  dmUploadDocument,
  dmUpdateDocument,
  dmDeleteDocumentFile,
  dmSelectFund,
  dmSelectFunds,
  dmSelectWlp,
  dmSelectWlps,
  dmSelectFirm,
  dmSelectFirms,
  dmSelectUser,
  dmFetchFunds,
  dmFetchFundsAutocomplete,
  dmFetchWlps,
  dmFetchFirms,
  dmFetchFirmsAutocomplete,
  dmFetchUsersAutocomplete,
  dmFetchDocuments,
  dmFetchMultipleDocuments,
  dmFetchPendingApproval,
  dmFetchPendingNotifications,
  dmFetchExpiringDocuments,
  dmFetchExpiredDocuments,
  dmFetchExpiredDocuments,
  dmFetchLibraryDocuments,
  dmFetchMultipleLibraryDocuments,
  dmFetchFundDocumentTemplates,
  dmFetchDocumentTypes,
  dmFetchTemplateDocumentTypes,
  dmFetchBranchCodes,
  dmFetchFundGroups,
  dmUploadFundDocument,
  dmUploadFundLibraryDocument,
  dmUploadFundDocumentTemplate,
  dmUpdateDocumentDetails,
  dmUpdateLibraryDocumentDetails,
  dmUpdateFundDocumentTemplateDetails,
  dmDeleteDocument,
  dmDeleteLibraryDocument,
  dmSetDocumentOrder,
  dmSetDocumentsStatus,
  dmFetchDocumentsStatus,
  dmBackgroundWatermarking,
  dmApproveDocument,
  dmApproveTemplate,
  dmRejectTemplate,
  dmApproveFundLibraryDocument,
  dmApproveUpload,
  dmRejectUpload,
  dmCheckIfFileExists,
  dmUploadToIdm,
};
