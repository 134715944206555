export const PUBLIC_PATH = '/icn_react/static';
const BASE_FEATURE_URL = 'nav_reconciliation';
export const BASE_ROUTE = `${PUBLIC_PATH}/${BASE_FEATURE_URL}`;
export const FUND_ADMIN_BASE_ROUTE = `${PUBLIC_PATH}/fund_admin/${BASE_FEATURE_URL}`;
export const REDIRECT_PATH = `${PUBLIC_PATH}/home`;
export const PAF_ID_PARAM = 'pafId';
export const REPORT_DATE_PARAM = 'reportDate';
export const PAF_ID_URL_PARAM = `:${PAF_ID_PARAM}`;
export const REPORT_DATE_URL_PARAM = `:${REPORT_DATE_PARAM}`;
export const DETAIL_PATH = `${BASE_ROUTE}/${PAF_ID_URL_PARAM}/${REPORT_DATE_URL_PARAM}`;
