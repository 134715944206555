/* eslint-disable max-lines */
import { actionFactory } from 'services/actions';
import {
  getRequest,
  postRequest,
} from 'actions/utils';

import { onBootstrapRetrieved } from 'services/bootstrapServices';
import {
  REGISTER_USER,
  REGISTER_UPDATE_STEP,
  REGISTER_SET_PROFILE,
  REGISTER_SET_PROFILE_INITIAL_STATE,
  REGISTER_SET_PROFILE_OPTIONS,
  REGISTER_SET_INITIAL_STATE,
  REGISTER_SET_USER_INVITE,
  REGISTER_INVITED_USERS,
  REGISTER_SET_QUALIFICATION_QUESTIONS,
  REGISTER_UPDATE_QUALIFICATION_QUESTIONS,
  REGISTER_SET_IS_LOADING,
} from 'actions/actionsConstants';

import {
  LP_SET_INSIGHTS,
  LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH,
} from 'modules/landing_page/actions/constants';

const rRegisterUser = actionFactory(REGISTER_USER);
const rInvitedUsers = actionFactory(REGISTER_INVITED_USERS);
const rUpdateStep = actionFactory(REGISTER_UPDATE_STEP);
const rSaveProfileFields = actionFactory(REGISTER_SET_PROFILE);
const rSaveProfileInitialFields = actionFactory(REGISTER_SET_PROFILE_INITIAL_STATE);
const rSetProfileOptions = actionFactory(REGISTER_SET_PROFILE_OPTIONS);
const rSetInitialState = actionFactory(REGISTER_SET_INITIAL_STATE);
const rSetUserInvite = actionFactory(REGISTER_SET_USER_INVITE);
const rSetIsLoading = actionFactory(REGISTER_SET_IS_LOADING);

const rSetQualificationQuestions = actionFactory(REGISTER_SET_QUALIFICATION_QUESTIONS);
const rUpdateQualificationQuestions = actionFactory(REGISTER_UPDATE_QUALIFICATION_QUESTIONS);

const setInsights = actionFactory(LP_SET_INSIGHTS);
const setIsLoadingInsightsAndResearch = actionFactory(LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH);

const registrationUrl = 'registration/';
const usersUrl = 'users/';
const firmsUrl = 'firms/';

function setRegistrationInitialState(state) {
  return (dispatch) => {
    dispatch(rSetInitialState(state));
  };
}

function getUserInvite(token) {
  const params = { token };
  return getRequest({
    url: `${registrationUrl}get_invite_info`,
    params,
    onSuccess: (response) => rSetUserInvite(response.user),
  });
}

function getInvitedUsers(page, perPage, search = '') {
  const params = { page, perPage, search };
  return getRequest({
    url: `${registrationUrl}invited_users`,
    params,
    onSuccess: (response) => rInvitedUsers(response),
  });
}

function openUserInviteReminderModal(item) {
  return (dispatch) => {
    dispatch({
      type: USER_INVITE_OPEN_REMINDER_MODAL,
      payload: item,
    });
  };
}

function emailAlreadyRegistered(email) {
  const params = { email };
  return getRequest({
    url: `${registrationUrl}email_already_registered`,
    params,
    showSpinner: false,
  });
}

function registerUser(user, isRegistrationProcess = false, showSpinner = true) {
  const data = {
    user,
    is_registration_process: isRegistrationProcess,
  };

  return postRequest({
    showSpinner,
    url: registrationUrl,
    data,
    onSuccess: (response) => rRegisterUser(response.user),
  });
}

function updateUserFields(values) {
  return (dispatch) => {
    dispatch(rRegisterUser(values));
  };
}

function validateVerificationCode(code, email, showSpinner = true) {
  const data = { email, code };
  return postRequest({
    showSpinner,
    url: `${registrationUrl}validate_otp_code`,
    data,
  });
}

function resendVerificationCode(email, showSpinner = true) {
  const params = { email };
  return getRequest({
    url: `${registrationUrl}resend_otp_code`,
    params,
    showSpinner,
  });
}

function bootstrapData(response) {
  return (dispatch) => {
    onBootstrapRetrieved(response, dispatch);
  };
}

function initBootstrapRegistration(showSpinner = true) {
  const url = `${usersUrl}react_bootstrap_identity`;
  return getRequest({
    showSpinner,
    url,
    onSuccess: bootstrapData,
  });
}

function checkRoleMfaRequirement(role, email, showSpinner = true) {
  const params = { role, email };
  return getRequest({
    url: `${usersUrl}check_roles_mfa_requirement`,
    params,
    showSpinner,
  });
}

function searchRiaByName(query, email, showSpinner = true) {
  const params = { query, email };

  return getRequest({
    url: `${firmsUrl}/firms_from_domain`,
    params,
    showSpinner,
  });
}

function updateStep(step) {
  return (dispatch) => {
    dispatch(rUpdateStep(step));
  };
}

const formatUserAccreditationFields = (response) => {
  if (response.firm_confirm_us_location != null) { response.firm_confirm_us_location = response.firm_confirm_us_location === 'true'; }
  if (response.registered_with_sec != null) { response.registered_with_sec = response.registered_with_sec === 'true'; }
  if (response.firm_has_qp_clients != null) { response.firm_has_qp_clients = response.firm_has_qp_clients === 'true'; }
  if (response.attestation != null) { response.attestation = response.attestation === 'true'; }
};

const saveProfileFields = (response) => (
  (dispatch) => {
    formatUserAccreditationFields(response);
    dispatch(rSaveProfileFields(response));
  }
);

const saveProfile = (response) => (
  (dispatch) => {
    formatUserAccreditationFields(response);
    dispatch(rSaveProfileFields(response));
    dispatch(rSaveProfileInitialFields(response));
  }
);

function saveRegistrationProfile(userId, questionnaire, userInviteToken, skip = false, showSpinner = true) {
  return postRequest({
    showSpinner,
    url: `/${usersUrl}${userId}/registration_answers`,
    data: {
      registration_answer: questionnaire,
      skip_validations: skip,
      user_invite_token: userInviteToken,
    },
    onSuccess: (response) => saveProfile(response),
  });
}

function updateRegistrationProfile(userId, questionnaire, userInviteToken, questionIds, showSpinner = true) {
  return postRequest({
    showSpinner,
    url: `/${usersUrl}${userId}/registration_answers/update`,
    data: {
      registration_answer: questionnaire,
      skip_validations: true,
      user_invite_token: userInviteToken,
      iqs_question_ids: questionIds,
    },
    onSuccess: (response) => saveProfile(response),
  });
}

function bootstrapProfileFields(userId, showSpinner = true) {
  const params = { user_id: userId };
  return getRequest({
    showSpinner,
    url: 'registration_answers/answers',
    params,
    onSuccess: (response) => saveProfile(response.registration_answer),
  });
}

function setRegistrationProfileFields(values) {
  return (dispatch) => {
    dispatch(rSaveProfileFields(values));
  };
}

function loadProfileOptions(userId, showSpinner = true) {
  const params = { user_id: userId };
  return getRequest({
    showSpinner,
    url: 'registration_answers/options',
    params,
    onSuccess: rSetProfileOptions,
  });
}

function loadQualificationQuestions(userId, accountTypeId, showSpinner = true) {
  const params = {
    user_id: userId,
    registration_account_type_id: accountTypeId,
  };

  return getRequest({
    showSpinner,
    url: 'investor_qualification_standard_question',
    params,
    onSuccess: rSetQualificationQuestions,
  });
}

function checkQualificationQuestions(id, checked) {
  const question = {
    id,
    checked,
  };

  return (dispatch) => {
    dispatch(rUpdateQualificationQuestions(question));
  };
}

function getInsightsAndResearch() {
  return (dispatch) => dispatch(
    getRequest({
      url: 'icapital_insights',
      showSpinner: false,
      onSuccess: (payload) => {
        dispatch(setIsLoadingInsightsAndResearch(false));
        dispatch(setInsights(payload));
      },
    })
  );
}

function setIsRegistrationLoading(isLoading) {
  return (dispatch) => dispatch(rSetIsLoading(isLoading));
}

export default {
  registerUser,
  updateUserFields,
  getInvitedUsers,
  getInsightsAndResearch,
  validateVerificationCode,
  resendVerificationCode,
  searchRiaByName,
  updateStep,
  saveProfile,
  saveProfileFields,
  saveRegistrationProfile,
  updateRegistrationProfile,
  bootstrapProfileFields,
  setRegistrationProfileFields,
  loadProfileOptions,
  emailAlreadyRegistered,
  setRegistrationInitialState,
  checkRoleMfaRequirement,
  getUserInvite,
  initBootstrapRegistration,
  loadQualificationQuestions,
  checkQualificationQuestions,
  setIsRegistrationLoading,
};
