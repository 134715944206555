import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { postRequest } from 'services/apiRequest';
import { currentPageName } from 'services/client_management/cell_renderers/helpers';

export const previewInvestmentSubscription = (investmentId) =>
  postRequest({
    errorMessage: FALLBACK_ERROR_MESSAGE,
    url: 'agreement_previews/',
    data: {
      investment_id: investmentId,
      type: 'investment_subscription',
      from_page: currentPageName(window.location.pathname),
    },
  });
