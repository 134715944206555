/* eslint-disable max-statements */
import { isEmpty, trim, isUndefined, isNil } from 'lodash';
import { showFirmType } from 'containers/registration/RegistrationUtils';
import {
  FINANCIAL_ADVISOR_ROLE,
  FIRM_TYPES_WITH_REQUIRED_AUM,
  COUNTRIES_ZIP_NOT_REQUIRED,
  COUNTRIES_USA_AND_CANADA,
  GP_TEAM_MEMBER,
  GP_ADMIN,
  EDUCATION_USER,
  ADVISOR_ROLES,
} from './profileData';

const isTextFilled = (text) => !isEmpty(trim(text));

const isLocationFilled = (profile) =>
  profile.firm_confirm_us_location ? isTextFilled(profile.state) : isTextFilled(profile.country);

const isFirmTypeFilled = (profile) => {
  let isFirmFilled = false;
  if (showFirmType(profile)) {
    isFirmFilled = isTextFilled(profile.firm_name) && isTextFilled(profile.firm_type);
  } else if (!showFirmType(profile)) {
    isFirmFilled = isTextFilled(profile.firm_name);
  } else {
    isFirmFilled = true;
  }
  return isFirmFilled;
};

const isRegisteredWithSecFilled = (profile) =>
  profile.firm_confirm_us_location ? !isNil(profile.registered_with_sec) : true;

const isCrdNumberFilled = (profile) =>
  profile.firm_confirm_us_location &&
  isTextFilled(profile.firm_name) &&
  profile.added_firm &&
  isTextFilled(profile.is_registered_bd_or_ia) &&
  profile.is_registered_bd_or_ia !== 'none'
    ? isTextFilled(profile.crd_number)
    : true;

const isStateFilled = (profile) =>
  COUNTRIES_USA_AND_CANADA.includes(profile.country) ? isTextFilled(profile.state) : true;

const isClientFromDataSync = (role) => role === 'qpcl';

const isCommonFieldsFilled = (profile) =>
  isTextFilled(profile.first_name) && isTextFilled(profile.last_name) && profile.attestation;

const isAdvisorFilled = (profile) =>
  !isNil(profile.advisor_confirmed) &&
  (!profile.advisor_confirmed || (profile.advisor_confirmed && !isNil(profile.firm_name)));

const selfEmployedStatus = (profile) => isTextFilled(profile.role) && isTextFilled(profile.type_of_business);

const isFirmAssetsFilled = (profile) =>
  !isUndefined(profile.firm_type) && FIRM_TYPES_WITH_REQUIRED_AUM.includes(profile.firm_type)
    ? isTextFilled(profile.firm_assets)
    : true;

const employmentStatus = (profile) => {
  switch (profile.employment_status) {
    case 'Employed':
      return isTextFilled(profile.employer) && selfEmployedStatus(profile);
    case 'Self Employed':
      return selfEmployedStatus(profile);
    default:
      return true;
  }
};

const isZipFilled = (profile) => COUNTRIES_ZIP_NOT_REQUIRED.includes(profile.country) || isTextFilled(profile.zip);

// Advisor CRD Number is required on FA questionnaire if role is FA and firm located in US
const isAdvisorCrdNumberFilled = (profile) =>
  profile.firm_confirm_us_location && isTextFilled(profile.role) && profile.role === FINANCIAL_ADVISOR_ROLE
    ? isTextFilled(profile.advisor_crd_number)
    : true;

function useCompleteProfile(role, profile, isInvitedClient) {
  const allInputsFilled = () => {
    if (role === 'ria' || ADVISOR_ROLES.includes(role)) {
      return (
        isTextFilled(profile.first_name) &&
        isTextFilled(profile.last_name) &&
        isFirmTypeFilled(profile) &&
        isLocationFilled(profile) &&
        isRegisteredWithSecFilled(profile) &&
        isCrdNumberFilled(profile) &&
        isAdvisorCrdNumberFilled(profile) &&
        isTextFilled(profile.firm_name) &&
        isFirmAssetsFilled(profile) &&
        isTextFilled(profile.role) &&
        !isNil(profile.firm_confirm_us_location)
      );
    }

    if (role === 'sfo') {
      return (
        isTextFilled(profile.first_name) &&
        isTextFilled(profile.last_name) &&
        isTextFilled(profile.firm_type) &&
        isTextFilled(profile.firm_name) &&
        isTextFilled(profile.firm_assets) &&
        isTextFilled(profile.role) &&
        isTextFilled(profile.country) &&
        isTextFilled(profile.address1) &&
        isTextFilled(profile.city) &&
        isZipFilled(profile) &&
        isTextFilled(profile.firm_investment_total_value)
      );
    }

    if (role === 'qp' || role === 'delegation' || role === 'delegate_invest') {
      return (
        isTextFilled(profile.first_name) &&
        isTextFilled(profile.last_name) &&
        isTextFilled(profile.country) &&
        isTextFilled(profile.address1) &&
        isTextFilled(profile.city) &&
        isStateFilled(profile) &&
        isZipFilled(profile) &&
        isAdvisorFilled(profile) &&
        isTextFilled(profile.net_worth) &&
        isTextFilled(profile.individual_investment_total_value) &&
        !isEmpty(profile.employment_status) &&
        employmentStatus(profile)
      );
    }

    if (role === GP_TEAM_MEMBER || role === GP_ADMIN || role === EDUCATION_USER) {
      return (
        isTextFilled(profile.first_name) &&
        isTextFilled(profile.last_name) &&
        isTextFilled(profile.country) &&
        isTextFilled(profile.address1) &&
        isTextFilled(profile.city) &&
        isStateFilled(profile) &&
        isZipFilled(profile)
      );
    }

    if (role === 'aicl') {
      return (
        profile.aicl_confirmed &&
        (profile.advisor_confirmed ? isTextFilled(profile.client_year) && profile.client_year.length === 4 : false)
      );
    }

    if (isInvitedClient || isClientFromDataSync(role)) {
      return profile.advisor_confirmed ? isTextFilled(profile.client_year) && profile.client_year.length === 4 : false;
    }

    return false;
  };

  return {
    allInputsFilled,
  };
}

export {
  isLocationFilled,
  isFirmTypeFilled,
  isRegisteredWithSecFilled,
  isCrdNumberFilled,
  isStateFilled,
  isClientFromDataSync,
  isCommonFieldsFilled,
  isZipFilled,
  isAdvisorFilled,
  employmentStatus,
  useCompleteProfile,
  isTextFilled,
  isFirmAssetsFilled,
};
