import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentUser } from './current_user';
import { routeCodes } from '../router';
const onPublicPage = (location) => [
    routeCodes.LOGIN_MFE,
    routeCodes.REGISTRATION_PROCESS,
    routeCodes.MULTIFACTOR_AUTHENTICATION_VERIFY,
    routeCodes.MULTIFACTOR_AUTHENTICATION_REGISTER,
    routeCodes.DISCLOSURES,
    routeCodes.TERMS_OF_USE,
].some((route) => location.pathname.startsWith(route));
/*
  Similar to Angular's app/assets/javascripts/services/redirects.coffee + routes_util_service.coffee
  Listens to various state values and redirects/jails users if needed.
*/
export default function useRouteListeners(bootstrap) {
    const location = useLocation();
    const history = useHistory();
    const user = useCurrentUser();
    useEffect(() => {
        // Pending MFA phone setup or code verify
        if ((bootstrap === null || bootstrap === void 0 ? void 0 : bootstrap.pending_mfa_registration) && !onPublicPage(location)) {
            history.push((user === null || user === void 0 ? void 0 : user.phone) ? routeCodes.MULTIFACTOR_AUTHENTICATION_VERIFY : routeCodes.MULTIFACTOR_AUTHENTICATION_REGISTER);
        }
        // Logged in user on /login --> Go to their homepage
        else if (user && location.pathname.startsWith(routeCodes.LOGIN_MFE)) {
            window.location.replace(user.default_page);
        }
    }, [bootstrap, history, location, user]);
}
