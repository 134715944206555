const i18nNamespaces = [
  'activity_log',
  'advisor_dashboard',
  'aml_documents',
  'announcement',
  'bulk_investment',
  'client_management',
  'common',
  'configuration_master',
  'definitions',
  'custodian_integrations',
  'disclaimer',
  'doc_center',
  'document_definition',
  'education',
  'events',
  'fund_profile',
  'glossary',
  'gp_countersignature',
  'impersonation',
  'initiate_transfer',
  'insight',
  'integration_partners_dashboard',
  'investment_dashboard',
  'investment_details',
  'investment_opportunities',
  'investor_representative',
  'jurisdictions',
  'letter_of_authorization',
  'library_templates',
  'list_opportunities',
  'manage_close',
  'manage_nominee_allocations',
  'passport',
  'polyglot',
  'product_profile_builder',
  'profile',
  'registration',
  'release_notes',
  'remediation',
  'required_documents',
  'sales_dashboard',
  'settings',
  'subscription_review',
  'subscriptions_dashboard',
  'subscription_workflow_status',
  'team_management',
  'third_party_vendors',
  'transfers_review',
  'compare_products',
  'product_access',
  'view_documents',
];

const languages = ['en', 'es', 'fr', 'de', 'it', 'pt', 'zh'];

const i18nResources = {};
languages.forEach((language) => {
  i18nResources[language] = {};
  i18nNamespaces.forEach((namespace) => {
    try {
      i18nResources[language][namespace] = require(`./${namespace}/${language}.json`);
    } catch (e) {
      i18nResources[language][namespace] = {};
    }
  });
});

export { i18nResources, i18nNamespaces };
