/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { arrayOf, bool, number, shape, string, func } from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@icapitalnetwork/supernova-core';
import { useMenu, useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from 'actions';
import sanitizeHtml from 'sanitize-html';
import _ from 'lodash';
import * as icnBootstrapSelectors from 'selectors/icnBootstrapSelectors';
import { scrollRequiredLabel } from 'constants/registrations';
import CheckIcon from '@mui/icons-material/Check';
import LanguageIcon from '@mui/icons-material/Language';
import { getAvailableLanguages } from 'modules/polyglot/state/selectors';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import styles from './TermsOfUsePopup.module.scss';

export const TermsOfUsePopup = ({
  user,
  terms,
  isOpen,
  isWhiteLabel,
  errorMessage,
  acceptTermsOfUse,
  resetErrorMessage,
  updateUserAttributes,
  defaultLanguage,
  availableLanguages,
}) => {
  const { i18n } = useTranslation('common');

  const activeIcnTermsAccepted = user.active_main_site_terms_accepted;
  const activeWlpTermsAccepted = user.active_wlp_terms_accepted;

  const [activeTerm, setActiveTerm] = useState({});
  const [activeTermValues, setActiveTermValues] = useState({});
  const [acceptIcnTerm, setAcceptIcnTerm] = useState(activeIcnTermsAccepted);
  const [acceptWlpTerm, setAcceptWlpTerm] = useState(activeWlpTermsAccepted);
  const [languageContext, setLanguageContext] = useState({});
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [termsProgressBar, setTermsProgressBar] = useState(0);
  const [language, setLanguage] = useState(defaultLanguage);
  const fixedt = i18n.getFixedT(language);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const DEFAULT_TERMS_TITLE = 'Terms and Conditions Acknowledgement';
  const DEFAULT_TERMS_ACKNOWLEDGE_TEXT =
    'I acknowledge that I have read and agree to the above Terms and Conditions agreement';

  // In case scroll exists,
  // needs to scroll to enable button
  const ref = useRef(null);
  const contentRef = useRef(null);

  const getAvailabeLanguage = (locale) => availableLanguages?.find((lang) => lang.code === locale);

  const buildActiveTermValues = (term) => {
    let hasDefaultLanguage = false;
    const baseValues = {};
    term.translations?.forEach((translation) => {
      const locale = getAvailabeLanguage(translation.locale);

      if (!locale) return;

      if (translation.locale === defaultLanguage) hasDefaultLanguage = true;

      baseValues[translation.locale] = {
        translation_id: translation.id,
        terms_title: translation.terms_title || term.terms_title,
        terms_content: translation.terms_content || term.terms_content,
        terms_ack_text: translation.terms_ack_text || term.terms_ack_text,
        language_name: locale.name,
      };
    });

    if (!hasDefaultLanguage) {
      baseValues[defaultLanguage] = {
        translation_id: null,
        terms_title: term.terms_title,
        terms_content: term.terms_content,
        terms_ack_text: term.terms_ack_text,
        language_name: getAvailabeLanguage(defaultLanguage)?.name,
      };
    }

    return baseValues;
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (target) {
      const progress = ((target.scrollTop + target.offsetHeight) / target.scrollHeight) * 100;
      if (setTermsProgressBar) {
        setTermsProgressBar((current) => {
          if (progress >= 98) {
            setBottom(true);
            return 100;
          }
          if (progress > current) {
            return progress;
          }
          return current;
        });
      }
    }
  };

  const isActiveTermFromMainsite = () => _.isEqual(activeTerm.white_label_partner_id, 0);

  const onRequestClose = () => {
    window.location.href = '/logout';
  };

  const checkResetMessage = () => {
    if (errorMessage.length > 0) {
      resetErrorMessage();
    }
  };

  const checkIcnTerms = useCallback(() => {
    if (!bottom) {
      return enqueueSnackbar(scrollRequiredLabel, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        onCloseAction: (id) => closeSnackbar(id),
      });
    }
    checkResetMessage();
    setAcceptIcnTerm(!acceptIcnTerm);
  }, [closeSnackbar, enqueueSnackbar, bottom, acceptIcnTerm]);

  const checkWlpTerms = useCallback(() => {
    if (!bottom) {
      return enqueueSnackbar(scrollRequiredLabel, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        onCloseAction: (id) => closeSnackbar(id),
      });
    }
    setAcceptWlpTerm(!acceptWlpTerm);
    checkResetMessage();
  }, [closeSnackbar, enqueueSnackbar, bottom, acceptWlpTerm]);

  const showTerms = () => {
    const newActiveTerm = terms.find((term) => term.white_label_partner_id !== activeTerm.white_label_partner_id);
    setActiveTerm(newActiveTerm);
  };

  const showNextTerm = () => terms.length > 1 && (!acceptIcnTerm || (!acceptWlpTerm && isActiveTermFromMainsite()));

  const needToAcceptMainSiteTerms = () =>
    !activeIcnTermsAccepted && (!isWhiteLabel || (isWhiteLabel && !user.enable_custom_wlp_page));

  const acceptTermsAndClose = async (id) => {
    await acceptTermsOfUse(id, languageContext[id]?.translation_id);
    setModalOpen(false);
    updateUserAttributes({ active_main_site_terms_accepted: acceptIcnTerm, active_wlp_terms_accepted: acceptWlpTerm });
  };

  const acceptTerms = async () => {
    if (showNextTerm()) {
      showTerms();
    } else if (acceptIcnTerm && acceptWlpTerm && !activeIcnTermsAccepted && !activeWlpTermsAccepted) {
      await acceptTermsOfUse(terms[0].id, languageContext[terms[0].id]?.translation_id);
      acceptTermsAndClose(terms[1].id);
    } else if (acceptWlpTerm && (activeIcnTermsAccepted || !needToAcceptMainSiteTerms())) {
      acceptTermsAndClose(activeTerm.id);
    } else if (acceptIcnTerm) {
      acceptTermsAndClose(activeTerm.id);
    }
  };

  const activeTermsForLanguage = useCallback(() => activeTermValues[language], [activeTermValues, language]);

  const updateLanguageContext = () => {
    if (Object.keys(activeTermValues).length > 0 && activeTerm.id) {
      setLanguageContext((state) =>
        produce(state, (draft) => {
          draft[activeTerm.id] = { language, translation_id: activeTermsForLanguage().translation_id };
        })
      );
    }
  };

  const termsAckTextLabel = () => {
    if (_.isEmpty(activeTermsForLanguage()?.terms_ack_text)) {
      return (
        <div className={bottom ? styles.terms_of_use__checkbox : styles.terms_of_use__disabled_checkbox}>
          {DEFAULT_TERMS_ACKNOWLEDGE_TEXT}
        </div>
      );
    }

    return (
      <div
        className={bottom ? styles.terms_of_use__checkbox : styles.terms_of_use__disabled_checkbox}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(activeTermsForLanguage()?.terms_ack_text) }}
      />
    );
  };

  const { handleClick, open, anchorEl, handleClose } = useMenu();

  const handleLanguageChange = (locale) => {
    setBottom(false);
    const element = ref.current;
    if (element && element.scrollTo) {
      element.scrollTo(0, 0);
    }
    isActiveTermFromMainsite() ? setAcceptIcnTerm(false) : setAcceptWlpTerm(false);
    setLanguage(locale);
    handleClose();
  };

  useEffect(() => {
    if (!_.isEmpty(terms)) {
      setActiveTerm(terms[0]);
    }
  }, [terms]);

  useEffect(() => {
    setActiveTermValues(buildActiveTermValues(activeTerm));
  }, [activeTerm, language]);

  useEffect(() => {
    setLanguage(languageContext[activeTerm.id]?.language || defaultLanguage);
  }, [activeTerm]);

  useEffect(() => {
    updateLanguageContext();

    const element = ref.current;
    const contentElement = contentRef.current;
    if (element && contentElement) {
      const hasScroll = element.scrollHeight > contentElement.clientHeight;
      setHasScrollBar(hasScroll);

      if (!hasScroll) {
        setBottom(true);
      } else {
        const isTermAccepted = isActiveTermFromMainsite() ? acceptIcnTerm : acceptWlpTerm;
        setTermsProgressBar(isTermAccepted ? 100 : 0);
        element.scrollTo(0, isTermAccepted ? element.scrollHeight : 0);
        setBottom(isTermAccepted);
      }
    }
  }, [activeTermValues]);

  return (
    <>
      {terms.length > 0 && (
        <div data-testid="terms-of-use-popup-container">
          <Dialog maxWidth="md" open={isModalOpen} scroll="paper" onClose={onRequestClose}>
            <DialogTitle>
              {_.isEmpty(activeTermsForLanguage()?.terms_title)
                ? DEFAULT_TERMS_TITLE
                : activeTermsForLanguage()?.terms_title}
            </DialogTitle>

            {Object.keys(activeTermValues).length > 1 && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '9px', paddingLeft: '24px' }}>
                  <Typography style={{ fontWeight: 700 }}>{fixedt('language', 'Language')}</Typography>
                  <Button
                    variant="text"
                    color="primary"
                    aria-controls={open ? 'terms-of-use-language-selection-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <LanguageIcon sx={{ width: '18px', height: '18px', marginRight: '4px' }} />
                    <span>{language.toUpperCase()}</span>
                  </Button>
                </Box>

                <Menu
                  id="terms-of-use-language-selection-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ 'aria-labelledby': 'terms-of-use-language-selection-menu' }}
                >
                  {Object.keys(activeTermValues).map((locale) => (
                    <MenuItem
                      key={locale}
                      value={locale}
                      sx={{ justifyContent: 'space-between', minWidth: '160px' }}
                      onClick={() => handleLanguageChange(locale)}
                    >
                      {activeTermValues[locale].language_name}
                      {locale === language && <CheckIcon sx={{ width: '20px', height: '20px', marginLeft: 2 }} />}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}

            {hasScrollBar && (
              <Box>
                <LinearProgress value={termsProgressBar} variant="determinate" aria-label="terms progress bar" />
              </Box>
            )}

            <DialogContent ref={contentRef} onScroll={handleScroll}>
              <DialogContentText>
                {fixedt(
                  'terms_of_use.disclaimer',
                  'We have updated our Terms and Conditions. Please read the following in full before proceeding.'
                )}
              </DialogContentText>

              <DialogContentText
                ref={ref}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(activeTermsForLanguage()?.terms_content) }}
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Box>
                <FormControl required error={errorMessage.length > 0} component="fieldset" variant="standard">
                  <FormGroup row>
                    {isActiveTermFromMainsite() && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="accept-terms-fake-checkbox"
                            checked={acceptIcnTerm}
                            onChange={checkIcnTerms}
                          />
                        }
                        label={termsAckTextLabel()}
                        aria-label={!bottom && scrollRequiredLabel}
                      />
                    )}

                    {!isActiveTermFromMainsite() && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="accept-terms-fake-checkbox"
                            checked={acceptWlpTerm}
                            onChange={checkWlpTerms}
                          />
                        }
                        label={termsAckTextLabel()}
                        aria-label={!bottom && scrollRequiredLabel}
                      />
                    )}
                  </FormGroup>
                  <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ whiteSpace: 'nowrap' }}>
                <Button variant="text" color="text" size="medium" onClick={() => onRequestClose()}>
                  {fixedt('cancel', 'Cancel')}
                </Button>
                {terms.length > 1 && !isActiveTermFromMainsite() && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => showTerms()}
                    disabled={errorMessage.length > 0}
                  >
                    {fixedt('previous', 'Previous')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={acceptTerms}
                  disabled={
                    (!bottom && hasScrollBar) ||
                    (!acceptIcnTerm && needToAcceptMainSiteTerms()) ||
                    (!acceptWlpTerm && !isActiveTermFromMainsite()) ||
                    errorMessage.length > 0
                  }
                >
                  {showNextTerm() ? fixedt('continue', 'Continue') : fixedt('confirm', 'Confirm')}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {terms.length === 0 && <></>}
    </>
  );
};

function mapStateToProps(state) {
  const user = icnBootstrapSelectors.getUser(state);
  const isWhiteLabel = icnBootstrapSelectors.isWhiteLabelPartner(state);

  return {
    user,
    isWhiteLabel,
    terms: state.termsOfUsePopup.terms,
    errorMessage: state.termsOfUsePopup.errorMessage,
    availableLanguages: getAvailableLanguages(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptTermsOfUse: actions.acceptTermsOfUse,
      resetErrorMessage: actions.resetErrorMessage,
      updateUserAttributes: actions.updateUserAttributes,
    },
    dispatch
  );
}

TermsOfUsePopup.propTypes = {
  user: shape(),
  terms: arrayOf(
    shape({
      terms_title: string,
      terms_content: string,
      terms_ack_text: string,
      white_label_partner_id: number,
      id: number,
    })
  ),
  isOpen: bool.isRequired,
  isWhiteLabel: bool,
  acceptTermsOfUse: func,
  errorMessage: string,
  resetErrorMessage: func,
  updateUserAttributes: func,
  defaultLanguage: string,
  availableLanguages: arrayOf(shape({})),
};

TermsOfUsePopup.defaultProps = {
  user: {},
  terms: [],
  errorMessage: '',
  isWhiteLabel: false,
  acceptTermsOfUse: undefined,
  resetErrorMessage: undefined,
  defaultLanguage: 'en',
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUsePopup);
