import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { patchRequest, getRequest, deleteRequest } from 'services/apiRequest';

export const sendTransferDocument = (
  transfereeInvestment,
  signerData,
  subscriptionDocumentDefinitionId,
  signMethod,
  faFeatureTransferor,
  faFeatureTransferee
) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/private_access_funds/${transfereeInvestment.private_access_fund_id}/investments/${transfereeInvestment.id}/send_transfer_document`,
  data: {
    privateAccessFundId: transfereeInvestment.private_access_fund_id,
    investmentId: transfereeInvestment.id,
    sign_method: signMethod,
    signer_data: signerData,
    subscription_document_definition_id: subscriptionDocumentDefinitionId,
    fa_options: {
      fa_selection: faFeatureTransferor.faSelection,
      selected_signatory_id: faFeatureTransferor.selectedSignatory?.id,
      discretionary_phone: faFeatureTransferor.discretionaryPhone,
    },
    fa_options_transferee: {
      fa_selection: faFeatureTransferee.faSelection,
      selected_signatory_id: faFeatureTransferee.selectedSignatory?.id,
      discretionary_phone: faFeatureTransferee.discretionaryPhone,
    },
  },
});

export const retrieveSubscriptionDocumentDefinition = (investmentId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'subscription_document_definitions/retrieve_subscription_document_definition',
  params: {
    investment_id: investmentId,
    document_type: 'transfer',
  },
});

export const getTransferInvestments = (transferId) => getRequest({
  url: `transfers/${transferId}/transfer_investments`,
  errorMessage: FALLBACK_ERROR_MESSAGE,
});

export const cancelTransfer = (transferId) => deleteRequest({ url: `transfers/${transferId}` });

export const getTransferEvents = (transferId) => getRequest({ url: `transfers/${transferId}/events` });
