import { actionFactory } from 'services/actions';
import {
  getRequest, postRequest, patchRequest, deleteRequest,
} from 'actions/utils';
import { irIsFetching } from 'reducers/investor_representative_reducers/selectors';

import {
  IR_ADD_INVESTOR,
  IR_SET_INVESTOR,
  IR_SET_INVESTORS,
  IR_SET_MANAGERS,
  IR_SET_QUESTIONNAIRE,
  IR_SET_QUESTIONNAIRE_INITIAL_STATE,
  IR_SET_QUESTIONNAIRE_OPTIONS,
  IR_SET_SUCCESS_TOAST_MESSAGE,
  IR_SET_ERROR_TOAST_MESSAGE,
  IR_CLEAN_TOAST_MESSAGES,
  IR_SET_IS_FETCHING,
} from 'actions/actionsConstants';

const irAddInvestor = actionFactory(IR_ADD_INVESTOR);
const irSetInvestors = actionFactory(IR_SET_INVESTORS);
const irSetInvestor = actionFactory(IR_SET_INVESTOR);
const irSetManagers = actionFactory(IR_SET_MANAGERS);
const irSaveQuestionnaireFields = actionFactory(IR_SET_QUESTIONNAIRE);
const irSaveQuestionnaireInitialFields = actionFactory(IR_SET_QUESTIONNAIRE_INITIAL_STATE);
const irSetQuestionnaireOptions = actionFactory(IR_SET_QUESTIONNAIRE_OPTIONS);
const irSetSuccessToastMessage = actionFactory(IR_SET_SUCCESS_TOAST_MESSAGE);
const irSetErrorToastMessage = actionFactory(IR_SET_ERROR_TOAST_MESSAGE);
const irCleanToastMessages = actionFactory(IR_CLEAN_TOAST_MESSAGES);
const irSetIsFetching = actionFactory(IR_SET_IS_FETCHING);

const investorRepresentativeRequestUrl = 'investor_representative_requests';

function investorRepresentativeLoadInvestors() {
  return (dispatch, getState) => {
    const isFetching = irIsFetching(getState());
    dispatch(irSetIsFetching(isFetching + 1));
    return dispatch(getRequest({
      url: `${investorRepresentativeRequestUrl}/investors`,
      onSuccess: irSetInvestors,
      showSpinner: false,
    }));
  };
}

function investorRepresentativeLoadInvestor(userId) {
  const params = { user_id: userId };
  return getRequest({
    url: `${investorRepresentativeRequestUrl}/investors`,
    params,
    onSuccess: (response) => irSetInvestor(response[0]),
  });
}

function loadQuestionnaireOptions() {
  return getRequest({
    url: 'registration_answers/options',
    onSuccess: irSetQuestionnaireOptions,
  });
}

function setSuccessToastMessage(message) {
  return (dispatch) => {
    dispatch(irSetSuccessToastMessage(message));
  };
}

function setErrorToastMessage(title, message) {
  return (dispatch) => {
    dispatch(irSetErrorToastMessage({ title, message }));
  };
}

function cleanToastMessages() {
  return (dispatch) => {
    dispatch(irCleanToastMessages());
  };
}

function sendAgreement(id) {
  const params = { id };
  return postRequest({
    url: `${investorRepresentativeRequestUrl}/send_agreement`,
    params,
    onSuccess: investorRepresentativeLoadInvestors,
  });
}

function resendAgreement(id) {
  const params = { id };
  return postRequest({
    url: 'signature_processes/send_reminder',
    params,
  });
}

function updateInvestorRepresentative(investor) {
  return patchRequest({
    url: `${investorRepresentativeRequestUrl}/${investor.id}`,
    data: {
      request: investor,
    },
    onSuccess: irSetInvestor,
  });
}

const formatUserAccreditFields = (response, valid) => {
  if (response.advisor_confirmed != null) {
    response.advisor_confirmed = response.advisor_confirmed === 'true';
  }
  response.valid = valid;
};

const saveIRQuestionnaireFields = (response, valid) => (
  (dispatch) => {
    formatUserAccreditFields(response, valid);
    dispatch(irSaveQuestionnaireFields(response));
  }
);

const saveIRQuestionnaire = (response, valid) => (
  (dispatch) => {
    formatUserAccreditFields(response, valid);
    dispatch(irSaveQuestionnaireFields(response));
    dispatch(irSaveQuestionnaireInitialFields(response));
  }
);

function saveInvestorRepresentativeQuestionnaire(investor, questionnaire, skip = false) {
  return postRequest({
    url: `/users/${investor.investor_id}/registration_answers`,
    data: {
      registration_answer: questionnaire,
      skip_validations: skip,
    },
    onSuccess: (response) => saveIRQuestionnaireFields(response.accreditation.answers, response.valid),
  });
}

function bootstrapQuestionnaireFields(investorId) {
  const params = { user_id: investorId };
  return getRequest({
    url: 'registration_answers/answers',
    params,
    onSuccess: (response) => saveIRQuestionnaire(response.registration_answer, response.valid),
  });
}

function investorRepresentativeLoadManagers(investorId) {
  return (dispatch, getState) => {
    const isFetching = irIsFetching(getState());
    dispatch(irSetIsFetching(isFetching + 1));
    const params = { investor_id: investorId };
    return dispatch(getRequest({
      url: `${investorRepresentativeRequestUrl}/managers`,
      params,
      onSuccess: irSetManagers,
      showSpinner: false,
    }));
  };
}

function investorRepresentativeAddInvestor(investor) {
  return postRequest({
    url: investorRepresentativeRequestUrl,
    data: {
      email: investor.email,
      first_name: investor.first_name,
      last_name: investor.last_name,
    },
    onSuccess: irAddInvestor,
  });
}

function setRepresentatives(values) {
  return (dispatch) => {
    dispatch(irSetManagers(values));
  };
}

function setInvestor(values) {
  return (dispatch) => {
    dispatch(irSetInvestor(values));
  };
}

function setQuestionnaireFields(values) {
  return (dispatch) => {
    dispatch(irSaveQuestionnaireFields(values));
  };
}

function investorRepresentativeDeleteInvestor(investorId) {
  return deleteRequest({
    url: `${investorRepresentativeRequestUrl}/remove_investor/${investorId}`,
    onSuccess: investorRepresentativeLoadInvestors,
  });
}

function investorRepresentativeRemoveRepresentative(investorRepresentativeId) {
  return deleteRequest({
    url: `${investorRepresentativeRequestUrl}/remove_representative/${investorRepresentativeId}`,
    onSuccess: investorRepresentativeLoadManagers,
  });
}

function investorRepresentativeActAsRepresentative(investorId) {
  return postRequest({
    url: `users/${investorId}/impersonate`,
  });
}

function investorRepresentativeViewAgreement(id) {
  const protocol = window.location.protocol;
  const host = window.location.host;
  window.open(`${protocol}//${host}/api/v1/required_documents/view?id=${id}`, '_blank');
}

function sendSupportEmail(investorId) {
  return postRequest({
    url: `${investorRepresentativeRequestUrl}/send_support_email`,
    data: {
      investor_id: investorId,
    },
  });
}

function sendNoticeConfirmationEmail(investorId) {
  return postRequest({
    url: `${investorRepresentativeRequestUrl}/send_notice_confirmation_email`,
    data: {
      investor_id: investorId,
    },
  });
}

export default {
  investorRepresentativeLoadInvestors,
  investorRepresentativeLoadInvestor,
  investorRepresentativeAddInvestor,
  investorRepresentativeLoadManagers,
  updateInvestorRepresentative,
  investorRepresentativeDeleteInvestor,
  investorRepresentativeRemoveRepresentative,
  investorRepresentativeActAsRepresentative,
  saveInvestorRepresentativeQuestionnaire,
  investorRepresentativeViewAgreement,
  bootstrapQuestionnaireFields,
  setQuestionnaireFields,
  sendAgreement,
  loadQuestionnaireOptions,
  resendAgreement,
  setRepresentatives,
  setInvestor,
  setSuccessToastMessage,
  setErrorToastMessage,
  cleanToastMessages,
  sendSupportEmail,
  sendNoticeConfirmationEmail,
};
