import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import keys from 'lodash/keys';
import { getAccountNameByKey, translateName } from 'services/investment_dashboard/generalBehaviour';

const getFundName = (funds, fundId) => translateName(funds.find((fund) => fund.fund_id === fundId)) || '';
const getFundNameByPafId = (funds, fundId) => translateName(filter(funds, (f) => f.id === fundId)[0]) || '';
const getPafId = (funds, fundId) => filter(funds, (f) => f.fund_id === fundId)[0]?.id;
const getFundId = (funds, pafId) => filter(funds, (f) => f.id == pafId)[0]?.fund_id;

const getFundsFormatted = (fundIds, funds) => {
  if (!fundIds) {
    return [];
  }

  const fundIdsNumbers = fundIds.map((fundId) => Number(fundId));
  const fundsFormatted = funds.filter((f) => fundIdsNumbers.includes(f.fund_id));

  return sortBy(fundsFormatted, [(fund) => fund.name.toLowerCase()]);
};

const getFormattedCapitalCallOustandingData = (data, funds) => {
  if (!data) {
    return [];
  }

  return data.map((pafInfo) => ({
    id: pafInfo.paf_id,
    name: getFundNameByPafId(funds, pafInfo.paf_id),
    amount: Number(pafInfo.amount),
    dueDate: pafInfo.due_date,
    investorName: pafInfo.investor_name,
    fund_id: getFundId(funds, pafInfo.paf_id),
    account_ids: pafInfo.account_ids,
  }));
};

const getStatementsByAccountByFundFormatted = (statementsByAccountByFund, funds, accounts) => {
  const aggregatedFunds = { fundsCount: 0 };
  if (isEmpty(statementsByAccountByFund)) {
    return aggregatedFunds;
  }

  let fundsCount = 0;
  const accountKeys = keys(statementsByAccountByFund);
  accountKeys.forEach((accountKey) => {
    const formattedFunds = getFundsFormatted(keys(statementsByAccountByFund[accountKey]), funds, getFundName);
    const accountName = getAccountNameByKey(accounts, accountKey);
    aggregatedFunds[accountName] = formattedFunds;
    fundsCount += formattedFunds.length;
  });
  aggregatedFunds.fundsCount = fundsCount;
  return aggregatedFunds;
};

const getFundsAggregatedBySingleAccount = (funds, accounts, selectedAccount) => {
  const aggregatedFunds = { fundsCount: 0 };
  if (isEmpty(funds) || isEmpty(selectedAccount)) {
    return aggregatedFunds;
  }

  const accountName = getAccountNameByKey(accounts, selectedAccount[0].key);

  aggregatedFunds[accountName] = funds;
  aggregatedFunds.fundsCount = funds.length;
  return aggregatedFunds;
};

const getCapitalCallAggregatedByAccount = (capitalCallData) => {
  const aggregatedData = { fundsCount: 0 };
  if (isEmpty(capitalCallData)) {
    return aggregatedData;
  }

  capitalCallData.forEach((item) => {
    const investorName = item.investorName;

    if (aggregatedData[investorName]) {
      aggregatedData[investorName] = [...aggregatedData[investorName], item];
    } else {
      aggregatedData[investorName] = [item];
    }
    aggregatedData.fundsCount += 1;
  });

  return aggregatedData;
};

const getItemsAggregatedByAccount = (items, statementsByAccountByFund) => {
  const aggregatedFunds = { fundsCount: 0 };
  if (isEmpty(items) || isEmpty(statementsByAccountByFund)) {
    return aggregatedFunds;
  }

  let fundsCount = 0;
  for (const account in statementsByAccountByFund) {
    const matchingFunds = [];
    for (const fundId in statementsByAccountByFund[account]) {
      const matchingFund = items.find((fund) => fund.fund_id == fundId)
      if (matchingFund) {
        matchingFunds.push(matchingFund)
        fundsCount += 1;
      }
    }
    if (matchingFunds.length > 0) {
      aggregatedFunds[account] = matchingFunds;
    }
  }
  aggregatedFunds.fundsCount = fundsCount;
  return aggregatedFunds;
};

export {
  getFundName,
  getPafId,
  getFundId,
  getFundNameByPafId,
  getFundsFormatted,
  getFormattedCapitalCallOustandingData,
  getFundsAggregatedBySingleAccount,
  getStatementsByAccountByFundFormatted,
  getCapitalCallAggregatedByAccount,
  getItemsAggregatedByAccount,
};
