import { useEffect } from 'react';
import { useAuth } from '@icapitalnetwork/authentication';
import { getRequest } from '../services/apiRequest';

const checkHeartBeat = () =>
  getRequest({
    url: '/check_session_timeout',
  });

const clearTimeout = (interval) => {
  if (interval) {
    clearInterval(interval);
  }
};

const useHeartBeat = (icnReactBootstrap) => {
  const { useLogout } = useAuth();
  const { mutate: logout } = useLogout();

  useEffect(() => {
    let interval = null;
    if (icnReactBootstrap.user?.isSsoSessionWithLogoutStrategy) {
      interval = setInterval(() => {
        checkHeartBeat()
          .then((data) => {
            if (data.ttl <= 61) {
              clearTimeout(interval);
              logout();
            }
          })
          .catch(logout);
      }, 60000);
    }
    return () => clearTimeout(interval);
  }, [icnReactBootstrap, logout]);
};

export default useHeartBeat;
