import i18n from 'i18n';

export const ACCOUNT_NAME_SEARCH = Object.freeze({
  id: 'account_name',
  i18nPlaceholderKey: 'subscription_review:search_labels.account_name',
  i18nLabelKey: 'subscription_review:search_types.account_name',
});

export const ACCOUNT_NUMBER_SEARCH = Object.freeze({
  id: 'account_number',
  i18nPlaceholderKey: 'subscription_review:search_labels.account_number',
  i18nLabelKey: 'subscription_review:search_types.account_number',
});

export const CLIENT = Object.freeze({
  id: 'client',
  i18nPlaceholderKey: 'subscription_review:search_labels.client',
  i18nLabelKey: 'subscription_review:search_types.client',
});

export const INVESTMENT = Object.freeze({
  id: 'investment',
  i18nPlaceholderKey: 'subscription_review:search_labels.investment',
  i18nLabelKey: 'subscription_review:search_types.investment',
});

export const INVESTMENT_EXTERNAL_ID_SEARCH = Object.freeze({
  id: 'investment_external_id',
  i18nPlaceholderKey: 'subscription_review:search_labels.investment_external_id',
  i18nLabelKey: 'subscription_review:search_types.investment_external_id',
});

export const SUBSCRIPTION_REVIEW_PAGE_ENTRY = Object.freeze({
  id: 'aml_approval_document_status_dashboard',
  route: 'subscription_review',
});

export const SEARCH_TYPES = Object.freeze([
  ACCOUNT_NAME_SEARCH,
  ACCOUNT_NUMBER_SEARCH,
  CLIENT,
  INVESTMENT,
  INVESTMENT_EXTERNAL_ID_SEARCH,
]);
