import i18n from 'i18n.js';

export default {
  investmentObjective: () => i18n.t('glossary:investment_objective_one', 'Investment Objective'),
  category: () => i18n.t('glossary:category_one', 'Category'),
  strategy: () => i18n.t('glossary:strategy_one', 'Strategy'),
  investorQualification: () => i18n.t('glossary:investor_qualification_one', 'Investor Qualification'),
  investorJurisdiction: () =>
    i18n.t('investment_opportunities:components.filters.investor_jurisdiction.title', 'Investor Jurisdiction'),
  offshore: () => i18n.t('investment_opportunities:components.filters.offshore.title', 'Onshore/Offshore'),
  investorAccount: () => i18n.t('glossary:investor_account_type_one', 'Investor Account Type'),
  minimumInvestment: () => i18n.t('glossary:minimum_investment', 'Minimum Investment'),
  manager: () => i18n.t('glossary:manager_one', 'Manager'),
  distributor: () => i18n.t('glossary:distributor_one', 'Distributor'),
  liquidity: () => i18n.t('glossary:liquidity_one', 'Liquidity'),
  jurisdiction: () => i18n.t('glossary:jurisdiction_one', 'Jurisdiction'),
  currency: () => i18n.t('investment_opportunities:components.filters.currency.title', 'Currency'),
  features: () => i18n.t('investment_opportunities:components.filters.features.wlp_title', 'Features'),
  icapFeatures: () => i18n.t('investment_opportunities:components.filters.features.title', 'iCapital Features'),
  registrationType: () =>
    i18n.t('investment_opportunities:components.filters.registration_type.title', 'Registration Type'),
  productStructure: () =>
    i18n.t('investment_opportunities:components.filters.product_structure.title', 'Product Structure'),
  clientProgram: () => i18n.t('investment_opportunities:components.filters.citi_program', 'Citi Program'),
};
