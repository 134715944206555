import {
  FLEXIBLE_FEED_DASHBOARD_GET_FUND_NAV_PRICES,
} from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_GET_FUND_NAV_PRICES:
      return updateState(state, action.payload, (data) => ({
        fundNavPriceNames: data,
      }));
    default:
      return state;
  }
};
