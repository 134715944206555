import update from 'immutability-helper';
import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  PCAPS_SET_RESULTS,
  PREVIEW_SET_RESULTS,
  PREVIEW_TOTALS_SET_RESULTS,
  PREVIEW_UPDATE_RESULT,
  TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT,
  TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT,
  SET_DOC_CENTER_SYNC_TO_FALSE,
} from 'actions/actionsConstants';

const pcapListSelector = state => state.pcap_list.periods;
export const getQuartersCount = state => state.pcap_list.total_periods;

export const bulkExportList = createSelector(
  pcapListSelector,
  items => (
    items.map(item => ({
      ...item,
      year: new Date(item.end_date).getFullYear(),
    }))
  )
);

export const initialState = {
  pcap_list: {
    periods: [],
    total_periods: 0,
    document_center_sync: true,
  },
  preview_list: {
    end_date: '',
    investments: [],
    status: '',
    published_date: '',
    total: 0,
    columns_visibility: {
      dividend_reinvestment_non_zero: false,
      redemptions_non_zero: false,
      show_reallocation_of_carried_interest_column: false,
      transfer_in_out_recallable_distributions_non_zero: false,
      transfers_contribute_non_zero: false,
      transfers_noncallable_distribute_non_zero: false,
    },
  },
  total_commitments: 0,
  total_docs_published: 0,
  sum_total_commitments: 0,
  total_redemptions: 0,
  total_distribution: 0,
  total_capital_called: 0,
  total_recallable_commitment: 0,
  total_remaining_commitment: 0,
  document_center_sync: false,
  qtd: {
    beginning_pcap: 0,
    capital_called_during_reporting_period: 0,
    recallable_capital_during_reporting_period: 0,
    underlying_fund_net_income_type_1: 0,
    underlying_fund_net_income_type_2: 0,
    underlying_fund_realized_gains_loss: 0,
    underlying_fund_unrealized_gain_loss: 0,
    reallocation_of_carried_interest: 0,
    transfers_during_reporting_period: 0,
    ending_pcap: 0,
    non_recallable_distributions_during_reporting_period: 0,
    transfers_noncallable_distribute_reporting_period: 0,
    transfers_recallable_distribute_reporting_period: 0,
    redemptions_abs_during_reporting_period: 0,
    capital_called_during_reporting_period_investments: 0,
    transfers_contribute_reporting_period: 0,
    capital_called_expenses_during_reporting_period: 0,
    dividend_reinvestment_reporting_period: 0,
    total_paid_in_capital_during_reporting_period: 0,
  },
  ytd: {
    beginning_pcap: 0,
    capital_called_during_reporting_period: 0,
    recallable_capital_during_reporting_period: 0,
    underlying_fund_net_income_type_1: 0,
    underlying_fund_net_income_type_2: 0,
    underlying_fund_realized_gains_loss: 0,
    underlying_fund_unrealized_gain_loss: 0,
    reallocation_of_carried_interest: 0,
    transfers_during_reporting_period: 0,
    ending_pcap: 0,
    non_recallable_distributions_during_reporting_period: 0,
    transfers_noncallable_distribute_reporting_period: 0,
    transfers_recallable_distribute_reporting_period: 0,
    redemptions_abs_during_reporting_period: 0,
    capital_called_during_reporting_period_investments: 0,
    transfers_contribute_reporting_period: 0,
    capital_called_expenses_during_reporting_period: 0,
    dividend_reinvestment_reporting_period: 0,
    total_paid_in_capital_during_reporting_period: 0,
  },
  itd: {
    beginning_pcap: 0,
    capital_called_during_reporting_period: 0,
    recallable_capital_during_reporting_period: 0,
    underlying_fund_net_income_type_1: 0,
    underlying_fund_net_income_type_2: 0,
    underlying_fund_realized_gains_loss: 0,
    underlying_fund_unrealized_gain_loss: 0,
    reallocation_of_carried_interest: 0,
    transfers_during_reporting_period: 0,
    ending_pcap: 0,
    non_recallable_distributions_during_reporting_period: 0,
    transfers_noncallable_distribute_reporting_period: 0,
    transfers_recallable_distribute_reporting_period: 0,
    redemptions_abs_during_reporting_period: 0,
    capital_called_during_reporting_period_investments: 0,
    transfers_contribute_reporting_period: 0,
    capital_called_expenses_during_reporting_period: 0,
    dividend_reinvestment_reporting_period: 0,
    total_paid_in_capital_during_reporting_period: 0,
  },
};

export default function pcapReducer(state = initialState, action) {
  switch (action.type) {
    case PCAPS_SET_RESULTS:
      return update(state, {
        pcap_list: { $set: action.payload },
      });
    case PREVIEW_SET_RESULTS:
      return update(state, {
        preview_list: { $set: action.payload },
      });
      case PREVIEW_TOTALS_SET_RESULTS:
        return update(state, {
          total_commitments: { $set: action.payload.total_commitments },
          total_docs_published: { $set: action.payload.total_docs_published },
          total_redemptions: { $set: action.payload.total_redemptions },
          total_distribution: { $set: action.payload.total_distribution },
          qtd: { $set: action.payload.qtd },
          ytd: { $set: action.payload.ytd },
          itd: { $set: action.payload.itd },
          total_capital_called: { $set: action.payload.total_capital_called },
          total_recallable_commitment: { $set: action.payload.total_recallable_commitment },
          total_remaining_commitment: { $set: action.payload.total_remaining_commitment },
          sum_total_commitments: { $set: action.payload.sum_total_commitments },
          document_center_sync: { $set: action.payload.document_center_sync },
        });
    case PREVIEW_UPDATE_RESULT: {
      const itemIndex = _.findIndex(state.preview_list.investments,
        item => item.investment_id === action.investment_id);
      return update(state, {
        preview_list: list => update(list, {
          investments: item => update(item, {
            [itemIndex]: idx => update(idx, {
              $merge: action.payload,
            }),
          }),
        }),
      });
    }
    case TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT:
      return update(state, {
        total_docs_published: {
          $apply: () => state.total_docs_published + 1,
        },
      });
    case TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT:
      return update(state, {
        total_docs_published: {
          $apply: () => state.total_docs_published - 1,
        },
      });
    case SET_DOC_CENTER_SYNC_TO_FALSE:
      return update(state, {
        document_center_sync: {
          $apply: () => false,
        },
      });
    default:
      return state;
  }
}
