/* eslint-disable max-statements */
import i18n from 'i18n';
import enUS from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import pt from 'date-fns/locale/pt';
import zhCN from 'date-fns/locale/zh-CN';
import isValid from 'date-fns/isValid';
import dateFnsFormat from 'date-fns/format';
const DATE_REG = /^\d{4}-\d{2}-\d{2}$/;
const DATE_TIME_REG = /T/;
const locales = { en: enUS, de, es, fr, it, pt, zh: zhCN };
const DATE_PATTERNS = {
    en: {
        long: 'dd-MMM-yyyy',
        short: 'dd-MMM-yy',
    },
};
const DATE_TIME_PATTERNS = {
    en: {
        long: 'dd-MMM-yyyy hh:mm a',
        short: 'dd-MMM-yy hh:mm a',
    },
};
function castAsDate(dateStringOrObject) {
    if (dateStringOrObject instanceof Date)
        return dateStringOrObject;
    if (DATE_TIME_REG.test(dateStringOrObject)) {
        if (DATE_REG.test(dateStringOrObject.split('T')[0])) {
            const joinedDate = `${dateStringOrObject.split('T')[0]}T${dateStringOrObject.split('T')[1]}`;
            return new Date(joinedDate.substring(0, joinedDate.indexOf('.')));
        }
    }
    if (DATE_REG.test(dateStringOrObject))
        return new Date(dateStringOrObject.replace(/-/g, '/'));
    return new Date(dateStringOrObject);
}
export function formatLocale(dateStringOrObject, format = 'date', length = 'long') {
    var _a, _b, _c, _d, _e;
    if (!dateStringOrObject) {
        return '';
    }
    const language = i18n.language;
    const formatStr = format === 'date'
        ? ((_b = (_a = DATE_PATTERNS[language]) === null || _a === void 0 ? void 0 : _a[length]) !== null && _b !== void 0 ? _b : 'PP')
        : ((_d = (_c = DATE_TIME_PATTERNS[language]) === null || _c === void 0 ? void 0 : _c[length]) !== null && _d !== void 0 ? _d : 'PPpp');
    const locale = (_e = locales[language]) !== null && _e !== void 0 ? _e : enUS;
    const date = castAsDate(dateStringOrObject);
    if (!isValid(date))
        return dateStringOrObject.toString();
    return dateFnsFormat(date, formatStr, { locale });
}
