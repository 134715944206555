import _ from 'lodash';
import {
  getIntegrationPartnerBaseTemplates,
  createIntegrationPartnerTemplate,
  getIntegrationPartnerBaseTemplate,
  getIntegrationPartnerTemplates,
  getIntegrationPartnerTemplate,
  updateIntegrationPartnerTemplate,
  deleteIntegrationPartnerTemplate,
  executeIntegrationPartnerTemplate,
  executeDownloadIntegrationPartnerTemplate,
} from 'services/flexible_feeds_dashboard/templates';
import { getIntegrationPartnerTemplateTags } from 'services/flexible_feeds_dashboard/template_versions';
import {
  cancelIntegrationPartnerTemplateJob,
  getOutputFileS3UrlFromIntegrationPartnerTemplateJob,
} from 'services/flexible_feeds_dashboard/jobs';
import { getIntegrationPartners } from 'services/integration_partners';
import { getPrivateAccessFundsNameList } from 'services/funds/private_access_funds';
import { getProviders } from 'services/providers/providers';
import { fetchWlpList } from 'services/white_label_partner/whiteLabelPartnerList';
import { getGeneralLedgerAccounts } from 'services/integration_partners/general_ledger_accounts/index';
import { getFundNavPrices } from 'services/integration_partners/fund_nav_prices/index';
import {
  fetchFirmsAutocomplete,
  fetchIntegrationPartnerDashboardSearch,
} from 'services/firms/firms';
import {
  beginApiRequest,
  endApiRequest,
} from '../spinner_actions/spinnerActions';
import { actionFactory } from '../../services/actions';

import {
  FLEXIBLE_FEED_DASHBOARD_SET_SNACKBAR,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_SNACKBAR,
  FLEXIBLE_FEED_DASHBOARD_SET_BREADCRUMBS,
  FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATES,
  FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATE,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_BASE_TEMPLATE,
  FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES,
  FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_TEMPLATE,
  FLEXIBLE_FEED_DASHBOARD_SET_INTEGRATION_PARTNERS,
  FLEXIBLE_FEED_DASHBOARD_SET_PRIVATE_ACCESS_FUNDS,
  FLEXIBLE_FEED_DASHBOARD_SET_WHITE_LABEL_PARTNERS,
  FLEXIBLE_FEED_DASHBOARD_GET_GENERAL_LEDGER_ACCOUNTS,
  FLEXIBLE_FEED_DASHBOARD_GET_FUND_NAV_PRICES,
  FLEXIBLE_FEED_DASHBOARD_SET_FIRMS,
  FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_PAGINATION,
  FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTERS,
  FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_DOWNLOADING,
  FLEXIBLE_FEED_DASHBOARD_GET_PROVIDERS,
  FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE_TAGS,
} from '../actionsConstants';

const flexibleFeedDashboardSetSnackbar = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_SNACKBAR);
const flexibleFeedDashboardClearSnackbar = actionFactory(FLEXIBLE_FEED_DASHBOARD_CLEAR_SNACKBAR);
const flexibleFeedDashboardSetBreadcrumbs = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_BREADCRUMBS);
const flexibleFeedDashboardBaseTemplates = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATES);
const flexibleFeedDashboardBaseTemplate = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATE);
const flexibleFeedDashboardClearBaseTemplate = actionFactory(FLEXIBLE_FEED_DASHBOARD_CLEAR_BASE_TEMPLATE);
const flexibleFeedDashboardTemplates = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES);
const flexibleFeedDashboardTemplatesPagination = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_PAGINATION);
const flexibleFeedDashboardTemplate = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE);
const flexibleFeedDashboardClearTemplate = actionFactory(FLEXIBLE_FEED_DASHBOARD_CLEAR_TEMPLATE);
const flexibleFeedDashboardIntegrationPartners = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_INTEGRATION_PARTNERS);
const flexibleFeedDashboardPrivateAccessFunds = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_PRIVATE_ACCESS_FUNDS);
const flexibleFeedDashboardWhiteLabelPartners = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_WHITE_LABEL_PARTNERS);
const flexibleFeedDashboardGeneralLedgerAccounts = actionFactory(FLEXIBLE_FEED_DASHBOARD_GET_GENERAL_LEDGER_ACCOUNTS);
const flexibleFeedDashboardLoadFundNavPrices = actionFactory(FLEXIBLE_FEED_DASHBOARD_GET_FUND_NAV_PRICES);
const flexibleFeedDashboardFirms = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_FIRMS);
const flexibleFeedDashboardSetFilterOptions = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS);
const flexibleFeedDashboardClearFilters = actionFactory(FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTERS);
const flexibleFeedDashboardDownloadingTemplates = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_DOWNLOADING);
const flexibleFeedDashboardGetProviders = actionFactory(FLEXIBLE_FEED_DASHBOARD_GET_PROVIDERS);
const flexibleFeedDashboardSetTemplateTags = actionFactory(FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE_TAGS);

function flexibleFeedDashboardSetSnackbarData({ message, severity }) {
  return (dispatch) => dispatch(flexibleFeedDashboardSetSnackbar({ message, severity }));
}

function flexibleFeedDashboardClearSnackbarData() {
  return (dispatch) => dispatch(flexibleFeedDashboardClearSnackbar());
}

function flexibleFeedDashboardSetBreadcrumbsData(steps) {
  return (dispatch) => dispatch(flexibleFeedDashboardSetBreadcrumbs(steps));
}

function flexibleFeedDashboardLoadBaseTemplates() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartnerBaseTemplates().then((response) => {
      dispatch(
        flexibleFeedDashboardBaseTemplates(response.base_templates)
      );
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardLoadBaseTemplate(baseTemplateName) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartnerBaseTemplate(baseTemplateName).then((response) => {
      dispatch(flexibleFeedDashboardBaseTemplate(response));
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardClearSelectedBaseTemplate() {
  return (dispatch) => {
    return dispatch(flexibleFeedDashboardClearBaseTemplate());
  };
}

function flexibleFeedDashboardSetTemplatesPagination(params) {
  return (dispatch, getState) => {
    const state = getState();
    const updatedState = { ...state.flexibleFeedDashboard.templates_pagination, ...params };
    return dispatch(flexibleFeedDashboardTemplatesPagination(updatedState));
  };
}

function flexibleFeedDashboardLoadTemplates(filterOptions = {}) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const state = getState();
    const flexibleFeedDashboard = state.flexibleFeedDashboard;
    const updatedMeta = { ...flexibleFeedDashboard?.templates_pagination };

    const selectedFilters = [];
    for (const [key, value] of Object.entries(filterOptions)) {
      if (Array.isArray(value)) {
        value.forEach((v) => selectedFilters.push({ filter_type: key, typed_filter_id: v }));
      } else {
        selectedFilters.push({ filter_type: key, typed_filter_id: value });
      }
    }
    updatedMeta.filter_options = selectedFilters;

    return getIntegrationPartnerTemplates(updatedMeta).then((response) => {
      dispatch(flexibleFeedDashboardTemplates(response.integration_partner_templates));
      dispatch(flexibleFeedDashboardTemplatesPagination(_.omit(response.meta)));
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardFetchTemplateTags() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartnerTemplateTags()
      .then((tags) => {
        dispatch(flexibleFeedDashboardSetTemplateTags(tags));
      })
      .finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardLoadTemplate(templateId) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartnerTemplate(templateId).then((response) => {
      dispatch(flexibleFeedDashboardTemplate(response));
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardCopyTemplate(templateId) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartnerTemplate(templateId).then((response) => {
      dispatch(flexibleFeedDashboardTemplate( { ...response, id: _, name: `${response.name} - copy` }));
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardClearSelectedTemplate() {
  return (dispatch) => dispatch(flexibleFeedDashboardClearTemplate());
}

function flexibleFeedDashboardLoadIntegrationPartners() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getIntegrationPartners().then((response) => {
      dispatch(
        flexibleFeedDashboardIntegrationPartners(
          _.map(response.integration_partners, (item) => ({
            text: `ID ${item.id} - ${item.name}`,
            value: item.id
          }))
        )
      );
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardLoadPrivateAccessFunds() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getPrivateAccessFundsNameList().then((response) => {
      const items = _.sortBy(_.map(response, (item) => {
        const paf = item.private_access_fund;
        return {
          text: `ID ${paf.id} - ${paf.name}`,
          value: paf.id,
        };
      }), ['text']);
      dispatch(flexibleFeedDashboardPrivateAccessFunds(items));
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardLoadProviders() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getProviders().then((response) => {
      const items = _.sortBy(_.map(response, (item) => ({
        text: item.name,
        value: item.id,
      })), ['text']);
      dispatch(flexibleFeedDashboardGetProviders(items));
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardLoadWhiteLabelPartners() {
  return fetchWlpList({
    onSuccess: (response) => {
      const flagship = { text: 'ID 0 - Flagship Site', value: 0 };
      const list = _.map(response, (item) => (
        {
          text: `ID ${item.white_label_partner.id} - ${item.white_label_partner.name}`,
          value: item.white_label_partner.id,
        }
      ));
      return flexibleFeedDashboardWhiteLabelPartners([flagship, ..._.sortBy(list, ['text'])]);
    },
  });
}

function flexibleFeedDashboardLoadGeneralLedgerAccountIds() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getGeneralLedgerAccounts().then((response) => {
      const items = _.sortBy(_.map(response, (item) => ({
        text: item.general_ledger_account_id,
        value: item.general_ledger_account_id,
      })), ['text']);
      dispatch(flexibleFeedDashboardGeneralLedgerAccounts(items));
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardLoadFundNavPriceNames() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getFundNavPrices().then((response) => {
      const items = _.sortBy(_.map(response, (item) => ({
        text: item.fund_name,
        value: item.fund_name,
      })), ['text']);
      dispatch(flexibleFeedDashboardLoadFundNavPrices(items));
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardLoadFirms(existingFirmIds = [], searchValue = '') {
  return (dispatch) => {
    dispatch(beginApiRequest());
    const items = [];
    if (existingFirmIds.length > 0) {
      return fetchIntegrationPartnerDashboardSearch(existingFirmIds).then((response) => {
        const firms = response.firms.subjects;
        items.push(...(firms.map((firm) => ({
          text: `ID ${firm.id} - ${firm.name}`,
          value: firm.id,
        }))));
      }).then(() => fetchFirmsAutocomplete(searchValue).then((response) => {
        const searchedFirms = _.sortBy(_.map(response, (item) => ({
          text: `ID ${item.id} - ${item.name}`,
          value: item.id,
        })), ['text']);
        searchedFirms.forEach((firm) => {
          if (!existingFirmIds.includes(firm.value)) items.push(firm);
        });
        dispatch(flexibleFeedDashboardFirms(items));
      })).finally(() => dispatch(endApiRequest()));
    }
    return fetchFirmsAutocomplete(searchValue).then((response) => {
      items.push(...(_.sortBy(_.map(response, (item) => ({
        text: `ID ${item.id} - ${item.name}`,
        value: item.id,
      })), ['text'])));
      dispatch(flexibleFeedDashboardFirms(items));
    }).finally(() => dispatch(endApiRequest()));
  };
}

function flexibleFeedDashboardCreateTemplate(template, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return createIntegrationPartnerTemplate(template).then(() => {
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(error.data);
    });
  };
}

function flexibleFeedDashboardUpdateTemplate(template, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return updateIntegrationPartnerTemplate(template).then(() => {
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(error.data);
    });
  };
}

function flexibleFeedDashboardDeleteTemplate(templateId) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return deleteIntegrationPartnerTemplate(templateId).then(() => {
      dispatch(flexibleFeedDashboardLoadTemplates());
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardExecuteTemplate(templateId, onSuccess, onError) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return executeIntegrationPartnerTemplate(templateId).then(() => {
      dispatch(endApiRequest());
      onSuccess();
    }).catch(() => {
      dispatch(endApiRequest());
      onError();
    });
  };
}

function flexibleFeedDashboardCancelTemplateJob(jobId, onSuccess, onError) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return cancelIntegrationPartnerTemplateJob(jobId).then((data) => {
      onSuccess(data);
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardFilterOptions(data) {
  return (dispatch) => dispatch(flexibleFeedDashboardSetFilterOptions({ ...data }));
}

function flexibleFeedDashboardClearFilterOptions() {
  return (dispatch) => dispatch(flexibleFeedDashboardClearFilters());
}

function flexibleFeedDashboardGetOutputFileS3Url(jobId, onSuccess, onError) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return getOutputFileS3UrlFromIntegrationPartnerTemplateJob(jobId).then((data) => {
      onSuccess(data);
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function flexibleFeedDashboardBeginTemplateDownload(templateId, onSuccess, onError) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    return executeDownloadIntegrationPartnerTemplate(templateId)
      .then((data) => {
        const { templatesDownloading = [] } = getState()?.flexibleFeedDashboard;
        dispatch(
          flexibleFeedDashboardDownloadingTemplates([
            ...templatesDownloading,
            templateId,
          ])
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        dispatch(endApiRequest());
      });
  };
}

function flexibleFeedDashboardGetStatus(jobId, templateId, onSuccess, onError) {
  return (dispatch, getState) => {
    const { templatesDownloading = [] } = getState()?.flexibleFeedDashboard || [];
    let newJobIds = [];
    if (templatesDownloading.includes(templateId)) {
      newJobIds = templatesDownloading.filter((ids) => ids !== templateId);
    }
    return getOutputFileS3UrlFromIntegrationPartnerTemplateJob(jobId)
      .then((data) => {
        if (data?.url) {
          onSuccess(data);
          dispatch(flexibleFeedDashboardDownloadingTemplates(newJobIds));
          dispatch(endApiRequest());
        }
      })
      .catch((error) => {
        if (['canceled', 'failed'].includes(error.data.status)) {
          dispatch(flexibleFeedDashboardDownloadingTemplates(newJobIds));
        } else {
          setTimeout(() => {
            dispatch(getJobStatus(jobId, templateId, onSuccess, onError));
          }, 2000);
        }
      })
      .finally(() => {
        dispatch(endApiRequest());
      });
  };
}

function getJobStatus(jobId, templateId, onSuccess, onError) {
  return (dispatch) => {
    dispatch(flexibleFeedDashboardGetStatus(jobId, templateId, onSuccess, onError));
  };
}

export default {
  flexibleFeedDashboardSetSnackbarData,
  flexibleFeedDashboardClearSnackbarData,
  flexibleFeedDashboardSetBreadcrumbsData,
  flexibleFeedDashboardLoadBaseTemplates,
  flexibleFeedDashboardLoadBaseTemplate,
  flexibleFeedDashboardFetchTemplateTags,
  flexibleFeedDashboardClearSelectedBaseTemplate,
  flexibleFeedDashboardSetTemplatesPagination,
  flexibleFeedDashboardLoadTemplates,
  flexibleFeedDashboardLoadTemplate,
  flexibleFeedDashboardCopyTemplate,
  flexibleFeedDashboardClearSelectedTemplate,
  flexibleFeedDashboardLoadIntegrationPartners,
  flexibleFeedDashboardLoadPrivateAccessFunds,
  flexibleFeedDashboardLoadProviders,
  flexibleFeedDashboardLoadWhiteLabelPartners,
  flexibleFeedDashboardLoadGeneralLedgerAccountIds,
  flexibleFeedDashboardLoadFundNavPriceNames,
  flexibleFeedDashboardLoadFirms,
  flexibleFeedDashboardCreateTemplate,
  flexibleFeedDashboardUpdateTemplate,
  flexibleFeedDashboardDeleteTemplate,
  flexibleFeedDashboardExecuteTemplate,
  flexibleFeedDashboardCancelTemplateJob,
  flexibleFeedDashboardGetOutputFileS3Url,
  flexibleFeedDashboardFilterOptions,
  flexibleFeedDashboardClearFilterOptions,
  flexibleFeedDashboardGetStatus,
  flexibleFeedDashboardBeginTemplateDownload,
};
