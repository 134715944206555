import axios from 'axios';
import { formatDate } from 'services/DateUtils';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import {
  DC_SET_DOCUMENT_FILTER_LIST,
  DC_SET_ADVISORY_FIRM_FILTER_LIST,
  DC_SET_FUND_FILTER_LIST,
  DC_SET_FUND_ADMIN_GROUP_FILTER_LIST,
  DC_SET_INVESTORS_FILTER_LIST,
  DC_SET_ACCOUNT_NAME_FILTER_LIST,
  DC_SET_ACCOUNT_NUMBER_FILTER_LIST,
  DC_SET_FILTERS,
  DC_SET_ADVISOR_FILTER_LIST,
  DC_CLEAR_FILTER_LISTS,
  DC_SET_AUTO_SUGGEST_FILTER_LIST,
  DC_SET_DISPLAY_NAME_FILTER_LIST,
  DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST,
  DC_SET_CLIENT_FILTER_LIST,
} from 'actions/actionsConstants';

const FORMAT = "MM/dd/yy'T'HH:mm:ss";

const filterMappedKey = {
  external_document_type: DC_SET_DOCUMENT_FILTER_LIST,
  advisory_firm: DC_SET_ADVISORY_FIRM_FILTER_LIST,
  fund: DC_SET_FUND_FILTER_LIST,
  fund_admin_group: DC_SET_FUND_ADMIN_GROUP_FILTER_LIST,
  investors: DC_SET_INVESTORS_FILTER_LIST,
  investor_profile_id: DC_SET_ACCOUNT_NAME_FILTER_LIST,
  account_number: DC_SET_ACCOUNT_NUMBER_FILTER_LIST,
  advisor: DC_SET_ADVISOR_FILTER_LIST,
  display_name: DC_SET_DISPLAY_NAME_FILTER_LIST,
  white_label_partner: DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST,
  client: DC_SET_CLIENT_FILTER_LIST,
};

function filterMap(type) {
  return filterMappedKey[type] || 'Nothing';
}

function cleanupDateFormat(elem) {
  if (elem.filter_type == 'publish_date') {
    const dateArray = elem.typed_filter_id.split(' - ');
    const startDate = formatDate(dateArray[0], FORMAT);
    const endDate = formatDate(endOfDay(new Date(dateArray[1])), FORMAT);
    elem.typed_filter_id = `${startDate} - ${endDate}`;
  } else if (elem.filter_type == 'effective_date') {
    const dateArray = elem.typed_filter_id.split(' - ');
    elem.typed_filter_id = `${formatDate(startOfDay(new Date(dateArray[0])), FORMAT)} - ${formatDate(endOfDay(new Date(dateArray[1])), FORMAT)}`;
  }

  return elem;
}

function dcSetFilterList(type, data_sets = [], selectedFilter, filterCount) {
  return (dispatch) => {
    if (selectedFilter.length !== 0 && data_sets) {
      if (data_sets.length === 0) {
        dispatch({
          type: DC_SET_FILTERS,
          key: type,
          payload: selectedFilter,
        });
      } else {
        // Weird case of advisor filter putting
        // all cases as selected Filter
        dispatch({
          type: DC_SET_FILTERS,
          key: type,
          payload: (type === 'advisor') ? selectedFilter : data_sets,
        });
      }
    }

    dispatch({
      type: filterMap(type),
      payload: data_sets,
      count: filterCount,
    });
  };
}

function dcClearFilterLists() {
  return {
    type: DC_CLEAR_FILTER_LISTS,
    payload: true,
  };
}

function dcGetSelectedFilters(state, dateCleanUp = true) {
  const documentFilters = [];
  const filters = Object.keys(state.dcFilters).filter((item) => !['sequence'].includes(item));

  for (const f of filters) {
    const current = state.dcFilters[f];
    const currentCopy = [];
    if (current.length > 0) {
      current.forEach((curFilterObject) => {
        let curFilterObjectCopy = { ...curFilterObject };
        if (Object.prototype.hasOwnProperty.call(curFilterObjectCopy, 'content')) {
          curFilterObjectCopy.content = '';
        }
        if (dateCleanUp) {
          curFilterObjectCopy = cleanupDateFormat(curFilterObjectCopy);
        }
        currentCopy.push(curFilterObjectCopy);
      });
      documentFilters.push([].concat(...currentCopy));
    }
  }
  return { filters: [].concat(...documentFilters) };
}

const CancelToken = axios.CancelToken;
let cancelInstance;

function dcSetAutoSuggestFilterList(searchInputValue, filterName, setSpinnerVisibility = () => { }) {
  if (cancelInstance !== undefined) {
    cancelInstance();
    cancelInstance = undefined;
  }
  return (dispatch, getState) => {
    if (searchInputValue.length < 3) {
      dispatch({
        type: DC_SET_AUTO_SUGGEST_FILTER_LIST,
        payload: {
          filter_options: [],
          display_prompt: false,
        },
      });
    } else {
      let filters = dcGetSelectedFilters(getState()).filters;

      let filterType = {
        investors: 'investors',
        investor_profile_id: 'investor_profile_id',
        account_number: 'account_number',
        advisory_firm: 'advisory_firm',
      }[filterName];

      filterType = filterType || filterName;

      filters = filters.filter((obj) => obj.filter_type !== filterType);
      setSpinnerVisibility(true);

      const url = 'doc_center/auto_suggest';
      return axios({
        url,
        method: 'post',
        withCredentials: true,
        data: {
          search_value: searchInputValue,
          search_filter_name: filterName,
          filters,
        },
        cancelToken: new CancelToken(((c) => {
          // An executor function receives a cancel function as a parameter
          cancelInstance = c;
        })),
      })
        .then((response) => {
          setSpinnerVisibility(false);
          dispatch({
            type: DC_SET_AUTO_SUGGEST_FILTER_LIST,
            payload: response.data,
          });
        })
        .catch((error) => {
          // Checks if it is request canceled
          if (!axios.isCancel(error)) {
            setSpinnerVisibility(false);
          }
        });
    }
  };
}

function dcCancelAutocompleteRequest() {
  return function () {
    if (cancelInstance !== undefined) {
      cancelInstance();
    }
  };
}

export default {
  dcClearFilterLists,
  dcGetSelectedFilters,
  dcSetAutoSuggestFilterList,
  dcCancelAutocompleteRequest,
  dcSetFilterList,
};
