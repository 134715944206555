import { useQuery } from '@tanstack/react-query';
import { getRequest } from 'services/apiRequest';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';

export const useQueryRelatedInvestorProfiles = (investorProfileId) =>
  useQuery({
    queryKey: ['related_investor_profiles', investorProfileId],
    queryFn: () =>
      getRequest({
        url: `client_management/external_apis/investor_profiles/${investorProfileId}/related_investor_profiles`,
        errorMessage: FALLBACK_ERROR_MESSAGE,
      }),
  });
