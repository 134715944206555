import snackbarReducer from './snackbarReducer';
import breadcrumbsReducer from './breadcrumbsReducer';
import baseTemplatesReducer from './baseTemplatesReducer';
import baseTemplateReducer from './baseTemplateReducer';
import templatesReducer from './templatesReducer';
import templatesPaginationReducer from './templatesPaginationReducer';
import templateReducer from './templateReducer';
import templateTagsReducer from './templateTagsReducer';
import integrationPartnersReducer from './integrationPartnersReducer';
import privateAccessFundsReducer from './privateAccessFundsReducer';
import providersReducer from './providersReducer';
import whiteLabelPartnersReducer from './whiteLabelPartnersReducer';
import generalLedgerAccountsReducer from './generalLedgerAccountsReducer';
import fundNavPricesReducer from './fundNavPricesReducer';
import firmsReducer from './firmsReducer';
import filterOptionsReducer from './filterOptionsReducer';

export default {
  snackbarReducer,
  breadcrumbsReducer,
  baseTemplatesReducer,
  templatesPaginationReducer,
  baseTemplateReducer,
  templatesReducer,
  templateReducer,
  templateTagsReducer,
  integrationPartnersReducer,
  privateAccessFundsReducer,
  providersReducer,
  whiteLabelPartnersReducer,
  generalLedgerAccountsReducer,
  fundNavPricesReducer,
  firmsReducer,
  filterOptionsReducer,
};
