export default {
    PENDING_SIGNATURE_STATUS: 'pending_esign',
    SIGNATURE_NEEDED_STATUS: 'signature_needed',
    REQUIRED_STATUS: 'required',
    GENERATING_AGREEMENT_STATUS: 'generating_agreement',
    ERROR: 'error',
    REQUIRED_SUB_DOC_STATUS: 'required_sub_doc',
    UPLOADED_STATUS: 'uploaded',
    APPROVED_STATUS: 'approved',
    MANUAL_SIGNED_STATUS: 'manual_signed',
    SIGNED_STATUS: 'signed',
    REVERTED_STATUS: 'reverted',
    REJECTED_STATUS: 'rejected',
    ACCEPTED_STATUS: 'accepted',
    SENT_STATUS: 'sent',
    NOT_SENT_STATUS: 'not sent',
    PENDING_COMPLETION_STATUS: 'pending_completion',
    PENDING_UPLOAD_STATUS: 'pending_upload',
    PENDING_SUBDOC_UPLOAD_STATUS: 'pending_subdoc_upload',
    PENDING_TRANSFER_SIGNATURE_STATUS: 'pending_esign',
    AUTHENTICATION_FAILED_STATUS: 'authentication_failed',
};
export var docTypes;
(function (docTypes) {
    docTypes["SubscriptionDocument"] = "SubscriptionDocument";
    docTypes["TransferDocument"] = "TransferDocument";
})(docTypes || (docTypes = {}));
;
export const REQUIRED_DOCUMENTS_PAGE_ENTRY = 'required_documents';
