/* eslint-disable max-lines */
import axios from 'axios';
import { getRequest, postRequest, deleteRequest, patchRequest } from 'actions/utils';
import { createAction } from 'redux-actions';

import {
  CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS,
  CAPITAL_EVENTS_OPEN_NOTICE_EDITOR,
  CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR,
  CAPITAL_EVENTS_UPDATE_GROUPS,
  CAPITAL_EVENTS_OPEN_CREATE_FORM,
  CAPITAL_EVENTS_CLOSE_CREATE_FORM,
  CAPITAL_EVENTS_OPEN_EDIT_FORM,
  CAPITAL_EVENTS_CLOSE_EDIT_FORM,
  CAPITAL_EVENTS_OPEN_EXPENSES_MODAL,
  CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL,
  CAPITAL_EVENTS_OPEN_REMINDER_MODAL,
  CAPITAL_EVENTS_CLOSE_REMINDER_MODAL,
  CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL,
  CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL,
  CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL,
  CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL,
  CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES,
  CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES,
  CAPITAL_EVENTS_SET_NOMINEE_NOTICES,
  CAPITAL_EVENTS_OPEN_EXECUTE_MODAL,
  CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL,
  CAPITAL_EVENTS_SET_FIRM_REPORTS,
  CAPITAL_EVENTS_OPEN_FIRM_REPORTS,
  CAPITAL_EVENTS_CLOSE_FIRM_REPORTS,
  CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL,
  CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL,
  CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS,
  CAPITAL_EVENTS_OPEN_DELETE_MODAL,
  CAPITAL_EVENTS_CLOSE_DELETE_MODAL,
  CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS,
  CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_SET_STATUS_REPORTS,
  CAPITAL_EVENTS_SET_FILTER_DATE_LIST,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE,
  CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER,
  CAPITAL_EVENTS_SET_FILTER_OPTIONS,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION,
  CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED,
  CAPITAL_EVENTS_OPEN_REJECT_MODAL,
  CAPITAL_EVENTS_CLOSE_REJECT_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_FORM,
  CAPITAL_EVENTS_CLOSE_UPLOAD_FORM,
  CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_OPEN_RESET_MODAL,
  CAPITAL_EVENTS_CLOSE_RESET_MODAL,
  CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL,
  CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS,
  CAPITAL_EVENTS_SET_UNDERLYING_FUNDS,
  CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS,
  CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL,
  CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM,
  CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM,
  CAPITAL_EVENTS_OPEN_UF_MODAL,
  CAPITAL_EVENTS_CLOSE_UF_MODAL,
  CAPITAL_EVENTS_SET_SUMMARY,
  CAPITAL_EVENTS_CLEAR_SUMMARIES,
  CAPITAL_EVENTS_OPEN_NOTES_MODAL,
  CAPITAL_EVENTS_CLOSE_NOTES_MODAL,
  CAPITAL_EVENTS_SET_NOTES,
  CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL,
  CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL,
  CAPITAL_EVENTS_SET_MANAGEMENT_FEES,
  CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS,
  CAPITAL_EVENTS_SET_MENU_OPTIONS,
  CAPITAL_EVENTS_SET_GRID_FETCHING,
  CAPITAL_EVENTS_UNSET_GRID_FETCHING,
  CAPITAL_EVENTS_SET_MODAL_FETCHING,
  CAPITAL_EVENTS_UNSET_MODAL_FETCHING,
  CAPITAL_EVENTS_SET_EXECUTE_INFO,
  CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL,
  CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL,
  CAPITAL_EVENTS_DISABLE_GRID_MENU,
  CAPITAL_EVENTS_ENABLE_GRID_MENU,
  CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING,
  CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING,
  CAPITAL_EVENTS_SET_UF_DATES,
  CAPITAL_EVENTS_SET_UF_DUE_DATE,
  CAPITAL_EVENTS_SET_UF_RECEIVED_DATE,
  CAPITAL_EVENTS_SET_SELECTED_EVENT,
  CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER,
  CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER,
  CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL,
  CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL,
  CAPITAL_EVENTS_SET_EXPENSES_CATEGORIES,
} from '../actionsConstants';

const setPafChargedFeeQuarters = createAction(CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS);
const setManagementFees = createAction(CAPITAL_EVENTS_SET_MANAGEMENT_FEES);
const setCapitalEventMenuOptions = createAction(CAPITAL_EVENTS_SET_MENU_OPTIONS);
const setFilterOptions = createAction(CAPITAL_EVENTS_SET_FILTER_OPTIONS);
const disableGridMenu = createAction(CAPITAL_EVENTS_DISABLE_GRID_MENU);
const enableGridMenu = createAction(CAPITAL_EVENTS_ENABLE_GRID_MENU);

const setCapitalEventGridIsFetching = (key) => ({
  type: CAPITAL_EVENTS_SET_GRID_FETCHING,
  payload: { key: key, },
});

const setCapitalEventGridStoppedFetching = (key) => ({
  type: CAPITAL_EVENTS_UNSET_GRID_FETCHING,
  payload: { key: key, },
});

const setCapitalEventModalIsFetching = createAction(CAPITAL_EVENTS_SET_MODAL_FETCHING);
const setCapitalEventModalStoppedFetching = createAction(CAPITAL_EVENTS_UNSET_MODAL_FETCHING);
const setUnderlyingFundsisFetching = createAction(CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING);
const setUnderlyingFundsStoppedFetching = createAction(CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING);
const setOpenUnderlyingCapEventDrawer = createAction(CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER);
const setCloseUnderlyingCapEventDrawer = createAction(CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER);
const setOpenUnderlyingCapEventDeleteModal = createAction(CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL);
const setCloseUnderlyingCapEventDeleteModal = createAction(CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL);
const setCapitalEventsPrivateAccessFunds = createAction(CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS);

function openCapitalEventNoticeEditor(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_NOTICE_EDITOR,
      payload: item,
    });
  };
}

function closeCapitalEventNoticeEditor() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR });
  };
}

function setSummary({ data }, id) {
  return {
    type: CAPITAL_EVENTS_SET_SUMMARY,
    payload: data,
    id,
  };
}

function clearSummaryState() {
  return { type: CAPITAL_EVENTS_CLEAR_SUMMARIES };
}

function executeCapitalEvent(capitalEventId, options = {}) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      url: `capital_events/${capitalEventId}/execute`,
      data: options,
      showSpinner: false,
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function lockCapitalEvent(capitalEventId) {
  return patchRequest({ url: `capital_events/${capitalEventId}/lock`, showSpinner: false });
}

function unlockCapitalEvent(capitalEventId) {
  return patchRequest({ url: `capital_events/${capitalEventId}/unlock`, showSpinner: false });
}

function deleteCapitalEvent(capitalEventId) {
  return patchRequest({ url: `capital_events/${capitalEventId}/delete`, showSpinner: false });
}

function exportOutstandingInvestments(pafId) {
  return getRequest({ url: `private_access_funds/${pafId}/capital_events/outstanding_investments`, showSpinner: false });
}

function reconcileCapitalEvent(capitalEventId, params) {
  return patchRequest({ url: `capital_events/${capitalEventId}/reconcile`, showSpinner: false, data: params });
}

function setCapitalEventLineGroups(results) {
  return {
    type: CAPITAL_EVENTS_UPDATE_GROUPS,
    payload: results,
  };
}

function setFinanceOwnerOptions(results) {
  return {
    type: CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS,
    payload: results,
  };
}

function setUnderlyingFunds(results) {
  return {
    type: CAPITAL_EVENTS_SET_UNDERLYING_FUNDS,
    payload: results,
  };
}

function setUnderlyingCapitalEvents(results) {
  return {
    type: CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS,
    payload: results,
  };
}

function setSelectedEvent(event) {
  return {
    type: CAPITAL_EVENTS_SET_SELECTED_EVENT,
    payload: event,
  };
}

function fetchPrivateAccessFunds() {
  return (dispatch) =>
    dispatch(
      getRequest({
        showSpinner: false,
        url: 'capital_events/private_access_funds',
        onSuccess: setCapitalEventsPrivateAccessFunds,
      })
    );
}

function fetchCapitalEventLineGroupsPreview(pafId, date, disableFetchingPopup = false) {
  return (dispatch) => {
    if (!disableFetchingPopup) { dispatch(setCapitalEventModalIsFetching()); }
    return axios.get(`private_access_funds/${pafId}/capital_event_line_groups/preview`, {
      params: { call_date: date },
      withCredentials: true,
    }).then((response) => {
      dispatch(setCapitalEventLineGroups(response.data));
      if (!disableFetchingPopup) { dispatch(setCapitalEventModalStoppedFetching()); }
    }).catch(() => {
      if (!disableFetchingPopup) { dispatch(setCapitalEventModalStoppedFetching()); }
    });
  };
}

function fetchCapitalEventLineGroupsEdit(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get(`capital_events/${capitalEventId}/capital_event_line_groups/preview`, {
      withCredentials: true,
    }).then((response) => {
      dispatch(setCapitalEventLineGroups(response.data));
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function fetchFinanceOwnerOptions() {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: 'users/for_finance_owner_options',
    onSuccess: setFinanceOwnerOptions,
  }));
}

function fetchUnderlyingCapitalEventAssociatedWithCapitalEvent(capitalEventId, feederFundId) {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/underlying_capital_events/uf_event_for_capital_event`,
    params: { private_access_fund_id: feederFundId },
    onSuccess: setUnderlyingCapitalEvents,
  }));
}

function fetchCapitalEventAssociatedWithUnderlyingCapitalEvent(underlyingCapitalEventId, feederFundId) {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: `underlying_capital_events/${underlyingCapitalEventId}/capital_event_for_ops_dashboard`,
    params: { private_access_fund_id: feederFundId },
  }));
}

function fetchUnderlyingCapitalEventById(underlyingCapitalEventId, feederFundId) {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: `underlying_capital_events/${underlyingCapitalEventId}`,
    params: { private_access_fund_id: feederFundId },
    onSuccess: setUnderlyingCapitalEvents,
  }));
}

function clearUnderlyingCapitalEvents() {
  return (dispatch) => dispatch(setUnderlyingCapitalEvents(undefined));
}

function fetchCapitalEventLineGroups(capitalEventId) {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/capital_event_line_groups`,
    onSuccess: setCapitalEventLineGroups,
  }));
}

function createCapitalEvent(pafId, eventsParams) {
  return postRequest({
    url: `private_access_funds/${pafId}/capital_events`,
    showSpinner: false,
    data: eventsParams,
  });
}

function createUnderlyingCapitalEvents(pafId, underlyingEventParams) {
  return (dispatch) => dispatch(postRequest({
    showSpinner: false,
    url: `private_access_funds/${pafId}/underlying_capital_events`,
    params: underlyingEventParams,
  }));
}

function deleteUnderlyingCapitalEvent(underlyingCapitalEventId, feederFundId) {
  return deleteRequest({
    showSpinner: false,
    url: `underlying_capital_events/${underlyingCapitalEventId}`,
    params: { private_access_fund_id: feederFundId },
  });
}

function updateCapitalEvent(capitalEventId, eventsParams) {
  return patchRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}`,
    data: eventsParams,
  });
}

function openCreateCapitalEventsForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_OPEN_CREATE_FORM });
  };
}

function closeCreateCapitalEventsForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_CREATE_FORM });
  };
}

function openEditCapitalEventsForm(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_EDIT_FORM,
      payload: item,
    });
  };
}

function closeEditCapitalEventsForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_EDIT_FORM });
  };
}

function openExpensesModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_OPEN_EXPENSES_MODAL });
  };
}

function closeExpensesModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL });
  };
}

function initiateCapitalEvent(capitalEventId) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/initiate`,
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function openReminderModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_REMINDER_MODAL,
      payload: item,
    });
  };
}

function closeReminderModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_REMINDER_MODAL });
  };
}

function sendCapitalEventReminder(capitalEventId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/send_reminder`,
  });
}

function openStatusReportModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL,
      payload: item,
    });
  };
}

function closeStatusReportModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL,
    });
  };
}

function sendCapitalEventStatusReport(capitalEventId, fileType) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/status_report`,
    data: { file_type: fileType },
  });
}

function sendCapitalEventForApproval(capitalEventId, reason = '') {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/out_for_approval`,
      data: { approval_reason: reason },
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function approveCapitalEvent(capitalEventId) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/approve`,
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function fetchCapitalEventsCustodianNotices(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'CustodianCapitalEventNoticesZip',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function openCustodianNoticesModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES,
      payload: event,
    });
  };
}

function closeCustodianNoticesModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES });
  };
}

function generateCapitalEventsCustodianNotices(capitalEventId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/custodian_notices`,
  });
}

function openNomineeNoticesModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES,
      payload: event,
    });
  };
}

function closeNomineeNoticesModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES });
  };
}

function generateCapitalEventsNomineeNotices(capitalEventId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/nominee_notices`,
  });
}

function fetchCapitalEventsNomineeNotices(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'NomineeCapitalEventNoticesZip',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_NOMINEE_NOTICES,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function openCapitalEventsExecuteModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_EXECUTE_MODAL,
      payload: event,
    });
  };
}

function closeCapitalEventsExecuteModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL });
  };
}

function fetchCapitalEventsFirmReports(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'FirmCapitalEventsReportXlsx',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_FIRM_REPORTS,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function openFirmReportsModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_FIRM_REPORTS,
      payload: event,
    });
  };
}

function closeFirmReportsModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_FIRM_REPORTS });
  };
}

function generateCapitalEventsFirmReports(capitalEventId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/firm_reports`,
  });
}

function openDistributionReportsModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL,
      payload: event,
    });
  };
}

function closeDistributionReportsModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL });
  };
}

function fetchDistributionReports(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: ['DistributionReportExportCsv', 'DistributionReportExportXlsx'],
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function generateDistributionReport(capitalEventId, type) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/distribution_report`,
    data: { file_type: type },
  });
}

function openCapitalEventDeleteModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_DELETE_MODAL,
      payload: item,
    });
  };
}

function closeCapitalEventDeleteModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_DELETE_MODAL });
  };
}

function fetchCapitalEventReconciliationExports(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'CapitalEventReconciliationExport',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function openCapitalEventReconciliationModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL,
      payload: item,
    });
  };
}

function closeCapitalEventReconciliationModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL });
  };
}

function generateCapitalEventReconcilationExports(capitalEventId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/reconciliation_file`,
  });
}

function fetchCapitalEventsStatusReports(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: ['CapitalEventsReportCsv', 'CapitalEventsReportXlsx'],
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_STATUS_REPORTS,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function fetchCapitalEventsCallDates(pafId) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('call_dates'));
    return axios.get(`private_access_funds/${pafId}/capital_events/call_dates`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_FILTER_DATE_LIST,
        payload: response.data?.sort().reverse(),
      });
      dispatch(setCapitalEventGridStoppedFetching('call_dates'));
    }).catch(() => {
      dispatch(setCapitalEventGridStoppedFetching('call_dates'));
    });
  };
}

function setCapitalEventsSelectedFilterDate(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE,
      payload: event,
    });
  };
}

function fetchCapitalEventsWlp(pafId) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('wlps'));
    return axios.get(`private_access_funds/${pafId}/capital_events/paf_wlps`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST,
        payload: response.data,
      });
      dispatch(setCapitalEventGridStoppedFetching('wlps'));
    }).catch(() => {
      dispatch(setCapitalEventGridStoppedFetching('wlps'));
    });
  };
}

function fetchFilterOptions(pafId) {
  return (dispatch) => dispatch(getRequest({
    url: `private_access_funds/${pafId}/capital_events/filter_options`,
    showSpinner: false,
    onSuccess: setFilterOptions,
    withCredentials: true,
  }));
}

function setCapitalEventsSelectedFilterWlp(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER,
      payload: event,
    });
  };
}

function setCapitalEventsSelectedFilterText(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT,
      payload: event,
    });
  };
}

function setCapitalEventsSelectedFilterOption(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION,
      payload: event,
    });
  };
}

function setCapitalEventsSelectedGroupByClose(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE,
      payload: event,
    });
  };
}

function setCapitalEventsSelectedFilterUnreconciled(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED,
      payload: event,
    });
  };
}

function openRejectCapitalEventModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_REJECT_MODAL,
      payload: event,
    });
  };
}

function closeRejectCapitalEventModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_REJECT_MODAL,
    });
  };
}

function rejectCapitalEvent(capitalEventId, eventsParams) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/reject`,
      data: eventsParams,
    })).finally(() => {
      dispatch(enableGridMenu(capitalEventId));
    });
  };
}

function openUploadCapitalEventForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_OPEN_UPLOAD_FORM });
  };
}

function closeUploadCapitalEventForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_UPLOAD_FORM });
  };
}

function generateBulkPreviewNoticesFiles(capitalEventId, selectedWlpId) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/bulk_notices_preview`,
    data: { wlp_id: selectedWlpId },
  });
}

function closePreviewNoticesModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES });
  };
}

function openPreviewNoticesModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES,
      payload: event,
    });
  };
}

function fetchCapitalEventsBulkPreviewNoticesFiles(capitalEventId) {
  return (dispatch) => {
    dispatch(setCapitalEventModalIsFetching());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'BulkPreviewNoticesFilesZip',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES,
        payload: response.data,
      });
      dispatch(setCapitalEventModalStoppedFetching());
    }).catch(() => {
      dispatch(setCapitalEventModalStoppedFetching());
    });
  };
}

function resetCapitalEvent(capitalEventId) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(patchRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/reset`,
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function openResetCapitalEventModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_RESET_MODAL,
      payload: event,
    });
  };
}

function closeResetCapitalEventModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_RESET_MODAL,
    });
  };
}

function resendCapitalEventFailedNotices(capitalEventId) {
  return (dispatch) => {
    dispatch(disableGridMenu(capitalEventId));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/resend_notices`,
    })).finally(() => dispatch(enableGridMenu(capitalEventId)));
  };
}

function openResendFailedNoticesModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL,
      payload: event,
    });
  };
}

function closeResendFailedNoticesModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL,
    });
  };
}

function getSummary(id) {
  return (dispatch) => axios.get(
    `capital_event_line_items/${id}/summary`,
    { withCredentials: true }
  ).then((response) => {
    dispatch(setSummary(response, id));
  });
}

function resendEventNotice(eventId, wlpIds, sendEmail) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${eventId}/send_updated_notices`,
      data: { wlp_ids: wlpIds, send_email: sendEmail },
      onSuccess: () => setCapitalEventGridStoppedFetching('generic'),
      onFailure: () => setCapitalEventGridStoppedFetching('generic'),
    }));
  };
}

function closeResendEventNoticeModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL });
  };
}

function openResendEventNoticeModal(id) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL,
      payload: id,
    });
  };
}

function openPastDueNoticeModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL,
      payload: item,
    });
  };
}

function closePastDueNoticeModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL });
  };
}

function sendPastDueNotice(capitalEventId, itemIds) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${capitalEventId}/send_past_due_reminder`,
      data: { item_ids: itemIds },
      onSuccess: () => setCapitalEventGridStoppedFetching('generic'),
      onFailure: () => setCapitalEventGridStoppedFetching('generic'),
    }));
  };
}

function openUploadUpdateCapitalEventForm(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL,
      payload: item,
    });
  };
}

function closeUploadUpdateCapitalEventForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL });
  };
}

function openUploadReconciliationModal(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL,
      payload: item,
    });
  };
}

function closeUploadReconciliationModal() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL });
  };
}

function uploadUpdateCapitalEvent(capitalEventId, params) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/update_executed_event`,
    data: params,
  });
}

function openEditCapitalEventsFromFileForm(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM,
      payload: item,
    });
  };
}

function closeEditCapitalEventsFromFileForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM });
  };
}

function editCapitalEventsFromFileForm(capitalEventId, params) {
  return patchRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}`,
    data: params,
  });
}

function openUFCapitalEventForm(item) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_UF_MODAL,
      payload: item,
    });
  };
}

function closeUFCapitalEventForm() {
  return (dispatch) => {
    dispatch({ type: CAPITAL_EVENTS_CLOSE_UF_MODAL });
  };
}

function openInitialDefaultModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL,
      payload: event,
    });
  };
}

function closeInitialDefaultModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL,
    });
  };
}

function openFinalDefaultModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL,
      payload: event,
    });
  };
}

function closeFinalDefaultModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL,
    });
  };
}

function fetchFmUnderlyingFunds(pafId) {
  return (dispatch) => {
    dispatch(setUnderlyingFundsisFetching());
    return axios.get(`private_access_funds/${pafId}/underlying_fm_funds`, {
      params: { fm_fund_params: ['id', 'name'] },
      withCredentials: true,
    }).then((response) => {
      dispatch(setUnderlyingFunds(response.data));
    }).catch((error) => {
      dispatch(setUnderlyingFunds([]));
      if (error.status === 424) {
        throw error;
      }
    }).finally(() => {
      dispatch(setUnderlyingFundsStoppedFetching());
    });
  };
}

function openNotesCapitalEventModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_NOTES_MODAL,
      payload: event,
    });
  };
}

function closeNotesCapitalEventModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_NOTES_MODAL,
    });
  };
}

function setCapitalEventsNotes(results) {
  return {
    type: CAPITAL_EVENTS_SET_NOTES,
    payload: results,
  };
}

function fetchCapitalEventNotes(capitalEventId) {
  return (dispatch) => dispatch(getRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/notes`,
    onSuccess: setCapitalEventsNotes,
  }));
}

function createCapitalEventNote(capitalEventId, noteText) {
  return postRequest({
    showSpinner: false,
    url: `capital_events/${capitalEventId}/notes/`,
    data: { reason: noteText },
    withCredentials: true,
  });
}

function openSubmitForApprovalModal(event) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL,
      payload: event,
    });
  };
}

function closeSubmitForApprovalModal() {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL,
    });
  };
}

const fetchPafChargedFeeQuarters = (pafId) => (dispatch) => (
  dispatch(getRequest({
    url: `private_access_funds/${pafId}/paf_charged_fees`,
    showSpinner: false,
    requestParams: { key: 'fee_quarters' },
    onSuccess: setPafChargedFeeQuarters,
  }))
);

const fetchManagementFees = (pafId, capId) => (dispatch) => (
  dispatch(getRequest({
    url: `private_access_funds/${pafId}/capital_event_line_fees`,
    showSpinner: false,
    requestParams: { key: 'management_fees' },
    params: { capitalEventId: capId },
    onSuccess: setManagementFees,
  }))
);

function fetchCapitalEventMenuOptions(pafId) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('menu_options'));
    return axios.get(`private_access_funds/${pafId}/capital_event_menu_options`, {
      withCredentials: true,
    }).then((response) => {
      dispatch(setCapitalEventMenuOptions(response.data));
      dispatch(setCapitalEventGridStoppedFetching('menu_options'));
    }).catch(() => {
      dispatch(setCapitalEventGridStoppedFetching('menu_options'));
    });
  };
}

function setCapitalEventExecuteInfo(results) {
  return {
    type: CAPITAL_EVENTS_SET_EXECUTE_INFO,
    payload: results,
  };
}

function loadCapitalEventExecuteInfo(capitalEventId) {
  return (dispatch) => dispatch(getRequest({
    url: `capital_events/${capitalEventId}/capital_event_execute_info`,
    onSuccess: setCapitalEventExecuteInfo,
  }));
}

function openUnpublishNoticeModal(id) {
  return {
    type: CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL,
    payload: id,
  };
}

function openResendItemNoticeModal(id) {
  return {
    type: CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL,
    payload: id,
  };
}

function closeUnpublishNoticeModal() {
  return {
    type: CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL,
  };
}

function closeResendItemNoticeModal() {
  return {
    type: CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL,
  };
}

const openUnpublishEventNoticesModal = createAction(CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL);

const closeUnpublishEventNoticesModal = createAction(CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL);

function unpublishNotice(itemId) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return axios.post(`capital_event_line_items/${itemId}/unpublish_notice`, {
      withCredentials: true,
    }).then(() => {
      dispatch(setCapitalEventGridStoppedFetching('generic'));
    }).catch((e) => {
      dispatch(setCapitalEventGridStoppedFetching('generic'));
      throw e;
    });
  };
}

function resendItemNotice(itemId, sendEmail) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return axios.post(`capital_event_line_items/${itemId}/send_updated_notice`, {
      withCredentials: true,
      send_email: sendEmail,
    }).then(() => {
      dispatch(setCapitalEventGridStoppedFetching('generic'));
    }).catch((e) => {
      dispatch(setCapitalEventGridStoppedFetching('generic'));
      throw e;
    });
  };
}

function sendInitialDefaultNotice(eventId, itemIds) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${eventId}/send_initial_default_positions`,
      data: { item_ids: itemIds },
      onSuccess: () => setCapitalEventGridStoppedFetching('generic'),
      onFailure: () => setCapitalEventGridStoppedFetching('generic'),
    }));
  };
}

function sendFinalDefaultNotice(eventId, itemIds) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${eventId}/send_final_default_positions`,
      data: { item_ids: itemIds },
      onSuccess: () => setCapitalEventGridStoppedFetching('generic'),
      onFailure: () => setCapitalEventGridStoppedFetching('generic'),
    }));
  };
}

function unpublishEventNotices(eventId, wlpIds) {
  return (dispatch) => {
    dispatch(setCapitalEventGridIsFetching('generic'));
    return dispatch(postRequest({
      showSpinner: false,
      url: `capital_events/${eventId}/unpublish_event_notices`,
      data: { wlp_ids: wlpIds },
      onSuccess: () => setCapitalEventGridStoppedFetching('generic'),
      onFailure: () => setCapitalEventGridStoppedFetching('generic'),
    }));
  };
}

const setUfDates = createAction(CAPITAL_EVENTS_SET_UF_DATES);

function fetchUfDates(pafId) {
  return (dispatch) => dispatch(getRequest({
    url: 'underlying_capital_events/uf_event_dates',
    params: { private_access_fund_id: pafId },
    showSpinner: false,
    onSuccess: setUfDates,
  }));
}

function setUfEventsSelectedDueDate(date) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_UF_DUE_DATE,
      payload: date,
    });
  };
}

function setUfEventsSelectedReceivedDate(date) {
  return (dispatch) => {
    dispatch({
      type: CAPITAL_EVENTS_SET_UF_RECEIVED_DATE,
      payload: date,
    });
  };
}

function checkIfCapitalEventIsLockable(underlyingEventId, pafId, withLoading = true) {
  return (dispatch) => {
    if (withLoading) dispatch(setCapitalEventModalIsFetching());
    return dispatch(getRequest({
      showSpinner: false,
      url: `underlying_capital_events/${underlyingEventId}/can_lock_capital_event`,
      params: { private_access_fund_id: pafId },
    })).finally(() => withLoading && dispatch(setCapitalEventModalStoppedFetching()));
  };
}

function markUnderlyingEventAsPaid(underlyingEventId, params) {
  return patchRequest({
    showSpinner: false,
    url: `underlying_capital_events/${underlyingEventId}/mark_as_paid`,
    data: params,
  });
}

function setCapitalEventsExpensesCategories(data) {
  return {
    type: CAPITAL_EVENTS_SET_EXPENSES_CATEGORIES,
    payload: data,
  };
}

function fetchCapitalEventExpensesCategories(pafId) {
  return (dispatch) =>
    dispatch(
      getRequest({
        showSpinner: false,
        url: `private_access_funds/${pafId}/capital_events/standard_expense_categories`,
        onSuccess: setCapitalEventsExpensesCategories,
      })
    );
}

export default {
  fetchPrivateAccessFunds,
  openCapitalEventNoticeEditor,
  closeCapitalEventNoticeEditor,
  executeCapitalEvent,
  lockCapitalEvent,
  unlockCapitalEvent,
  deleteCapitalEvent,
  reconcileCapitalEvent,
  setCapitalEventLineGroups,
  fetchCapitalEventLineGroupsPreview,
  fetchCapitalEventLineGroupsEdit,
  fetchCapitalEventLineGroups,
  createCapitalEvent,
  openCreateCapitalEventsForm,
  closeCreateCapitalEventsForm,
  openEditCapitalEventsForm,
  closeEditCapitalEventsForm,
  openExpensesModal,
  closeExpensesModal,
  openReminderModal,
  closeReminderModal,
  sendCapitalEventReminder,
  openStatusReportModal,
  closeStatusReportModal,
  sendCapitalEventStatusReport,
  sendCapitalEventForApproval,
  approveCapitalEvent,
  fetchCapitalEventsCustodianNotices,
  openCustodianNoticesModal,
  closeCustodianNoticesModal,
  generateCapitalEventsCustodianNotices,
  openNomineeNoticesModal,
  closeNomineeNoticesModal,
  generateCapitalEventsNomineeNotices,
  fetchCapitalEventsNomineeNotices,
  openCapitalEventsExecuteModal,
  closeCapitalEventsExecuteModal,
  fetchCapitalEventsFirmReports,
  openFirmReportsModal,
  closeFirmReportsModal,
  generateCapitalEventsFirmReports,
  openDistributionReportsModal,
  closeDistributionReportsModal,
  fetchDistributionReports,
  openCapitalEventDeleteModal,
  closeCapitalEventDeleteModal,
  fetchCapitalEventReconciliationExports,
  openCapitalEventReconciliationModal,
  closeCapitalEventReconciliationModal,
  generateCapitalEventReconcilationExports,
  fetchCapitalEventsStatusReports,
  fetchCapitalEventsCallDates,
  setCapitalEventsSelectedFilterDate,
  fetchCapitalEventsWlp,
  fetchFilterOptions,
  setCapitalEventsSelectedFilterWlp,
  setCapitalEventsSelectedFilterText,
  setCapitalEventsSelectedFilterOption,
  setCapitalEventsSelectedGroupByClose,
  setCapitalEventsSelectedFilterUnreconciled,
  updateCapitalEvent,
  initiateCapitalEvent,
  generateDistributionReport,
  openRejectCapitalEventModal,
  closeRejectCapitalEventModal,
  rejectCapitalEvent,
  openUploadCapitalEventForm,
  closeUploadCapitalEventForm,
  openPreviewNoticesModal,
  closePreviewNoticesModal,
  generateBulkPreviewNoticesFiles,
  fetchCapitalEventsBulkPreviewNoticesFiles,
  resetCapitalEvent,
  exportOutstandingInvestments,
  openResetCapitalEventModal,
  closeResetCapitalEventModal,
  resendCapitalEventFailedNotices,
  openResendFailedNoticesModal,
  closeResendFailedNoticesModal,
  fetchFinanceOwnerOptions,
  setFinanceOwnerOptions,
  fetchFmUnderlyingFunds,
  fetchUnderlyingCapitalEventAssociatedWithCapitalEvent,
  fetchCapitalEventAssociatedWithUnderlyingCapitalEvent,
  fetchUnderlyingCapitalEventById,
  clearUnderlyingCapitalEvents,
  resendEventNotice,
  openResendEventNoticeModal,
  closeResendEventNoticeModal,
  openPastDueNoticeModal,
  closePastDueNoticeModal,
  sendPastDueNotice,
  openUploadUpdateCapitalEventForm,
  closeUploadUpdateCapitalEventForm,
  openUploadReconciliationModal,
  closeUploadReconciliationModal,
  uploadUpdateCapitalEvent,
  openEditCapitalEventsFromFileForm,
  closeEditCapitalEventsFromFileForm,
  editCapitalEventsFromFileForm,
  openUFCapitalEventForm,
  closeUFCapitalEventForm,
  createUnderlyingCapitalEvents,
  deleteUnderlyingCapitalEvent,
  getSummary,
  clearSummaryState,
  openNotesCapitalEventModal,
  closeNotesCapitalEventModal,
  fetchCapitalEventNotes,
  createCapitalEventNote,
  openSubmitForApprovalModal,
  closeSubmitForApprovalModal,
  fetchManagementFees,
  fetchPafChargedFeeQuarters,
  fetchCapitalEventMenuOptions,
  setCapitalEventGridStoppedFetching,
  loadCapitalEventExecuteInfo,
  openUnpublishNoticeModal,
  openResendItemNoticeModal,
  closeUnpublishNoticeModal,
  closeResendItemNoticeModal,
  unpublishNotice,
  resendItemNotice,
  openInitialDefaultModal,
  closeInitialDefaultModal,
  openFinalDefaultModal,
  closeFinalDefaultModal,
  sendInitialDefaultNotice,
  sendFinalDefaultNotice,
  openUnpublishEventNoticesModal,
  closeUnpublishEventNoticesModal,
  unpublishEventNotices,
  fetchUfDates,
  setUfEventsSelectedDueDate,
  setUfEventsSelectedReceivedDate,
  checkIfCapitalEventIsLockable,
  markUnderlyingEventAsPaid,
  setUnderlyingCapitalEvents,
  setSelectedEvent,
  setOpenUnderlyingCapEventDrawer,
  setCloseUnderlyingCapEventDrawer,
  setOpenUnderlyingCapEventDeleteModal,
  setCloseUnderlyingCapEventDeleteModal,
  fetchCapitalEventExpensesCategories,
};
