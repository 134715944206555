/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// importing this as "constants" causes a conflict with node constants
import { PUSH_EVENTS } from 'constants/index';
import { prependPartnerProxyAPIPrefix } from 'services/axios/setBaseUrl';
import { useDefaultNotifications } from 'hooks/useDefaultNotifications';
import pushCallbacks from './pushCallbacks';
const refreshPage = (data) => {
    // Some notifications don't set the url, e.g. synthesis/refresh_share_class_performance_worker.rb
    // in that case refresh the current page
    window.location.href = (data === null || data === void 0 ? void 0 : data.url) || window.location.href;
};
export function useGlobalHandlers() {
    const { t } = useTranslation();
    const { enqueueSnackbarError, enqueueSnackbarSuccess, enqueueSnackbarInfo } = useDefaultNotifications();
    const initializeGlobalHandlers = useCallback((channel) => {
        channel.bind(PUSH_EVENTS.JOB_STARTED, (data) => {
            enqueueSnackbarInfo(data.message, {});
        });
        channel.bind(PUSH_EVENTS.JOB_COMPLETE, (data) => {
            enqueueSnackbarInfo(data.message, {
                onActionClick: () => refreshPage(data),
                actionButtonLabel: t('common:reload_small', 'reload'),
            });
        });
        channel.bind(PUSH_EVENTS.JOB_SUCCESS, (data) => {
            enqueueSnackbarSuccess(data.message, {
                onActionClick: () => refreshPage(data),
                actionButtonLabel: t('common:reload_small', 'reload'),
                persist: data.persist || false,
            });
        });
        channel.bind(PUSH_EVENTS.JOB_FAILED, (data) => {
            enqueueSnackbarError(data.message, {
                onActionClick: () => refreshPage(data),
                actionButtonLabel: t('common:reload_small', 'reload'),
                persist: data.persist || false,
            });
        });
        channel.bind(PUSH_EVENTS.EXPORT_FINISHED, (data) => {
            enqueueSnackbarSuccess(`${t('common:messages.your_export_is_ready_for_download', 'Your export is ready for download.')} `, {
                onActionClick: () => window.open(data.url, '_blank'),
                actionButtonLabel: t('common:download_caps', 'DOWNLOAD'),
            });
            pushCallbacks.onExportFinished();
        });
        channel.bind(PUSH_EVENTS.EXPORT_FAILED, (data) => {
            enqueueSnackbarError(data.message, {});
        });
        channel.bind(PUSH_EVENTS.FILE_DOWNLOAD, (data) => {
            enqueueSnackbarSuccess(`${t('common:messages.your_download_file_is_ready', 'Your download file is ready.')} `, {
                onActionClick: () => window.open(data.url, '_blank'),
                actionButtonLabel: t('common:download', 'Download'),
                persist: true,
            });
            pushCallbacks.onExportFinished();
        });
        channel.bind(PUSH_EVENTS.IMPORT_FINISHED, (data) => {
            enqueueSnackbarInfo(data.message || `${t('common:messages.your_upload_is_ready_to', 'Your upload is ready to')} `, {
                onActionClick: () => window.open(data.url, '_blank'),
                actionButtonLabel: t('common:download_small', 'download'),
            });
        });
        channel.bind(PUSH_EVENTS.IMPORT_FAILED, (data) => {
            enqueueSnackbarError(data.message, {
                onActionClick: () => window.open(data.url, '_blank'),
                actionButtonLabel: data.urlText,
            });
        });
        channel.bind(PUSH_EVENTS.EXPORT_DOWNLOAD, (data) => {
            enqueueSnackbarSuccess(data.message, {});
            document.location.href = data.url;
        });
        channel.bind(PUSH_EVENTS.EXPORT_STARTED, () => {
            enqueueSnackbarInfo(`${t('common:messages.your_export_may_take_a_few_moments', 'Your export may take a few moments.')} ${t('common:messages.we_will_notify_you', "We will notify you when it's ready.")}`, {});
        });
        channel.bind(PUSH_EVENTS.WATERMARK_DOCUMENT_READY, (data) => {
            enqueueSnackbarInfo(`${t('common:messages.your_document_is_ready_to', 'Your document is ready to')} `, {
                onActionClick: () => window.open(data.url, '_blank'),
                actionButtonLabel: t('common:preview_small', 'preview'),
                autoHideDuration: 7000,
            });
            pushCallbacks.onWatermarkDocumentReady(data.documentType, data.documentId, data.status);
        });
        channel.bind(PUSH_EVENTS.WATERMARK_DOCUMENT_ERROR, (data) => {
            enqueueSnackbarError(data.message, {
                autoHideDuration: 7000,
            });
            pushCallbacks.onWatermarkDocumentReady(data.documentType, data.documentId, data.status);
        });
        channel.bind(PUSH_EVENTS.REFRESH_STARTED, (data) => setTimeout(() => {
            enqueueSnackbarInfo(data.message, {});
        }));
        channel.bind(PUSH_EVENTS.REFRESH_ONGOING, (data) => setTimeout(() => {
            enqueueSnackbarInfo(data.message, {});
        }));
        channel.bind(PUSH_EVENTS.REFRESH_FINISHED, (data) => setTimeout(() => {
            enqueueSnackbarSuccess(data.message, {});
        }));
        channel.bind(PUSH_EVENTS.REFRESH_FINISHED_WITH_ERRORS, (data) => setTimeout(() => {
            enqueueSnackbarError(data.message, {});
        }));
        channel.bind(PUSH_EVENTS.EMAIL_STATUS_PROCESSING, (data) => {
            enqueueSnackbarInfo(data.message, {
                autoHideDuration: 1000,
            });
        });
        channel.bind(PUSH_EVENTS.EMAIL_STATUS_FINISHED, (data) => {
            enqueueSnackbarInfo(data.message, {});
            document.location.href = data.url;
        });
        channel.bind(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_STARTED, (data) => setTimeout(() => {
            enqueueSnackbarInfo(data.message, {});
        }, 500));
        channel.bind(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_ERROR, (data) => setTimeout(() => {
            enqueueSnackbarError(data.message, {});
        }, 500));
        channel.bind(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_FINISHED, (data) => enqueueSnackbarSuccess(t('glossary:agreement_preview_generated', 'Agreement preview generation finished'), {
            onActionClick: () => window.open(data.url, '_blank'),
            actionButtonLabel: t('glossary:click_to_view', 'Click here to view'),
            persist: true,
        }));
        channel.bind(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_READY, (data) => {
            const reportDowloadUrl = prependPartnerProxyAPIPrefix(`/api/v1/investment_dashboard/pdf_report/${data.request_id}/pdf_file`);
            enqueueSnackbarInfo(t('investment_dashboard:your_report_is_available', 'Your Investment Report is available to'), {
                onActionClick: () => window.open(reportDowloadUrl, '_blank'),
                actionButtonLabel: t('common:download_small', 'download'),
                persist: true,
            });
        });
        channel.bind(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_NO_DATA, () => {
            enqueueSnackbarInfo(t('investment_dashboard:no_data_to_generate_report'), {});
        });
        channel.bind(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_FAILED, () => {
            enqueueSnackbarError(t('common:errors.problem_generating_report', 'There was a problem generating the report, please try again later'));
        });
        channel.bind(PUSH_EVENTS.WRAP_DOCUMENT_STARTED, (data) => {
            enqueueSnackbarInfo(data.message, {
                persist: true,
            });
        });
        channel.bind(PUSH_EVENTS.WRAP_DOCUMENT_FINISHED, (data) => {
            enqueueSnackbarSuccess(data.message, {
                persist: true,
            });
        });
        channel.bind(PUSH_EVENTS.WRAP_DOCUMENT_FAILED, (data) => {
            enqueueSnackbarError(data.message, {
                persist: true,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { initializeGlobalHandlers };
}
