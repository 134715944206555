/* eslint-disable max-lines */
import isEqual from 'lodash/isEqual';
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';

import {
  GL_SET_FUNDS_FOR_FILTER,
  GL_SELECTED_FUND,
  GL_SELECTED_PERIOD,
  GL_SET_ACCOUNTS_FOR_FILTER,
  GL_SELECTED_ACCOUNTS,
  GL_SET_STATUSES_FOR_FILTER,
  GL_SELECTED_STATUSES,
  GL_DOCUMENT_EXPORTS,
  GL_SELECTED_TRANSACTION_IDS,
  GL_SET_SELECTED_BATCHES,
  GL_SELECT_ALL,
  GL_TRANSACTIONS_TOTALS,
  GL_PENDING_TOTALS,
  GL_SET_ACTIVE_TAB,
  GL_RESET_STATE,
  GL_MANAGE_ARCHIVE_MODAL,
  GL_MANAGE_MOVE_TO_PENDING_MODAL,
  GL_MANAGE_APPROVE_MODAL,
  GL_SET_FETCHING,
  GL_SET_TRANSACTION_FETCHING,
  GL_SET_TABLE_FETCHING,
} from 'actions/actionsConstants';

const glSetFundsForFilter = createAction(GL_SET_FUNDS_FOR_FILTER);
const glSetSelectedFund = createAction(GL_SELECTED_FUND);
const glSetAccountsForFilter = createAction(GL_SET_ACCOUNTS_FOR_FILTER);
const glSetSelectedAccounts = createAction(GL_SELECTED_ACCOUNTS);
const glSetStatusesForFilter = createAction(GL_SET_STATUSES_FOR_FILTER);
const glSetSelectedStatuses = createAction(GL_SELECTED_STATUSES);
const glSetDocumentExports = createAction(GL_DOCUMENT_EXPORTS);
const glSetSelectedTransactionIds = createAction(GL_SELECTED_TRANSACTION_IDS);
const glSetSelectedBatches = createAction(GL_SET_SELECTED_BATCHES);
const glSelectAll = createAction(GL_SELECT_ALL);
const glPendingTotals = createAction(GL_PENDING_TOTALS);
const glTransactionsTotals = createAction(GL_TRANSACTIONS_TOTALS);
const glSetFetching = createAction(GL_SET_FETCHING);
const glSetTransactionFetching = createAction(GL_SET_TRANSACTION_FETCHING);
const glSetTableFetching = createAction(GL_SET_TABLE_FETCHING);
const glSetActiveTab = createAction(GL_SET_ACTIVE_TAB);
const glManageArchiveModal = createAction(GL_MANAGE_ARCHIVE_MODAL);
const glManageMoveToPendingModal = createAction(GL_MANAGE_MOVE_TO_PENDING_MODAL);
const glManageApproveModal = createAction(GL_MANAGE_APPROVE_MODAL);

function glLoadFundsForFilter() {
  return (dispatch) => {
    dispatch(glSetFetching(true));
    return dispatch(
      getRequest({
        url: '/private_access_funds/for_fund_admin',
        params: { fund_type: 'private_equity', vintage: true },
        onSuccess: glSetFundsForFilter,
        showSpinner: false,
      })
    ).finally(() => dispatch(glSetFetching(false)));
  };
}

function glFilterByFunds(newSelectedFund) {
  return (dispatch, getState) => {
    const oldSelectedFund = getState().generalLedgers.selected_fund;
    if (!isEqual(newSelectedFund, oldSelectedFund)) {
      return dispatch(glSetSelectedFund(newSelectedFund));
    }
    return Promise.resolve();
  };
}

function glResetState() {
  return {
    type: GL_RESET_STATE,
  };
}

function glSetSelectedPeriod(data) {
  return {
    type: GL_SELECTED_PERIOD,
    payload: data || '',
  };
}

function glFilterByPeriod(newSelectedPeriod) {
  return (dispatch, getState) => {
    const oldSelectedPeriod = getState().generalLedgers.selected_period;
    if (!isEqual(newSelectedPeriod, oldSelectedPeriod)) {
      return dispatch(glSetSelectedPeriod(newSelectedPeriod));
    }
    return Promise.resolve();
  };
}

function getPeriodForTransactions(privateAccessFundId, activeTab) {
  return (dispatch) => {
    dispatch(glSetTransactionFetching(true));

    return dispatch(
      getRequest({
        url: 'general_ledger_accounts/period_default',
        params: {
          private_access_fund_id: privateAccessFundId,
          transaction_type: activeTab,
        },
        onSuccess: (data) => glSetSelectedPeriod(data),
        showSpinner: false,
      })
    ).finally(() => dispatch(glSetTransactionFetching(false)));
  };
}

function glLoadAccountsForFilter(privateAccessFundId, activeTab, period) {
  return (dispatch) => {
    dispatch(glSetTransactionFetching(true));

    return dispatch(
      getRequest({
        url: 'general_ledger_accounts/accounts_for_transactions',
        params: {
          private_access_fund_id: privateAccessFundId,
          transaction_type: activeTab,
          period,
        },
        onSuccess: (data) => {
          dispatch(glSetAccountsForFilter(data));
          dispatch(glSetSelectedAccounts([]));

          return () => {};
        },
        showSpinner: false,
      })
    ).finally(() => dispatch(glSetTransactionFetching(false)));
  };
}

// needs to receive an array with the account ids
function glFilterByAccounts(selectedAccounts) {
  return (dispatch, getState) => {
    const oldSelectedAccounts = getState().generalLedgers.selected_accounts;
    const newSelectedAccounts = selectedAccounts.map((e) => e.id);
    if (!isEqual(newSelectedAccounts, oldSelectedAccounts)) {
      return dispatch(glSetSelectedAccounts(newSelectedAccounts));
    }
    return Promise.resolve();
  };
}

function glLoadStatusesForFilter(privateAccessFundId, enteredAtStart, enteredAtEnd, generalLedgerAccountIds) {
  return (dispatch) => {
    dispatch(glSetTransactionFetching(true));

    return dispatch(
      getRequest({
        url: 'pending_general_ledger_transactions/statuses',
        params: {
          private_access_fund_id: privateAccessFundId,
          entered_at_start: enteredAtStart,
          entered_at_end: enteredAtEnd,
          general_ledger_account_ids: generalLedgerAccountIds,
        },
        onSuccess: (data) => {
          dispatch(glSetStatusesForFilter(data));
          dispatch(glSetSelectedStatuses([]));

          return () => {};
        },
        showSpinner: false,
      })
    ).finally(() => dispatch(glSetTransactionFetching(false)));
  };
}

function glFilterByStatuses(selectedStatuses) {
  return (dispatch, getState) => {
    const oldSelectedStatuses = getState().generalLedgers.selected_statuses;
    if (!isEqual(selectedStatuses, oldSelectedStatuses)) {
      return dispatch(glSetSelectedStatuses(selectedStatuses));
    }
    return Promise.resolve();
  };
}

function glLoadTransactionsExports(documentTypes, currentPage = 1, resultsPerPage = 25) {
  return (dispatch) => {
    dispatch(glSetTableFetching(true));
    return dispatch(
      getRequest({
        url: 'document_exports',
        params: {
          document_types: documentTypes,
          page: currentPage,
          per_page: resultsPerPage,
        },
        showSpinner: false,
        onSuccess: (data) => glSetDocumentExports(data),
      })
    ).finally(() => glSetTableFetching(false));
  };
}

function glArchiveTransactions(transactionIds, selectedBatches, privateAccessFundId) {
  return postRequest({
    url: '/general_ledger_transactions/approved_archive',
    data: {
      transaction_ids: transactionIds,
      batch_ids: selectedBatches,
      private_access_fund_id: privateAccessFundId,
    },
    showSpinner: false,
  });
}

function glPendingArchiveTransactions(transactionIds, selectedBatches, privateAccessFundId) {
  return postRequest({
    url: '/general_ledger_transactions/pending_archive',
    data: {
      transaction_ids: transactionIds,
      batch_ids: selectedBatches,
      private_access_fund_id: privateAccessFundId,
    },
  });
}

function glMoveToPendingTransactions(transactionIds, selectedBatches, privateAccessFundId) {
  return postRequest({
    url: '/general_ledger_transactions/approved_pending',
    data: {
      transaction_ids: transactionIds,
      batch_ids: selectedBatches,
      paf_id: privateAccessFundId,
    },
    showSpinner: false,
  });
}

function glAcceptPendingTransactions(transactionIds, selectedBatches, privateAccessFundId) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: '/pending_general_ledger_transactions/accept',
        data: {
          pending_transaction_ids: transactionIds,
          batch_ids: selectedBatches,
          paf_id: privateAccessFundId,
        },
        showSpinner: false,
      })
    );
}

function exportTransactions(params, activeTab) {
  let url;
  const data = {
    private_access_fund_id: params.privateAccessFundId,
    transaction_ids: params.transactionIds,
    general_ledger_account_ids: params.generalLedgerAccountIds,
    entered_at_start: params.enteredAtStart,
    entered_at_end: params.enteredAtEnd,
  };

  if (activeTab === 'pending') {
    url = '/pending_general_ledger_transactions/export';
    data.statuses = params.statuses;
  } else {
    url = '/fund_admin_reporting/export_general_ledger_transactions_report';
  }

  return postRequest({
    url,
    data,
    showSpinner: false,
  });
}

function glPendingFetchTotalAmount(params) {
  return (dispatch) => {
    dispatch(glSetTableFetching(true));
    return dispatch(
      getRequest({
        url: 'pending_general_ledger_transactions/total_amount_transactions',
        params: {
          private_access_fund_id: params.privateAccessFundId,
          general_ledger_account_ids: params.generalLedgerAccountIds,
          entered_at_start: params.enteredAtStart,
          entered_at_end: params.enteredAtEnd,
          statuses: params.statuses,
        },
        showSpinner: false,
        onSuccess: (data) => glPendingTotals(data),
      })
    ).finally(() => glSetTableFetching(false));
  };
}

function glTransactionsFetchTotalAmount(params) {
  return (dispatch) => {
    dispatch(glSetTableFetching(true));
    return dispatch(
      getRequest({
        url: 'general_ledger_transactions/total_amount_transactions',
        params: {
          private_access_fund_id: params.privateAccessFundId,
          general_ledger_account_ids: params.generalLedgerAccountIds,
          entered_at_start: params.enteredAtStart,
          entered_at_end: params.enteredAtEnd,
        },
        showSpinner: false,
        onSuccess: (data) => glTransactionsTotals(data),
      })
    ).finally(() => glSetTableFetching(false));
  };
}

export default {
  glFilterByFunds,
  glSetSelectedFund,
  glLoadFundsForFilter,
  glFilterByPeriod,
  glLoadAccountsForFilter,
  glLoadStatusesForFilter,
  glSetSelectedStatuses,
  glFilterByStatuses,
  getPeriodForTransactions,
  glFilterByAccounts,
  glLoadTransactionsExports,
  glSetSelectedTransactionIds,
  glSetSelectedBatches,
  glArchiveTransactions,
  glAcceptPendingTransactions,
  glMoveToPendingTransactions,
  glPendingArchiveTransactions,
  exportTransactions,
  glSetSelectedAccounts,
  glSelectAll,
  glPendingTotals,
  glTransactionsTotals,
  glPendingFetchTotalAmount,
  glTransactionsFetchTotalAmount,
  glSetActiveTab,
  glResetState,
  glManageArchiveModal,
  glManageMoveToPendingModal,
  glManageApproveModal,
  glSetTableFetching,
};
