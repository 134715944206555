import axios from 'axios';

export const getFundNavPrices = (query) => {
  const url = 'flexible_feeds/dashboard/filters/fund_nav_prices';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then(response => response.data)
    .catch(error => error);
};
