const announcement = {
  announcement_category_id: undefined,
  announcement_documents: [],
  audience_groups: [],
  body: '',
  button_interaction: false,
  button_text: '',
  button_url: '',
  disclaimer: '',
  expiration_date: '',
  featured_until: '',
  funds: [],
  id: undefined,
  image_url: '',
  status: 'draft',
  title: '',
  visible_date: '',
  translations: [],
};

const insight = {
  id: undefined,
  status: 'draft',
  visible_date: '',
  category: '',
  title: '',
  description: '',
  url: '',
  authors: '',
  image: '',
};

export default {
  configurations: {
    customContent: '',
    customDisclaimer: '',
    showAnnouncements: false,
    showIcapitalInsights: false,
    showInvestmentOpportunitiesColletions: false,
  },
  heroConfiguration: {
    image: '',
    heroColor: '',
    showSalutation: false,
    title: '',
    description: '',
    resourceCenterPage: '',
    showHelpContactInformation: false,
    textTreatment: '',
    applyGradientToImage: true,
  },
  advisorMarketingFields: { fundName: '', announcement: '', announcementUrl: '' },
  announcements: { list: [], size: 0, filter: '' },
  announcementFunds: [],
  edit: {
    translation: {
      announcementDialogOpen: false,
      announcement: null,
      originalAnnouncement: null,
      list: {},
    },
    initialAnnouncement: { ...announcement },
    updatedAnnouncement: {},
    options: {
      categoriesOptions: [],
      fundsOptions: [],
      audienceGroupsOptions: [],
    },
    dialogOpen: false,
    checkForErrors: false,
    missingCategoriesData: false,
    announcementId: undefined,
    mode: undefined,
  },
  insights: {
    list: [],
    size: 0,
    filter: '',
    categories: [],
    categoriesSelected: [],
    isLoading: true,
    edit: {
      initialState: { ...insight },
      updatedState: {},
      options: {
        categoriesOptions: [],
      },
      dialogOpen: false,
      checkForErrors: false,
      changed: false,
      insightId: undefined,
      mode: undefined,
    },
  },
  loading: true,
};
