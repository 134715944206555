var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { NotificationMessage } from 'components/shared/notifications/NotificationMessage';
export function useDefaultNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const enqueueMessage = useCallback((message, variant = 'default', options = {}) => {
        const { body, onActionClick } = options, rest = __rest(options, ["body", "onActionClick"]);
        const finalMessage = body ? _jsx(NotificationMessage, { title: message, body: body }) : message;
        const id = enqueueSnackbar(finalMessage, Object.assign({ variant, anchorOrigin: { vertical: 'top', horizontal: 'right' }, onCloseAction: () => closeSnackbar(id), onActionClick: () => {
                if (typeof onActionClick === 'function') {
                    onActionClick(() => closeSnackbar(id));
                }
            } }, rest));
    }, [closeSnackbar, enqueueSnackbar]);
    const enqueueSnackbarDefault = useCallback((message, options = {}) => enqueueMessage(message, 'default', options), [enqueueMessage]);
    const enqueueSnackbarError = useCallback((message, options = {}) => enqueueMessage(message, 'error', options), [enqueueMessage]);
    const enqueueSnackbarSuccess = useCallback((message, options = {}) => enqueueMessage(message, 'success', options), [enqueueMessage]);
    const enqueueSnackbarInfo = useCallback((message, options = {}) => enqueueMessage(message, 'info', options), [enqueueMessage]);
    const enqueueSnackbarWarning = useCallback((message, options = {}) => enqueueMessage(message, 'warning', options), [enqueueMessage]);
    return {
        enqueueSnackbar: enqueueMessage,
        enqueueSnackbarDefault,
        enqueueSnackbarError,
        enqueueSnackbarSuccess,
        enqueueSnackbarInfo,
        enqueueSnackbarWarning,
    };
}
