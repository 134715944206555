import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { getRequest, patchRequest } from 'services/apiRequest';

export const getDownloadSubdocLink = (investment) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/investments/${investment.id}/subscription_document`,
});

export const updateInvestmentShareClass = (investment, shareClassId) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/investments/${investment.id}/set_investment_share_class`,
  data: {
    investment_share_class_id: shareClassId,
  },
});

export const getInvestmentEvents = (investmentId) => getRequest({ url: `investments/${investmentId}/events` });
