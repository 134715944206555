/* eslint-disable max-statements, max-lines */
import { getRequest, patchRequest, postRequest, deleteRequest } from 'actions/utils';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';
import { createAction } from 'redux-actions';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { createRequestTypes } from 'actions/helpers';
import { patchCommitmentAmount } from 'services/client_management/requests/commitmentAmount';
import { patchPlacementFee } from 'services/client_management/requests';
import { pick } from 'lodash';
import i18n from 'i18n';
import {
  currentPafId,
  selectedAdvisorId,
  searchTerm,
  searchType,
  searchNewCommentOnlyFilter,
  showCanceledInvestments,
  investmentsByState,
} from './selectors';

const CLIENT_MANAGEMENT_URL = 'client_management';
const BASE_URL = 'private_access_funds';
const BASE_NAME = 'clientManagement';

export const CLIENT_MANAGEMENT = {
  PAF_LIST: createRequestTypes(`${BASE_NAME}/PAF_LIST`),
  GET_PAF: createRequestTypes(`${BASE_NAME}/GET_PAF`),
  INVESTMENT_LIST: createRequestTypes(`${BASE_NAME}/INVESTMENT_LIST`),
  SET_SEARCH_TERM: `${BASE_NAME}/SET_SEARCH_TERM`,
  SET_SEARCH_TYPE: `${BASE_NAME}/SET_SEARCH_TYPE`,
  SET_NEW_COMMENT_ONLY_FILTER: `${BASE_NAME}/SET_NEW_COMMENT_ONLY_FILTER`,
  SET_INVESTMENT_STATS: `${BASE_NAME}/SET_INVESTMENT_STATS`,
  SET_SELECTED_ADVISOR: `${BASE_NAME}/SET_SELECTED_ADVISOR`,
  SET_SHOW_CANCELED_INVESTMENTS: `${BASE_NAME}/SET_SHOW_CANCELED_INVESTMENTS`,
  EXPORT_FINISHED: `${BASE_NAME}/EXPORT_FINISHED`,
  SET_EXPAND_INVESTMENT_STATE: `${BASE_NAME}/SET_EXPAND_INVESTMENT_STATE`,
  SET_REDEMPTIONS_OPEN_GROUPS: `${BASE_NAME}/SET_REDEMPTIONS_OPEN_GROUPS`,
  CANCEL_INVESTMENT: `${BASE_NAME}/CANCEL_INVESTMENT`,
  WHITE_LABEL_PARTNER: createRequestTypes(`${BASE_NAME}/WHITE_LABEL_PARTNER`),
  TRANSFERS: createRequestTypes(`${BASE_NAME}/TRANSFERS`),
  CLEAR_REDEMPTIONS_TOTALS: `${BASE_NAME}/CLEAR_REDEMPTIONS_TOTALS`,
  SET_EXPIRED_INVESTMENT_STATE: createRequestTypes(`${BASE_NAME}/SET_EXPIRED_INVESTMENT_STATE`),
};

export const setSelectedAdvisor = createAction(CLIENT_MANAGEMENT.SET_SELECTED_ADVISOR);
export const setShowCanceledInvestments = createAction(CLIENT_MANAGEMENT.SET_SHOW_CANCELED_INVESTMENTS);

export const getInvestment = (id) => (dispatch) =>
  dispatch(
    getRequest({
      showSpinner: false,
      url: `${CLIENT_MANAGEMENT_URL}/investments/${id}`,
      errorMessage: FALLBACK_ERROR_MESSAGE,
    })
  );

export const getInvestments = () => (dispatch, getState) => {
  const state = getState();
  const term = searchTerm(state);
  const type = searchType(state);
  const newCommentOnlyFilter = searchNewCommentOnlyFilter(state);
  const canceled = showCanceledInvestments(state);
  const userId = selectedAdvisorId(state);
  const pafId = currentPafId(state);
  const states = investmentsByState(state);

  return Promise.all(
    Object.keys(states)
      .filter((stateKey) => {
        const { expired } = states[stateKey];
        const expanded = state.clientManagement.expandedGrids.includes(stateKey);

        return expanded && expired;
      })
      .map((stateKey) => {
        const { count } = states[stateKey];
        const request = { state: stateKey };
        const stateToRequest = [stateKey];

        if (stateKey === INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED) {
          stateToRequest.push(INVESTMENT_APPROVAL_STATUS_OVERRIDES.INVESTMENT_AUTO_REVERTED);
        }

        if (!count) {
          return dispatch({ type: CLIENT_MANAGEMENT.INVESTMENT_LIST.SUCCESS, payload: { response: [], request } });
        }

        return dispatch(
          getRequest({
            showSpinner: false,
            url: `${CLIENT_MANAGEMENT_URL}/investments`,
            requestParams: request,
            params: {
              search_term: term,
              search_type: type,
              new_comment_only: newCommentOnlyFilter,
              canceled,
              user_id: userId,
              paf_id: pafId,
              page: 1,
              state: stateToRequest,
              page_size: count < 500 ? 500 : 100,
              exclude_actions: true,
            },
            errorMessage: FALLBACK_ERROR_MESSAGE,
            actions: CLIENT_MANAGEMENT.INVESTMENT_LIST,
          })
        );
      })
  );
};

export const setExpandedState =
  (state, value, fetch = true) =>
  (dispatch) => {
    const promises = [dispatch({ type: CLIENT_MANAGEMENT.SET_EXPAND_INVESTMENT_STATE, payload: { state, value } })];

    if (fetch) {
      promises.push(dispatch(getInvestments()));
    }
    return Promise.all(promises);
  };

export const setRedemptionsOpenGroups = createAction(CLIENT_MANAGEMENT.SET_REDEMPTIONS_OPEN_GROUPS);

export const getInvestmentStats = () => (dispatch, getState) => {
  const state = getState();

  return dispatch(
    getRequest({
      url: `${CLIENT_MANAGEMENT_URL}/investments/stats`,
      params: {
        search_term: searchTerm(state),
        search_type: searchType(state),
        new_comment_only: searchNewCommentOnlyFilter(state),
        canceled: showCanceledInvestments(state),
        user_id: selectedAdvisorId(state),
        paf_id: currentPafId(state),
      },
      errorMessage: FALLBACK_ERROR_MESSAGE,
      onSuccess: (stats) => () =>
        Promise.all([
          dispatch({ type: CLIENT_MANAGEMENT.SET_INVESTMENT_STATS, payload: { response: stats } }),
          searchNewCommentOnlyFilter(state) &&
            Object.entries(stats).forEach(([key]) => {
              setExpandedState(key, true)(dispatch);
            }),
          dispatch(getInvestments()),
        ]),
    })
  );
};

export const addInvestmentInList = (investment) => (dispatch) =>
  Promise.all([dispatch(getInvestmentStats()), dispatch(setExpandedState(investment.approval_status_override, true))]);

export const cancelInvestment = (payload) => (dispatch) =>
  Promise.all([dispatch({ type: CLIENT_MANAGEMENT.CANCEL_INVESTMENT, payload }), dispatch(getInvestmentStats())]);

const setSearchTerm = createAction(CLIENT_MANAGEMENT.SET_SEARCH_TERM);
const setSearchType = createAction(CLIENT_MANAGEMENT.SET_SEARCH_TYPE);
const setNewCommentOnlyFilter = createAction(CLIENT_MANAGEMENT.SET_NEW_COMMENT_ONLY_FILTER);

export const search = (term, type, filter) => (dispatch) =>
  Promise.all([
    dispatch(setSearchTerm(term)),
    dispatch(setSearchType(type)),
    dispatch(setNewCommentOnlyFilter(filter)),
  ]);

export const getPafList = () =>
  getRequest({
    url: `${CLIENT_MANAGEMENT_URL}/private_access_funds`,
    actions: CLIENT_MANAGEMENT.PAF_LIST,
    errorMessage: FALLBACK_ERROR_MESSAGE,
  });

export const getPaf = (pafId) =>
  getRequest({
    url: `${CLIENT_MANAGEMENT_URL}/private_access_funds/${pafId}`,
    actions: CLIENT_MANAGEMENT.GET_PAF,
  });

export const createClientManagementExport =
  (exportType, filter = {}) =>
  (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'exports',
        data: {
          export_type: exportType,
          private_access_fund_id: currentPafId(getState()),
          ria_id: exportType === 'investment_export' ? selectedAdvisorId(getState()) : undefined,
          ...filter,
        },
      })
    );

export const clientManagementExportFinished = () => (dispatch) => dispatch({ type: CLIENT_MANAGEMENT.EXPORT_FINISHED });

export const setCommitmentAmount = (investmentId, investment) => (dispatch, getState) => {
  const privateAccessFundId = currentPafId(getState());
  return dispatch(
    patchCommitmentAmount(privateAccessFundId, investmentId, investment, { onSuccess: getInvestmentStats })
  );
};

export const setInvestmentPlacementFee = (investment, placementFee) => (dispatch) =>
  dispatch(patchPlacementFee(investment, placementFee, { onSuccess: getInvestmentStats }));

export const getWlpSpecificAttestation = (investmentId) => (dispatch) =>
  dispatch(
    getRequest({
      url: 'wlp_specific_attestations/for_investment',
      params: {
        investment_id: investmentId,
      },
    })
  );

export const createWlpAttestationRecord = (investmentId, wlpAttestationId, caiAnswer) => (dispatch) =>
  dispatch(
    postRequest({
      url: 'wlp_attestation_records',
      data: {
        record: {
          investment_id: investmentId,
          wlp_attestation_id: wlpAttestationId,
          cai_answer: caiAnswer,
        },
      },
      onSuccess: getInvestmentStats,
    })
  );

export const patchCancelInvestment = (investmentId) =>
  patchRequest({
    url: `investments/${investmentId}/cancel`,
    onSuccess: () => cancelInvestment({ investmentId }),
    showSpinner: false,
  });

export const patchResubmitInvestment = (investmentId) => (dispatch) =>
  dispatch(
    patchRequest({
      url: 'reversion/document_approvals/submit',
      data: {
        investment_id: investmentId,
      },
      onSuccess: getInvestmentStats,
      successMessage: { message: 'Investment resubmitted successfully' },
      errorMessage: FALLBACK_ERROR_MESSAGE,
    })
  );

export const sendRegistrationReminderEmail = (investmentId, investmentContactCard) =>
  postRequest({
    url: `investments/${investmentId}/send_registration_reminder`,
    successMessage: { message: i18n.t('client_management:send_registration_email_modal.reminder_success_message') },
    errorMessage: { message: i18n.t('client_management:send_registration_email_modal.reminder_error_message') },
    data: {
      contactCard: investmentContactCard,
    },
  });

export const sendInterestedPartyInvites = (investmentId, emails) =>
  postRequest({
    url: `investments/${investmentId}/send_interested_party_invitations`,
    data: {
      investmentId,
      emails,
    },
    successMessage: { message: i18n.t('client_management:send_registration_email_modal.registration_invite') },
    errorMessage: { message: i18n.t('client_management:send_registration_email_modal.registration_invite_error') },
  });

export const deleteInvestment =
  ({ pafId, investmentId }) =>
  (dispatch) =>
    dispatch(
      deleteRequest({
        url: `${BASE_URL}/${pafId}/investments/${investmentId}`,
        onSuccess: getInvestmentStats,
        successMessage: { message: i18n.t('client_management:delete_investment_modal.delete_success') },
        errorMessage: { message: i18n.t('client_management:delete_investment_modal.error_message') },
      })
    );

export const overrideInvestmentStatusRequest =
  (investment = {}) =>
  (dispatch) =>
    dispatch(
      patchRequest({
        url: `investments/${investment.id}/override_status`,
        data: {
          status_action: investment.status_override ? 'clear' : 'set',
        },
        onSuccess: getInvestmentStats,
        errorMessage: FALLBACK_ERROR_MESSAGE,
      })
    );

export const validateFundingDate =
  ({ fundingDate, investmentId }) =>
  (dispatch) =>
    dispatch(
      postRequest({
        url: 'investments/validate_funding_date',
        data: {
          funding_date: fundingDate,
          id: investmentId,
        },
        errorMessage: FALLBACK_ERROR_MESSAGE,
      })
    );

export const sendCustodianDocs =
  ({ investmentId, fundingDate, orderCondition, custodianAdvisoryType, integrationId }) =>
  (dispatch) =>
    dispatch(
      postRequest({
        url: `investments/${investmentId}/send_custodian_docs`,
        data: {
          funding_date: fundingDate,
          order_condition: orderCondition,
          custodian_advisory_type: custodianAdvisoryType,
          integration: integrationId,
        },
        errorMessage: FALLBACK_ERROR_MESSAGE,
      })
    );

export const saveTokenAndSendToFidelity =
  ({ currentPafId: pafId, ...rest }) =>
  (dispatch) =>
    dispatch(
      postRequest({
        url: `${BASE_URL}/${pafId}/investments/save_token_and_send_to_fidelity`,
        data: pick(rest, ['code', 'integration', 'investment_id', 'context', 'funding_date', 'order_condition']),
        errorMessage: FALLBACK_ERROR_MESSAGE,
      })
    );

export const clearRedemptionsTotals = () => (dispatch) =>
  dispatch({ type: CLIENT_MANAGEMENT.CLEAR_REDEMPTIONS_TOTALS });

export default CLIENT_MANAGEMENT;
