import { getRequest, postRequest, putRequest, deleteRequest } from 'actions/utils';

import {
  PDE_CREATE,
  PDE_EDIT,
  PDE_UPDATE,
  PDE_DELETE,
  PDE_SET_DATA,
  PDE_SHOW,
  PDE_RUN,
  PDE_SET_FUNDS,
  PDE_SET_FIRMS,
} from 'actions/actionsConstants';

const pdeUrl = `published_document_export`;

function pdeSetData(payload) {
  return {
    type: PDE_SET_DATA,
    payload,
  };
}

function pdeSetFunds(payload) {
  return {
    type: PDE_SET_FUNDS,
    payload,
  };
}

function pdeSetFirms(payload) {
  return {
    type: PDE_SET_FIRMS,
    payload,
  };
}

function pdeCreate(payload) {
  return {
    type: PDE_CREATE,
    payload,
  };
}

function pdeEdit(payload) {
  return {
    type: PDE_EDIT,
    payload,
  };
}

function pdeUpdate(payload) {
  return {
    type: PDE_UPDATE,
    payload,
  };
}

function pdeDelete(payload) {
  return {
    type: PDE_DELETE,
    payload,
  };
}

function pdeShow(payload) {
  return {
    type: PDE_SHOW,
    payload,
  };
}

function pdeRun(payload) {
  return {
    type: PDE_RUN,
    payload,
  };
}

function pdeObject(pde) {
  return {
    name: pde.name,
    document_type_ids: pde.document_type_id
      .replace(/\s+/g, '')
      .split(',')
      .filter((e) => e && !Number.isNaN(e))
      .map((e) => parseInt(e, 10)),
    query_service: pde.query_service,
    integration_partner_id: pde.integration_partner.id,
    white_label_partner_id: pde.white_label_partner?.id,
    date_format: pde.zip_date_format,
    file_name: pde.zip_name,
    pdf_date_format: pde.pdf_date_format,
    pdf_name: pde.pdf_name,
    frequency: pde.frequency,
    run_hour: pde.run_hour && `0${pde.run_hour}`.slice(-2),
    expression: pde.expression,
    extra_service: pde.extra_service,
    encryption: pde.encryption,
    enabled: pde.enabled,
    allow_kafka_event: pde.allow_kafka_event,
    allow_only_approved_documents: pde.allow_only_approved_documents,
    threshold: pde.threshold,
    extra_file_name: pde.extra_file_name,
    extra_file_date_format: pde.extra_file_date_format,
    code: pde.code,
    form_number: pde.form_number,
    docs_methods: pde.docs_method || [],
    doc_type_option: pde.doc_type_option || 'some',
    fund_option: pde.fund_option || 'all',
    firm_option: pde.firm_option || 'all',
    firm_ids: pde.firms.length > 0 ? pde.firms.map((x) => x.id) : [],
    fund_ids: pde.funds.length > 0 ? pde.funds.map((x) => x.id) : [],
    funds: pde.funds,
    firms: pde.firms,
  };
}

const getPDETableInfo = () =>
  getRequest({
    url: `${pdeUrl}`,
    showSpinner: true,
    withCredentials: true,
    onSuccess: (response) => pdeShow(response.rows),
  });

const changeStateGetData = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeSetData(response));
    callback();
  };
};

const getPDEData = (callback = () => {}) =>
  getRequest({
    url: `${pdeUrl}/data`,
    showSpinner: true,
    withCredentials: true,
    onSuccess: changeStateGetData(callback),
  });

const changeStateToEdit = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeEdit(response));
    callback();
  };
};

const getPDEToEdit = (id, callback = () => {}) =>
  getRequest({
    url: `${pdeUrl}/${id}`,
    showSpinner: true,
    withCredentials: true,
    onSuccess: changeStateToEdit(callback),
  });

const changeStateCreated = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeCreate(response));
    callback(true, 'Published Document Export was successfully created.');
  };
};

const createPDE = (pde, callback = () => {}) =>
  postRequest({
    url: `${pdeUrl}`,
    showSpinner: true,
    withCredentials: true,
    data: pdeObject(pde),
    onSuccess: changeStateCreated(callback),
    onFailure: () => {
      callback(false, 'There was an error creating Published Document Export.');
    },
  });

const changeStateEdited = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeUpdate(response));
    callback(true, 'Published Document Export was successfully updated.');
  };
};

const editPDE = (pde, callback = () => {}) =>
  putRequest({
    url: `${pdeUrl}/${pde.id}`,
    showSpinner: true,
    withCredentials: true,
    data: pdeObject(pde),
    onSuccess: changeStateEdited(callback),
    onFailure: () => {
      callback(false, 'There was an error updating Published Document Export.');
    },
  });

const changeStateDeleted = (id, callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeDelete(id));
    callback(true, 'Published Document Export was successfully deleted.');
  };
};

const deletePDE = (id, callback = () => {}) =>
  deleteRequest({
    url: `${pdeUrl}/${id}`,
    showSpinner: true,
    withCredentials: true,
    onSuccess: changeStateDeleted(id, callback),
    onFailure: () => {
      callback(false, 'There was an error deleting the Published Document Export.');
    },
  });

const changeStateRun = (id, callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeRun(id));
    callback(true, 'Published Document Export was successfully started.');
  };
};

const changeFundsData = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeSetFunds(response));
  };
};

const pdeFetchFunds = (query, callback = () => {}) =>
  getRequest({
    url: `${pdeUrl}/funds`,
    showSpinner: true,
    withCredentials: true,
    params: { query },
    onSuccess: changeFundsData(callback),
  });

const changeFirmsData = (callback) => (response) => {
  return (dispatch) => {
    dispatch(pdeSetFirms(response));
  };
};

const pdeFetchFirms = (query, callback = () => {}) =>
  getRequest({
    url: `${pdeUrl}/firms`,
    showSpinner: true,
    withCredentials: true,
    params: { query },
    onSuccess: changeFirmsData(callback),
  });

const runPDE = (id, callback = () => {}, date) =>
  postRequest({
    url: `${pdeUrl}/${id}/run_export`,
    showSpinner: true,
    withCredentials: true,
    data: { dateUTC: date },
    onSuccess: changeStateRun(id, callback),
    onFailure: () => {
      callback(false, 'Error starting the Document Export.');
    },
  });

export default {
  pdeSetData,
  pdeShow,
  pdeCreate,
  pdeEdit,
  getPDETableInfo,
  getPDEData,
  pdeFetchFunds,
  pdeFetchFirms,
  getPDEToEdit,
  createPDE,
  editPDE,
  deletePDE,
  runPDE,
};
