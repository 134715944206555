/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import isObject from 'lodash/isObject';
import { map, reduce, pick, get, omit, isEqual, snakeCase } from 'lodash';
import produce from 'immer';
import { SR_TABLE_STATUS } from 'constants/index';
import {
  SUBSCRIPTION_REVIEW_SET_PAFS,
  SUBSCRIPTION_REVIEW_SELECT_PAF,
  SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS,
  SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES,
  SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY,
  SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT,
  SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT,
  SUBSCRIPTION_REVIEW_ADD_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS,
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP,
  SUBSCRIPTION_REVIEW_SET_SEARCH_TERM,
  SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE,
  SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS,
  SUBSCRIPTION_REVIEW_UPDATE_TRANSFER,
  SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY,
  SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE,
  SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_ZIPS,
  SUBSCRIPTION_REVIEW_SET_ACCEPTED_INVESTMENTS_CLOSES,
  SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS,
  SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND,
  SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE,
  SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT,
  SUBSCRIPTION_REVIEW_REMOVE_CLOSE,
  SUBSCRIPTION_REVIEW_SET_PAF_NOTE,
  SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE,
  SUBSCRIPTION_REVIEW_SET_USER_VIEWS,
  SUBSCRIPTION_REVIEW_UPDATE_CLOSE,
  SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_COMMITMENT,
  SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS,
  SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE,
  SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE,
  SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS,
  SUBSCRIPTION_REVIEW_RESET_STATE,
  SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS,
  SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS,
  SUBSCRIPTION_REVIEW_SET_GRID_STATES,
  SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE,
  SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW,
  SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW,
  SUBSCRIPTION_REVIEW_RESET_FILTERS,
  SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS,
  SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS,
  SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE,
  SUBSCRIPTION_REVIEW_STATE_RENDERED,
  SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED,
  SUBSCRIPTION_REVIEW_STATE_LOADING,
  SUBSCRIPTION_REVIEW_SET_STATE_FILTER,
  SUBSCRIPTION_REVIEW_RESET_STATE_FILTER,
  SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS,
  SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS,
  SUBSCRIPTION_REVIEW_SELECT_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET,
  SUBSCRIPTION_REVIEW_SET_SNACKBAR,
} from 'actions/actionsConstants';
import { SR_GET_SELECTED_PAF } from 'actions/subscription_review_actions/subscriptionReviewActions';

import update from 'immutability-helper';
import { sortArrayAlphabetically } from '../subscription_reviewer_reducers/utils';

const {
  REVERTED,
  PENDING_REVIEW,
  EXTERNAL_REVIEW,
  EXTERNAL_SECONDARY_REVIEW,
  IR_REVIEW,
  COMPLIANCE_REVIEW,
  APPROVED,
  ACCEPTED,
  PRE_APPROVAL,
  PRE_APPROVAL_SECONDARY,
  TRANSFERS,
  REDEMPTIONS,
  INVESTMENT_AUTO_REVERTED,
} = SR_TABLE_STATUS;

const createInvestmentGroups = () => ({
  [REVERTED]: [],
  [PENDING_REVIEW]: [],
  [EXTERNAL_REVIEW]: [],
  [EXTERNAL_SECONDARY_REVIEW]: [],
  [IR_REVIEW]: [],
  [COMPLIANCE_REVIEW]: [],
  [APPROVED]: [],
  [ACCEPTED]: [],
  [PRE_APPROVAL]: [],
  [PRE_APPROVAL_SECONDARY]: [],
});

const createGridToggleStates = (flag, transfer_flag = false, redemption_flag = false) =>
  Object.keys(createInvestmentGroups()).reduce(
    (result, state) => {
      result[state] = flag;
      return result;
    },
    {
      [TRANSFERS]: transfer_flag,
      [REDEMPTIONS]: redemption_flag,
    }
  );

const createStatsByStatus = () =>
  Object.keys(createInvestmentGroups()).reduce((result, state) => {
    result[state] = {
      investments_count: 0,
      commitment_amount: 0,
      new_comment_count: 0,
    };
    return result;
  }, {});

export const initialState = {
  pafs: [],
  wlps: [],
  riasFirms: [],
  paf_investment_groups: {
    ...createInvestmentGroups(),
    [TRANSFERS]: [],
    [REDEMPTIONS]: [],
  },
  transfer_investments: [],
  paf_investments: [],
  pafSeries: [],
  selected_paf_id: null,
  selectedPaf: undefined,
  fund_categories: [],
  selected_fund_category: null,
  selected_filters: {
    rias_firms: [],
    rias_users: [],
    wlps: [],
    custodians: [],
    lead_approvers: [],
    sign_methods: [],
    branch_codes: [],
    referral_codes: [],
    show_cancelled_investments: false,
    new_comment_only: false,
  },
  state_filters: {},
  forceCloseDateFilterOptionsRefresh: {},
  searchTerm: '',
  searchType: undefined,
  gridStates: {},
  paf_investment_stats_by_status: createStatsByStatus(),
  gridToggleStates: {
    isLoading: createGridToggleStates(null, null),
    isExpanded: createGridToggleStates(false),
    didRenderOnce: createGridToggleStates(false, true, true),
  },
  currentSavedView: null,
  defaultSavedView: null,
  isSavedViewIncomplete: false,
  paf_summary: {
    total_investments: 0,
    total_commitment: 0,
    pending_commitment: 0,
  },
  paf_next_close: {},
  paf_aggregations: {
    share_classes: [],
  },
  generatedZips: {},
  generatedZipsForInvestment: {},
  acceptedInvestmentsCloses: {},
  generatedRedemptionsExports: {},
  generatedInvestmentsExports: {},
  generatedRemediationCommentsExports: {},
  generatedSignersExports: {},
  generatedApprovalHistoryExports: {},
  generatedLeadApproverAssignmentsExports: {},
  closesForFund: [],
  pafNote: {},
  user_views: { statuses: [] },
  allowed_statuses: [],
  investmentNotes: {
    tabInternal: {
      investment: [],
      investor_profile: [],
    },
    tabWlp: {
      investment: [],
      investor_profile: [],
    },
    tabExternal: {
      investment: [],
      investor_profile: [],
    },
    displayTabs: false,
    loaded: false,
  },
  subBooks: [],
  subBooksForInvestment: [],
  availableInvestmentShareClasses: [],
  paf_has_canceled_investments: false,
  investmentList: {
    currentBucket: '',
    totalSelected: 0,
    selectedIds: [],
  },
  snackbar: {
    isVisible: false,
    message: undefined,
  },
};

const replaceInvestment = (investments, payload) =>
  map(investments, (inv) => {
    if (inv.id === payload.id) {
      const uncachedComputedFields = {
        submission_date: payload.submission_date || inv.submission_date,
      };
      return { ...inv, ...payload, ...uncachedComputedFields };
    }
    return inv;
  });

const filterInvestmentsWithoutCloseDate = (status, state) => (investment) => {
  const closeDate = state.state_filters[status]?.close_date;

  return closeDate == null || closeDate.includes(investment.close_date);
};

const replaceInvestmentFields = (investments, investment, fields) =>
  investments.map((inv) => (inv.id === investment.id ? { ...inv, ...pick(investment, fields) } : inv));

const replaceInvestmentCloseDate = (investments, close) =>
  investments.map((inv) => {
    if (inv.close_date === close.close_date) {
      return { ...inv, close_date: null };
    }
    return inv;
  });

const replacePafNote = (selectedPaf, payload) => ({
  private_access_fund: {
    ...selectedPaf.private_access_fund,
    note: payload,
  },
});

const replaceUpdatedObject = (objects, updatedObj) =>
  objects.map((obj) => (obj.id === updatedObj.id ? { ...obj, ...updatedObj } : obj));

const getHeaderData = (payload, dataField) =>
  get(payload, [PENDING_REVIEW, dataField], 0) + get(payload, ['', dataField], 0);

const getIndex = (object, id) => object.findIndex((e) => e.id === id);

const STATUS_KEY = {
  accepted: { count: 'investments_count', amount: 'commitment_amount' },
  approved: { count: 'investments_count', amount: 'commitment_amount' },
  available_transfers: { count: 'transfers_count', amount: 'transfers_amount' },
  compliance_review: { count: 'investments_count', amount: 'commitment_amount' },
  executed_transfers: { count: 'transfers_count', amount: 'transfers_amount' },
  external_review: { count: 'investments_count', amount: 'commitment_amount' },
  external_secondary_review: { count: 'investments_count', amount: 'commitment_amount' },
  ir_review: { count: 'investments_count', amount: 'commitment_amount' },
  pending_review: { count: 'investments_count', amount: 'commitment_amount' },
  pre_approval: { count: 'investments_count', amount: 'commitment_amount' },
  pre_approval_secondary: { count: 'investments_count', amount: 'commitment_amount' },
  reverted: { count: 'investments_count', amount: 'commitment_amount' },
  investment_auto_reverted: { count: 'investments_count', amount: 'commitment_amount' },
  available_redemptions: { count: 'redemptions_count', amount: 'redemptions_amount' },
  holdback_redemptions: { count: 'redemptions_count', amount: 'redemptions_amount' },
  completed_redemptions: { count: 'redemptions_count', amount: 'redemptions_amount' },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_REVIEW_RESET_STATE:
      return { ...state, ...omit(initialState, ['pafs', 'fund_categories', 'defaultSavedView', 'selected_paf_id']) };
    case SUBSCRIPTION_REVIEW_SET_PAFS:
      const data = action.payload.map((fund) => ({
        private_access_fund: { ...fund.private_access_fund, name: fund.private_access_fund.name || '' },
      }));
      return update(state, {
        pafs: {
          $set: sortArrayAlphabetically(data, 'private_access_fund', 'name'),
        },
      });
    case SUBSCRIPTION_REVIEW_SET_USER_VIEWS:
      return update(state, {
        user_views: {
          $set: action.payload.user_subscription_review_view,
        },
        allowed_statuses: {
          $set: action.payload.allowed_statuses || state.allowed_statuses,
        },
      });
    case SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES:
      return update(state, {
        fund_categories: {
          $set: sortArrayAlphabetically(action.payload, 'value'),
        },
      });
    case SUBSCRIPTION_REVIEW_SELECT_PAF:
      return update(state, {
        selected_paf_id: {
          $set: action.payload,
        },
      });
    case SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY:
      return update(state, {
        selected_fund_category: {
          $set: action.payload,
        },
      });
    case SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS:
      return update(state, {
        paf_investments: {
          $set: action.payload,
        },
      });
    case SUBSCRIPTION_REVIEW_UPDATE_TRANSFER:
      const transferIdx = state.paf_investment_groups[TRANSFERS].findIndex((e) => e.id === action.payload.id);

      return update(state, {
        paf_investment_groups: (groups) =>
          update(groups || {}, {
            [TRANSFERS]: {
              [transferIdx]: { $merge: action.payload },
            },
          }),
      });
    case SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS:
      const { new_comment_count: newCommentCount, ...rest } = action.payload;
      return update(state, {
        new_comment_count: {
          $set: newCommentCount,
        },
        paf_investment_stats_by_status: {
          $set: {
            ...rest,
            [PENDING_REVIEW]: {
              investments_count: getHeaderData(action.payload, 'investments_count'),
              commitment_amount: getHeaderData(action.payload, 'commitment_amount'),
            },
          },
        },
      });
    case SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT: {
      const approvalStatus = action.payload.approval_status_override;
      const index = state.paf_investment_groups[approvalStatus].findIndex((e) => e.id === action.payload.id);
      const invCount = STATUS_KEY[approvalStatus].count;
      const invAmount = STATUS_KEY[approvalStatus].amount;
      const newAmount = state.paf_investment_stats_by_status[approvalStatus][invAmount] - action.payload.amount;

      return update(state, {
        paf_investment_groups: {
          [approvalStatus]: {
            $splice: [[index, 1]],
          },
        },
        paf_investments: {
          $splice: [[index, 1]],
        },
        paf_investment_stats_by_status: {
          [approvalStatus]: {
            $merge: {
              [invCount]: state.paf_investment_stats_by_status[approvalStatus][invCount] - 1,
              [invAmount]: newAmount,
            },
          },
        },
      });
    }
    case SUBSCRIPTION_REVIEW_ADD_INVESTMENT: {
      const approvalStatus = action.payload.approval_status_override;
      const invCount = STATUS_KEY[approvalStatus].count;
      const invAmount = STATUS_KEY[approvalStatus].amount;
      const newAmount = state.paf_investment_stats_by_status[approvalStatus][invAmount] + action.payload.amount;

      return update(state, {
        paf_investment_groups: {
          [approvalStatus]: {
            $push: [action.payload],
          },
        },
        paf_investments: {
          $push: [action.payload],
        },
        paf_investment_stats_by_status: {
          [approvalStatus]: {
            $merge: {
              [invCount]: state.paf_investment_stats_by_status[approvalStatus][invCount] + 1,
              [invAmount]: newAmount,
            },
          },
        },
      });
    }
    case SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT:
      return update(state, {
        paf_investment_groups: {
          [action.payload.approval_status_override]: {
            $apply: (investments) => replaceInvestment(investments, action.payload),
          },
        },
        paf_investments: {
          $apply: (investments) => replaceInvestment(investments, action.payload),
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SNACKBAR:
      return update(state, {
        snackbar: {
          $merge: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS: {
      const investmentGroups = createInvestmentGroups();
      action.payload.investments.forEach((inv) => {
        switch (inv.approval_status_override || action.payload.status) {
          case REVERTED:
          case INVESTMENT_AUTO_REVERTED:
            return investmentGroups[REVERTED].push(inv);
          case PENDING_REVIEW:
          case null:
            return investmentGroups[PENDING_REVIEW].push(inv);
          case EXTERNAL_REVIEW:
            return investmentGroups[EXTERNAL_REVIEW].push(inv);
          case EXTERNAL_SECONDARY_REVIEW:
            return investmentGroups[EXTERNAL_SECONDARY_REVIEW].push(inv);
          case IR_REVIEW:
            return investmentGroups[IR_REVIEW].push(inv);
          case COMPLIANCE_REVIEW:
            return investmentGroups[COMPLIANCE_REVIEW].push(inv);
          case APPROVED:
            return investmentGroups[APPROVED].push(inv);
          case ACCEPTED:
            return investmentGroups[ACCEPTED].push(inv);
          case PRE_APPROVAL:
            return investmentGroups[PRE_APPROVAL].push(inv);
          case PRE_APPROVAL_SECONDARY:
            return investmentGroups[PRE_APPROVAL_SECONDARY].push(inv);
          default:
        }
      });

      Object.keys(investmentGroups)
        .filter((st) => action.payload.status !== st)
        .forEach((st) => {
          investmentGroups[st] = state.paf_investment_groups[st];
        });

      return update(state, {
        paf_investment_groups: {
          $merge: investmentGroups,
        },
      });
    }

    case SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS:
      return update(state, {
        selected_filters: {
          $set: action.payload.relevantFilters,
        },
        isSavedViewIncomplete: {
          $set: action.payload.isSavedViewIncomplete,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP:
      const filterType = isObject(action.payload) ? action.payload.filterType : null;
      const filterOptions = isObject(action.payload) ? action.payload.options : null;
      return update(state, {
        selected_filters: {
          [filterType]: {
            $set: filterOptions,
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS:
      return update(state, {
        paf_has_canceled_investments: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SEARCH_TERM:
      return update(state, {
        searchTerm: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE:
      return update(state, {
        searchType: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY:
      return update(state, {
        paf_summary: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE:
      return update(state, {
        paf_next_close: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS:
      return update(state, {
        paf_aggregations: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_ZIPS:
      return update(state, {
        generatedZips: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_ACCEPTED_INVESTMENTS_CLOSES:
      return update(state, {
        acceptedInvestmentsCloses: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS:
      return update(state, {
        generatedInvestmentsExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS:
      return update(state, {
        generatedRedemptionsExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS:
      return update(state, {
        generatedRemediationCommentsExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS:
      return update(state, {
        generatedSignersExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS:
      return update(state, {
        generatedApprovalHistoryExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS:
      return update(state, {
        generatedLeadApproverAssignmentsExports: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND:
      return update(state, {
        closesForFund: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE:
      return update(state, {
        closesForFund: {
          $unshift: [action.payload],
        },
      });

    case SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT:
      return update(state, {
        forceCloseDateFilterOptionsRefresh: {
          $merge: {
            [action.payload.approval_status_override]: true,
          },
        },
        paf_investment_groups: {
          [action.payload.approval_status_override]: {
            $apply: (investments) =>
              replaceInvestment(investments, action.payload).filter(
                filterInvestmentsWithoutCloseDate(action.payload.approval_status_override, state)
              ),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS:
      return update(state, {
        forceCloseDateFilterOptionsRefresh: {
          $merge: {
            [action.payload.request]: false,
          },
        },
      });

    case SUBSCRIPTION_REVIEW_REMOVE_CLOSE: {
      const index = state.closesForFund.findIndex((e) => e.id === action.payload.id);
      const modifiedInvestments = reduce(
        state.paf_investment_groups,
        (acc, value, key) => ({
          ...acc,
          [key]: replaceInvestmentCloseDate(value, action.payload),
        }),
        {}
      );

      return update(state, {
        closesForFund: {
          $splice: [[index, 1]],
        },
        paf_investment_groups: {
          $set: modifiedInvestments,
        },
      });
    }

    case SUBSCRIPTION_REVIEW_SET_PAF_NOTE:
      return update(state, {
        selectedPaf: {
          $set: replacePafNote(state.selectedPaf, action.payload.note),
        },
      });

    case SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE:
      return update(state, {
        selectedPaf: {
          $set: replacePafNote(state.selectedPaf, null),
        },
      });

    case SUBSCRIPTION_REVIEW_UPDATE_CLOSE:
      return update(state, {
        closesForFund: {
          $set: replaceUpdatedObject(state.closesForFund, action.payload),
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT:
      return update(state, {
        subBooksForInvestment: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_COMMITMENT:
      return update(state, {
        paf_investment_groups: {
          [action.payload.investment.approval_status_override]: {
            $apply: (investments) =>
              replaceInvestmentFields(investments, action.payload.investment, ['commitment', 'amount']),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS:
      return update(state, {
        paf_investment_groups: {
          [action.payload.investment.approval_status_override]: {
            $apply: (investments) =>
              replaceInvestmentFields(investments, action.payload.investment, ['investment_share_class']),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES:
      return update(state, {
        availableInvestmentShareClasses: {
          $set: action.payload,
        },
      });

    case SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE:
      return update(state, {
        paf_investment_groups: {
          [action.payload.investment.approval_status_override]: {
            $apply: (investments) => replaceInvestmentFields(investments, action.payload.investment, ['placement_fee']),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE:
      return update(state, {
        paf_investment_groups: {
          [action.payload.investment.approval_status_override]: {
            $apply: (investments) =>
              replaceInvestmentFields(investments, action.payload.investment, ['sub_advisory_fee']),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS:
      return update(state, {
        paf_investment_groups: {
          [action.payload.approval_status_override]: {
            $apply: (investments) =>
              replaceInvestmentFields(investments, action.payload, [
                'sub_doc_type_name',
                'sub_book_id',
                'subscription_document_definition',
                'subscription_document_definition_id',
              ]),
          },
        },
      });

    case SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS:
      return update(state, {
        wlps: { $set: action.payload },
      });

    case SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS:
      return update(state, {
        riasFirms: { $set: action.payload },
      });

    case SR_GET_SELECTED_PAF.SUCCESS:
      return update(state, {
        selectedPaf: { $set: action.payload.response },
      });

    case SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS:
      return update(state, {
        gridToggleStates: {
          $merge: {
            isExpanded: createGridToggleStates(true, true, true),
          },
        },
      });
    case SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS:
      return update(state, {
        gridToggleStates: {
          $merge: {
            isExpanded: createGridToggleStates(false, false),
          },
        },
      });
    case SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE:
      return update(state, {
        gridToggleStates: {
          $merge: {
            isExpanded: {
              ...state.gridToggleStates.isExpanded,
              [action.payload]: !state.gridToggleStates.isExpanded[action.payload],
            },
          },
        },
      });
    case SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED:
      return update(state, {
        gridToggleStates: {
          $merge: {
            didRenderOnce: createGridToggleStates(false, true, true),
          },
        },
      });
    case SUBSCRIPTION_REVIEW_STATE_LOADING:
      return update(state, {
        gridToggleStates: {
          $merge: {
            isLoading: {
              ...state.gridToggleStates.isLoading,
              [action.payload.status]: action.payload.value,
            },
          },
        },
      });
    case SUBSCRIPTION_REVIEW_STATE_RENDERED:
      return update(state, {
        gridToggleStates: {
          $merge: {
            didRenderOnce: {
              ...state.gridToggleStates.didRenderOnce,
              [action.payload]: true,
            },
          },
        },
      });
    case SUBSCRIPTION_REVIEW_SET_GRID_STATES:
      return update(state, {
        gridStates: { $merge: action.payload },
      });

    case SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE: {
      let updatedState = {};
      if (state.currentSavedView) {
        const savedViewGridStates = state.currentSavedView.filters.drop_down_filters.find(
          (f) => f.filter_type === 'gridStates'
        );
        const statusState = savedViewGridStates.filters[0].content[action.payload.status];
        // if the incoming state is different, we need to reset the current saved view
        // and any warnings that might exist
        if (!savedViewGridStates || !isEqual(statusState, action.payload.state)) {
          updatedState = {
            currentSavedView: { $set: null },
            isSavedViewIncomplete: { $set: false },
          };
        }
      }
      return update(state, {
        gridStates: {
          [action.payload.status]: {
            $set: action.payload.state,
          },
        },
        ...updatedState,
      });
    }

    case SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW:
      return update(state, {
        defaultSavedView: { $set: action.payload },
      });

    case SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW:
      return update(state, {
        currentSavedView: { $set: action.payload },
        isSavedViewIncomplete: {
          $set: action.payload === null ? false : state.isSavedViewIncomplete,
        },
      });

    case SUBSCRIPTION_REVIEW_RESET_FILTERS:
      return {
        ...state,
        selected_filters: {
          rias_firms: [],
          rias_users: [],
          wlps: [],
          custodians: [],
          sign_methods: [],
          lead_approvers: [],
          branch_codes: [],
          referral_codes: [],
          show_cancelled_investments: false,
        },
        searchTerm: '',
        gridStates: {},
      };
    case SUBSCRIPTION_REVIEW_SET_STATE_FILTER:
      return update(state, {
        state_filters: {
          $merge: {
            [action.payload.state]: action.payload.filters,
          },
        },
      });
    case SUBSCRIPTION_REVIEW_RESET_STATE_FILTER:
      return update(state, {
        state_filters: {
          $set: {},
        },
      });
    case SUBSCRIPTION_REVIEW_SELECT_INVESTMENT:
      return produce(state, (draft) => {
        const { ids } = action.payload;

        draft.investmentList.selectedIds = ids;
        draft.investmentList.totalSelected = ids.length;
      });
    case SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET:
      return produce(state, (draft) => {
        const newBucket = action.payload;
        if (newBucket === state.investmentList.currentBucket) {
          return;
        }
        draft.investmentList.currentBucket = newBucket;
        draft.investmentList.totalSelected = 0;
        draft.investmentList.selectedIds = [];
      });
    default:
      return state;
  }
};
