import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { useTheme } from '@mui/material';
import styles from './IcnTooltipBase.module.scss';

const cx = classNames.bind(styles);

const IcnTooltipBase = (props) => {
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!clicked);
    setClicked(!clicked);
  };

  const handleMouseHover = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    if (!clicked) {
      setShow(false);
    }
  };

  const handleRootClose = (event) => {
    if (event && event.target.className !== `fa ${props.iconName}`) {
      setShow(false);
      setClicked(false);
    }
  };

  const SvgTag = props.iconSvg;

  const icon = () => {
    if (props.iconSvg) {
      return (<SvgTag className={cx(`icon_svg_${props.svgSize}`)} />);
    }
    if (props.iconName) {
      return (<i className={cx('fa', props.iconName)} style={{ '--color': props.color }} />);
    }
    return null;
  };

  const iconStyle = () => {
    if (props.tooltipType === 'textside') {
      return styles.ip_tooltip_icon_for_text;
    }
    if (props.tooltipType === 'complete') {
      return styles.complete;
    }
    return styles.ip_tooltip_icon_for_input;
  };

  const placement = props.placement ||
    (window.innerWidth > 1200 ? 'bottom' : 'left');

  const tooltipCaller = createRef();

  const theme = useTheme();

  return (
    <span className="tooltip_container">
      <a
        data-tip
        className={iconStyle()}
        onClick={handleClick}
        onKeyDown={handleClick}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseLeave}
        ref={tooltipCaller}
        role="button"
        tabIndex={0}
      >
        {icon()}
      </a>
      <Overlay
        show={show}
        placement={placement}
        target={() => tooltipCaller.current}
        rootClose
        onHide={(event) => handleRootClose(event)}
        container={props.containerRef}
      >
        <Popover
          onMouseEnter={handleMouseHover}
          onMouseLeave={handleMouseLeave}
          className={
            cx({
              tooltip_bubble_style: true,
              left: placement === 'left',
              right: placement === 'right',
              bottom: placement === 'bottom',
              scroll: props.scroll,
              initial_width: !props.maxWidth,
            })
          }
          style={{ '--color': props.color, zIndex: theme.zIndex.tooltip, ...props.popoverStyle }}
        >
          {props.children}
        </Popover>
      </Overlay>
    </span>
  );
};

IcnTooltipBase.propTypes = {
  tooltipType: PropTypes.string,
  placement: PropTypes.string,
  color: PropTypes.string,
  iconName: PropTypes.string,
  iconSvg: PropTypes.elementType,
  svgSize: PropTypes.string,
  scroll: PropTypes.bool,
  maxWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  popoverStyle: PropTypes.shape({}),
  containerRef: PropTypes.shape({}),
};

IcnTooltipBase.defaultProps = {
  tooltipType: 'textside',
  placement: 'bottom',
  color: '#399EAD',
  iconName: 'fa-question-circle',
  iconSvg: null,
  svgSize: 'md',
  scroll: false,
  maxWidth: true,
  children: null,
  popoverStyle: {},
  containerRef: null,
};

export default IcnTooltipBase;
