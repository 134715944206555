import {
  FUND_ADMIN_SET_FUNDS,
  FUND_ADMIN_SET_MENU,
  FUND_ADMIN_SET_HISTORY,
  FUND_ADMIN_POP_HISTORY,
  FUND_ADMIN_MENU_IS_LOADING,
  FUND_ADMIN_SET_BNY_FUNDS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import find from 'lodash/find';

export const initialState = {
  items: [],
  items_export: [],
  menu: [],
  history: [],
  isLoading: false,
  bny_funds: [],
};

export const getSelectedItem = (state, selectedItemId) => find(
  state.items, { id: selectedItemId }
);

export const getSelectedItemExport = (state, selectedItemId) => find(
  state.items_export, { id: selectedItemId }
);

export default function fundAdminReducer(state = initialState, action) {
  switch (action.type) {
    case FUND_ADMIN_SET_FUNDS:
      return update(state, {
        items: { $set: action.payload },
      });
    case FUND_ADMIN_SET_MENU:
      return update(state, {
        menu: { $set: action.payload },
      });
    case FUND_ADMIN_SET_HISTORY:
      return update(state, {
        history: { $push: [action.payload] },
      });
    case FUND_ADMIN_POP_HISTORY:
      return update(state, {
        history: { $splice: [[state.history.length - 1, 1]] },
      });
    case FUND_ADMIN_MENU_IS_LOADING:
      return update(state, {
        isLoading: { $set: action.payload },
      });
    case FUND_ADMIN_SET_BNY_FUNDS:
      return update(state, {
        bny_funds: { $set: action.payload },
      });
    default:
      return state;
  }
}
