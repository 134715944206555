import i18n from 'i18next';

export { RELATIONSHIP_MONTHS } from '../../shared/investor_acknowledgement_form/constants';

/**
 * TABS AND TYPES
 */

export const TABS = Object.freeze({
  ACCOUNT: i18n.t('client_management:create_investment_modal.for_an_account'),
  PROSPECT: i18n.t('client_management:create_investment_modal.for_a_prospect'),
});

export const TYPES = Object.freeze({
  ACCOUNT: 'account',
  PROSPECT_ACCOUNT: 'prospect-account',
  EXISTING_INVESTOR: 'existing-investor',
  NEW_INVESTOR: 'new-investor',
  EXISTING_PROSPECT: 'existing-prospect',
  NEW_PROSPECT: 'new-prospect',
});

// This are just the types of creation that use our internal prospect workflow, not external api prospects
export const PROSPECT_TYPES = Object.freeze([TYPES.EXISTING_PROSPECT, TYPES.NEW_PROSPECT]);

/**
 * DROPDOWN OPTIONS
 */

export const NEW_TEAM_CODE = { value: '', label: '+ New Team Code' };

export const NEW_ACCOUNT_PROFILE = { id: 0, display_name: '+ New Account Profile' };

export const ERROR_CONTACT_CSEC = i18n.t('client_management:create_investment_modal.error_contact_csec');
