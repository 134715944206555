export const filters = {
  features: [],
  investmentObjective: [],
  category: [],
  currency: [],
  strategy: [],
  investorQualification: [],
  investorJurisdiction: [],
  investorType: [],
  fundManager: [],
  liquidity: [],
  distributors: [],
  jurisdiction: [],
  offshore: [],
  investmentRanges: [],
  registrationType: [],
  productStructure: [],
  clientProgram: [],
  following: 'false',
};

export default {
  funds: [],
  page: [],
  currentPage: 1,
  resultsPerPage: 36,
  totalCount: 0,
  lastKnownScrollPosition: 0,
  requestMade: false,
  isLoading: false,
  infoMessage: '',
  isLoadingFirstPage: false,
  isLastPage: false,
  pageActions: {
    urlParsed: false,
    view_type: 'tiles',
    sort_type: 'default',
    searchTerms: '',
    showClosedFunds: false,
    filters,
  },
  showOpportunitiesFilters: false,
  showOpportunitiesClosedFilter: false,
  filterValues: filters,
  filterSelectedValues: filters,
  filterOptions: {
    show_objectives_filter: false,
    show_category_filter: false,
    show_currency_filter: false,
    show_strategy_filter: false,
    show_qualification_filter: false,
    show_investor_jurisdiction_filter: false,
    show_account_type_filter: false,
    show_investment_ranges_filter: false,
    show_fund_manager_filter: false,
    show_liquidity_filter: false,
    show_icapital_features_filter: false,
    show_closed_filter: false,
    show_jurisdiction_filter: false,
    show_offshore_filter: false,
    show_distributors_filter: false,
    show_marketplace_partner_filter: false,
    show_registration_type_filter: false,
    show_product_structure_filter: false,
    show_client_program_filter: false,
  },
  sortOptions: {
    show_newest_sort: false,
    show_closing_soon_sort: false,
  },
  flash_reports: {
    reports: [],
    pafs_with_reports: [],
  },
  isInvestorProfileCompleted: false,
  investorProfileId: '',
  currentUserHasProfile: false,
};
