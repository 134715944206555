import { createAction } from 'redux-actions';
import { deleteRequest, getRequest, postRequest } from 'actions/utils';
import { initialState } from 'reducers/fund_admin_reducers/navReducer';

import {
  NAVS_SET_RESULTS,
  NAV_PREVIEW_SET_RESULTS,
  NAV_PREVIEW_TOTALS_SET_RESULTS,
  NAV_PREVIEW_UPDATE_RESULT,
  NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT,
  NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT,
} from '../actionsConstants';

const cancelFetchPreviews = {
  instance: undefined,
};

const cancelFetchNavs = {
  instance: undefined,
};

const setNavPreviewTotals = createAction(NAV_PREVIEW_TOTALS_SET_RESULTS);

const setNavs = createAction(NAVS_SET_RESULTS, (data) =>
  data.periods ? data : { periods: data, total_periods: data.length }
);

function fetchNavs(pafId, currentPage, resultsPerPage) {
  return getRequest({
    showSpinner: false,
    cancelInstance: cancelFetchNavs,
    url: '/investment_report_document_sets/valid_reporting_periods',
    params: {
      private_access_fund_id: pafId,
      page: currentPage,
      per_page: resultsPerPage,
    },
    onSuccess: setNavs,
  });
}

function setNavPreviews(data, reportId) {
  return (dispatch) => {
    dispatch({ type: NAV_PREVIEW_SET_RESULTS, report_id: reportId, payload: data });
  };
}

function updateNavPreview(investmentId, values) {
  return (dispatch) => {
    dispatch({ type: NAV_PREVIEW_UPDATE_RESULT, investment_id: investmentId, payload: values });
  };
}

function fetchNavPreviews(pafId, reportId, currentPage = 1, resultsPerPage = 25, filterText = '') {
  return getRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/preview`,
    params: {
      private_access_fund_id: pafId,
      page: currentPage,
      per_page: resultsPerPage,
      search: filterText,
    },
    cancelInstance: cancelFetchPreviews,
    onSuccess: (data) => setNavPreviews(data, reportId),
    onFailure: () => setNavPreviews(initialState.nav_preview_list, reportId),
  });
}

function fetchNavTotals(pafId, reportId) {
  return getRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/nav_totals`,
    params: { private_access_fund_id: pafId },
    onSuccess: setNavPreviewTotals,
  });
}

function plusUpdateTotalDocsPublished() {
  return (dispatch) => {
    dispatch({ type: NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT });
  };
}

function minusUpdateTotalDocsPublished() {
  return (dispatch) => {
    dispatch({ type: NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT });
  };
}

function deleteNavReportDocument(pafId, investmentId, reportingPeriodId) {
  return (dispatch) =>
    // CONSIDER: Follow a more normal approach, just sending the InvestmentReportDocument#id to be deleted.
    dispatch(
      deleteRequest({
        showSpinner: false,
        url: '/investment_report_documents/destroy',
        params: {
          private_access_fund_id: pafId,
          investment_id: investmentId,
          reporting_period_id: reportingPeriodId,
        },
        onSuccess: () => {
          dispatch(updateNavPreview(investmentId, { document_id: null, document_url: null }));

          return minusUpdateTotalDocsPublished();
        },
      })
    );
}

function deleteNavReportDocumentSet(pafId, reportId, investmentIds) {
  return postRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/bulk_delete`,
    data: {
      private_access_fund_id: pafId,
      id: reportId, // Reporting period string (e.g. '2020-Mar').
      investment_ids: investmentIds,
    },
  });
}

function createNavReportDocument(pafId, investmentId, reportId) {
  return (dispatch) =>
    dispatch(
      postRequest({
        showSpinner: false,
        url: '/investment_report_documents',
        data: {
          investment_id: investmentId,
          private_access_fund_id: pafId,
          reporting_period_id: reportId,
        },
        onSuccess: (data) => {
          dispatch(
            updateNavPreview(investmentId, {
              document_id: data.id,
              document_url: data.url,
            })
          );

          return plusUpdateTotalDocsPublished();
        },
      })
    );
}

function publishNavDocuments(pafId, reportId, selectedInvestmentIds) {
  return postRequest({
    showSpinner: false,
    url: '/investment_report_document_sets',
    data: {
      private_access_fund_id: pafId,
      id: reportId,
      investment_ids: selectedInvestmentIds,
    },
  });
}

export default {
  fetchNavs,
  fetchNavPreviews,
  fetchNavTotals,
  deleteNavReportDocument,
  deleteNavReportDocumentSet,
  createNavReportDocument,
  publishNavDocuments,
};
