import update from 'immutability-helper';
import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  NAVS_SET_RESULTS,
  NAV_PREVIEW_SET_RESULTS,
  NAV_PREVIEW_TOTALS_SET_RESULTS,
  NAV_PREVIEW_UPDATE_RESULT,
  NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT,
  NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT,
} from 'actions/actionsConstants';

const navListSelector = state => state.nav_list.periods;
export const getPeriodsCount = state => state.nav_list.total_periods;

export const bulkExportList = createSelector(
  navListSelector,
  items => (
    items.map(item => ({
      ...item,
      year: new Date(item.end_date).getFullYear(),
    }))
  )
);

export const initialState = {
  nav_list: {
    periods: [],
    total_periods: 0,
  },
  nav_preview_list: {
    end_date: '',
    investments: [],
    status: '',
    published_date: '',
    total: 0,
  },
  nav_totals_list: {
    total_docs_published: 0,
    total_investors: 0,
    total_commitments: 0,
    total_redemptions: 0,
    total_distribution: 0,
    total_mtd_beginning_balance: 0,
    total_mtd_ending_balance: 0,
    total_mtd_performance: 0,
    total_mtd_profit_or_loss: 0,
    total_mtd_subscriptions_or_redemptions: 0,
    total_ytd_beginning_balance: 0,
    total_ytd_ending_balance: 0,
    total_ytd_performance: 0,
    total_ytd_profit_or_loss: 0,
    total_ytd_subscriptions_or_redemptions: 0,
  },
};

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case NAVS_SET_RESULTS:
      return update(state, {
        nav_list: { $set: action.payload },
      });
    case NAV_PREVIEW_SET_RESULTS:
      return update(state, {
        nav_preview_list: { $set: action.payload },
      });
    case NAV_PREVIEW_TOTALS_SET_RESULTS:
      return update(state, {
        nav_totals_list: { $set: action.payload },
      });
    case NAV_PREVIEW_UPDATE_RESULT: {
      const itemIndex = _.findIndex(state.nav_preview_list.investments,
        item => item.investment_id === action.investment_id);
      return update(state, {
        nav_preview_list: list => update(list, {
          investments: item => update(item, {
            [itemIndex]: idx => update(idx, {
              $merge: action.payload,
            }),
          }),
        }),
      });
    }
    case NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT:
      return update(state, {
        nav_totals_list: item => update(item, {
          total_docs_published: {
            $apply: () => state.nav_totals_list.total_docs_published + 1,
          },
        }),
      });
    case NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT:
      return update(state, {
        nav_totals_list: item => update(item, {
          total_docs_published: {
            $apply: () => state.nav_totals_list.total_docs_published - 1,
          },
        }),
      });
    default:
      return state;
  }
}
