import { getRequest, postRequest } from 'actions/utils';
import { createAction } from 'redux-actions';
import {
  FUND_ADMIN_SET_FUNDS,
  FUND_ADMIN_SET_MENU,
  FUND_ADMIN_SET_HISTORY,
  FUND_ADMIN_POP_HISTORY,
  FUND_ADMIN_MENU_IS_LOADING,
  FUND_ADMIN_SET_BNY_FUNDS,
} from '../actionsConstants';

const cancelGetPrivateAccessFunds = {
  instance: undefined,
};

const setPrivateAccessFunds = createAction(FUND_ADMIN_SET_FUNDS);
const setFundAdminMenu = createAction(FUND_ADMIN_SET_MENU);
const setHistory = createAction(FUND_ADMIN_SET_HISTORY);
const popHistory = createAction(FUND_ADMIN_POP_HISTORY);
const setMenuIsLoading = createAction(FUND_ADMIN_MENU_IS_LOADING);
const setBnyFunds = createAction(FUND_ADMIN_SET_BNY_FUNDS);

const getPrivateAccessFunds = (fundType, showSpinner = true) => (dispatch) => dispatch(
  getRequest({
    url: 'private_access_funds/for_fund_admin',
    params: {
      fund_type: fundType,
      vintage: true,
    },
    onSuccess: setPrivateAccessFunds,
    cancelInstance: cancelGetPrivateAccessFunds,
    showSpinner,
  })
);

const fetchFundAdminMenu = (showSpinner = true) => (dispatch) => {
  dispatch(setMenuIsLoading(true));

  return dispatch(getRequest({
    showSpinner,
    url: '/menu/fund_administration_menu',
    onSuccess: setFundAdminMenu,
    errorMessage: {
      title: 'Fund Admin',
      message: 'An error occurred while fetching the fund admin menu.',
    },
  })).finally(() => dispatch(setMenuIsLoading(false)));
};

const setFundHistory = (history) => (dispatch) => (
  dispatch(setHistory(history))
);

const popFundHistory = () => (dispatch) => dispatch(popHistory());

const getBnyFunds = () => (dispatch) => dispatch(getRequest({
  url: '/private_access_funds/bny_funds',
  onSuccess: setBnyFunds,
}));

const refreshBnyFunds = (pafIds, reportDate, publishType, requestedPublishType) => (dispatch) => dispatch(postRequest({
  url: '/hedge_fund_accounting_reports/refresh_bny_funds',
  params: {
    paf_ids: pafIds,
    report_date: reportDate.format('MM/DD/YYYY'),
    publish_type: publishType,
    requested_publish_type: requestedPublishType,
  },
}));

export default {
  getPrivateAccessFunds,
  fetchFundAdminMenu,
  setFundHistory,
  popFundHistory,
  getBnyFunds,
  refreshBnyFunds,
};
