/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  DC_REMOVE_FILTER,
  DC_REMOVE_ALL_FILTERS,
  DC_SET_FILTERS,
  DC_SET_SEQUENCE,
  DC_SET_ALL_FILTERS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  sequence: { queue: [] },
  date: [],
  external_document_type: [],
  advisory_firm: [],
  fund: [],
  fund_admin_group: [],
  investors: [],
  investor_profile_id: [],
  account_number: [],
  advisor: [],
  display_name: [],
  white_label_partner: [],
  client: [],
};

export const keysEnabledQueryString = [
  'fund',
  'investors',
  'investor_profile_id',
  'advisor',
  'external_document_type',
  'white_label_partner',
];

export { initialState };

function getQueryStringHelper(list, key, oldQueryString, newQueryStringData) {
  let typedFilterIds;
  if (key === 'investor_profile_id') {
    typedFilterIds = list.map((obj) => (`${obj.live_profile_id}(${obj.frozen_profile_ids.join('+')})`));
  } else {
    typedFilterIds = list.map((obj) => obj.typed_filter_id);
  }
  newQueryStringData[key] = typedFilterIds;
  return `${oldQueryString}${key}=${typedFilterIds.join(',')}&`;
}

function getQueryString(filterType, filterList, state) {
  const newQueryStringData = {};
  let newQueryString = '?';
  keysEnabledQueryString.forEach((key) => {
    if (key === filterType) {
      if (filterList.length === 0) {
        return;
      }
      newQueryString = getQueryStringHelper(
        filterList,
        filterType,
        newQueryString,
        newQueryStringData
      );
    } else {
      if (state[key].length === 0) {
        return;
      }
      newQueryString = getQueryStringHelper(
        state[key],
        key,
        newQueryString,
        newQueryStringData
      );
    }
  });
  return [newQueryStringData, newQueryString];
}

export default (state = initialState, action) => {
  let newQueryStringArray;
  let oldFilterSets = [];
  let newFilterSets = [];
  let reduxStateKey = '';
  const payload = {};
  let filterType;
  let newSequence;
  switch (action.type) {
    case DC_REMOVE_FILTER:
      // this is the logic for remove individual filter or the rest of
      if (action.payload.constructor === Array) {
        payload.filter_type = action.payload[0].filter_type;
      } else {
        payload.filter_type = action.payload.filter_type;
      }
      reduxStateKey = payload.filter_type.split(' ').join('_');
      if (reduxStateKey === 'effective_date' || reduxStateKey === 'publish_date') {
        reduxStateKey = 'date';
      }
      switch (payload.filter_type) {
        case 'external_document_type':
          oldFilterSets = state.external_document_type;
          break;
        case 'advisory_firm':
          oldFilterSets = state.advisory_firm;
          break;
        case 'fund':
          oldFilterSets = state.fund;
          break;
        case 'fund_admin_group':
          oldFilterSets = state.fund_admin_group;
          break;
        case 'investors':
          oldFilterSets = state.investors;
          break;
        case 'client':
          oldFilterSets = state.client;
          break;
        case 'investor_profile_id':
          oldFilterSets = state.investor_profile_id;
          break;
        case 'account_number':
          oldFilterSets = state.account_number;
          break;
        case 'advisor':
          oldFilterSets = state.advisor;
          break;
        case 'display_name':
          oldFilterSets = state.display_name;
          break;
        case 'white_label_partner':
          oldFilterSets = state.white_label_partner;
          break;
        case 'effective_date':
        case 'publish_date':
          oldFilterSets = state.date;
          break;
        default:
          oldFilterSets = [];
      }

      // this is the logic for remove individual filter or the rest of
      if (action.payload.constructor === Array) {
        newFilterSets = oldFilterSets.slice(0, 5);
      } else {
        newFilterSets = _.without(oldFilterSets, _.find(oldFilterSets, action.payload));
      }

      newQueryStringArray = getQueryString(payload.filter_type, newFilterSets, state);
      window.history.replaceState(
        newQueryStringArray[0],
        'doc_center_query_string',
        newQueryStringArray[1]
      );

      // reset the sequence for the group when removed all filters from that group
      if (newFilterSets.length === 0) {
        newSequence = JSON.parse(JSON.stringify(state.sequence));
        // find the index of that group and remove it from render queue.
        const index = state.sequence.queue.indexOf(reduxStateKey);
        const shadowCopyQueue = state.sequence.queue.slice(0);
        shadowCopyQueue.splice(index, 1);
        newSequence.queue = shadowCopyQueue;
        delete newSequence[reduxStateKey];
        return update(state, {
          [reduxStateKey]: {
            $set: newFilterSets,
          },
          sequence: {
            $set: newSequence,
          },
        });
      }
      return update(state, {
        [reduxStateKey]: {
          $set: newFilterSets,
        },
      });

    case DC_REMOVE_ALL_FILTERS:
      window.history.replaceState(
        {},
        'doc_center_query_string',
        '?'
      );

      return update(state, {
        date: {
          $set: [],
        },
        external_document_type: {
          $set: [],
        },
        advisory_firm: {
          $set: [],
        },
        fund: {
          $set: [],
        },
        fund_admin_group: {
          $set: [],
        },
        investors: {
          $set: [],
        },
        investor_profile_id: {
          $set: [],
        },
        account_number: {
          $set: [],
        },
        sequence: {
          $set: { queue: [] },
        },
        advisor: {
          $set: [],
        },
        client: {
          $set: [],
        },
        display_name: {
          $set: [],
        },
        white_label_partner: {
          $set: [],
        },
      });

    case DC_SET_FILTERS:
      filterType = action.key.split(' ').join('_');
      newSequence = state.sequence;
      // logic for not setting queue when payload has no filter at all
      if (action.payload.length !== 0 && !Object.hasOwnProperty.call(newSequence, filterType)) {
        newSequence[filterType] = true;
        newSequence.queue.push(filterType);
      } else if (action.payload.length === 0 &&
        Object.hasOwnProperty.call(newSequence, filterType)) {
        if (filterType === 'effective_date' || filterType === 'publish_date') {
          filterType = 'date';
        }
        newSequence = JSON.parse(JSON.stringify(state.sequence));
        // find the index of that group and remove it from render queue.
        const index = state.sequence.queue.indexOf(filterType);
        const shadowCopyQueue = state.sequence.queue.slice(0);
        shadowCopyQueue.splice(index, 1);
        newSequence.queue = shadowCopyQueue;
        delete newSequence[filterType];
      }

      // following logic is for setting queryString for keys that are enabled
      newQueryStringArray = getQueryString(filterType, action.payload, state);
      window.history.replaceState(
        newQueryStringArray[0],
        'doc_center_query_string',
        newQueryStringArray[1]
      );

      return update(state, {
        [filterType]: {
          $set: action.payload,
        },
        sequence: {
          $set: newSequence,
        },
      });

    case DC_SET_SEQUENCE:
      return update(state, {
        sequence: {
          $set: action.payload,
        },
      });

    case DC_SET_ALL_FILTERS:
      return update(state, {
        $set: action.payload,
      });

    default:
      return state;
  }
};
